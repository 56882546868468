import { Modal } from "bootstrap";

export const useModalFunctions = () =>{

    const OpenModal = (modalId) =>{

        const modal = new Modal(modalId, {backdrop: 'static', keyboard: false});
        modal.show();
    }

    const CloseModal = (modalId) =>{

        Modal.getInstance(modalId).hide();

        document.body.removeAttribute("data-bs-overflow");
        document.body.removeAttribute("data-bs-padding-right");
        document.body.removeAttribute("class");
        document.body.removeAttribute("style");

        document.querySelector(".modal-backdrop").remove();
    }

    return { OpenModal, CloseModal }
}