import { useState, useRef }  from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useMedia } from 'react-hooks-plus';

import { useOnHoverOutside } from '../Hooks/useOnHoverOutside';
import { treatmentsTitlesForPath } from '../Data/data';

import "../Styles/NavBar.scss";
import "../Styles/Buttons.scss";

export const NavBar = () => {

    let location = useLocation();

    const [isTreatmentsMenuDropDownOpen, setTreatmentsMenuDropDownOpen] = useState(false);

    const isMobile = useMedia(['(max-width: 767px)'], [true], false);

    const closeDropdownMenu = () => {
        setTreatmentsMenuDropDownOpen(false);
    };
    
    const openDropdownMenu = (event) => {
        setTreatmentsMenuDropDownOpen(true);
    };

    const handleDropdownMobile = (event) =>{

        event.preventDefault();
        event.stopPropagation();
        isTreatmentsMenuDropDownOpen && closeDropdownMenu();
        isTreatmentsMenuDropDownOpen || openDropdownMenu();
    }

    const treatmentsDropdownRef = useRef(null);

    useOnHoverOutside(treatmentsDropdownRef, closeDropdownMenu);
        
    const TreatmentsList = () => {
        let treatmentPathUrl = [];

        return treatmentsTitlesForPath.map(treatment => {
            treatmentPathUrl = treatment.split(" ").join("-").normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase();
            return(
                <li key={treatment} className={location.pathname.includes(treatmentPathUrl) ? "active" : ""} onClick={(event) => isMobile ? handleCollapseNavbar(event, true) : closeDropdownMenu()}>

                    <Link className="dropdown-item" to={`/Tratamientos/${treatmentPathUrl}`}>
                    {treatment}</Link>
                </li>
            )
        })
    };

    const handleCollapseNavbar = (event, isMobileListItem) =>{
        
        const navbar = document.getElementById("collapsableNavBar");
        let closeDropdownMenuTimeout = null;
        
        if(isMobileListItem){
            
            closeDropdownMenuTimeout = setTimeout(() =>{
                closeDropdownMenu();
            }, 100);
        }
        
        if(navbar.classList.contains("fadeIn")){
            document.body.removeAttribute('style');
            navbar.removeAttribute('style');
            navbar.classList.remove("fadeIn");
            navbar.classList.add("fadeOut");

            setTimeout(() =>{
                navbar.classList.remove("show");
            }, 1100)
        }
        else{
            document.body.style.overflowY = "hidden";
            navbar.style.overflowY = "auto";
            navbar.classList.remove("fadeOut");
            navbar.classList.add("show", "fadeIn");
        }
    }

    return(
        
        <nav className="navbar fixed-top navbar-expand-md">
            <div className="container-fluid px-0">
                <Link className="navbar-brand m-0 p-0" to="/">
                    <img src={require("../assets/img/logo-paldental.webp")} alt="logotipo de paldental"/>
                </Link>
                <button name='navbar-mobile-btn' className="navbar-toggler" aria-label="navbar-mobile-btn" type="button" onClick={e => handleCollapseNavbar(e)}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse mt-2 justify-content-center" id="collapsableNavBar">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <NavLink 
                                className={({isActive}) => `nav-link ${isActive ? 'active' : ''}`}
                                to="/" 
                                onClick={(e) => isMobile && handleCollapseNavbar(e)}>Inicio</NavLink>
                            <div className="nav-item__underlined"></div>
                        </li>
                        <li className="nav-item dropdown" ref={treatmentsDropdownRef}>
                            {isMobile && 
                                <>
                                    <NavLink 
                                        className={({isActive}) => `nav-link dropdown-toggle ${isActive ? "active" : ""} ${isTreatmentsMenuDropDownOpen ? "show" : ""}`}  
                                        to="Tratamientos" 
                                        id="navbarDropdown"
                                        onClick={(e) => handleCollapseNavbar(e)}>
                                            <span>
                                                Tratamientos 
                                                <i className='icon icon-chevron-down' onClick={(e) => handleDropdownMobile(e)}></i>
                                            </span>
                                            <div className="nav-item__underlined"></div>
                                    </NavLink>
                                    <ul className={`dropdown-menu ${isTreatmentsMenuDropDownOpen ? "show" : ""}`}
                                        aria-labelledby="navbarDropdown">
                                        <TreatmentsList/>
                                    </ul>
                                    
                                </>
                            }

                            {isMobile || 
                                <>
                                    <NavLink 
                                        id="navbarDropdown"
                                        className={({isActive}) => `nav-link dropdown-toggle ${isActive ? "active" : ""} ${isTreatmentsMenuDropDownOpen ? "show" : ""}`} 
                                        to="Tratamientos" 
                                        onMouseOver={() => openDropdownMenu()}
                                        onClick={() => closeDropdownMenu()}>
                                            Tratamientos
                                    </NavLink>
                                    <div className="nav-item__underlined"></div>
                                    <ul className={`dropdown-menu ${isTreatmentsMenuDropDownOpen ? "show" : ""}`}
                                        aria-labelledby="navbarDropdown">
                                        <TreatmentsList/>
                                    </ul>
                                </>
                            }

                        </li>
                        <li className="nav-item">
                            <NavLink className={({isActive}) => `nav-link ${isActive ? 'active' : ''}`} 
                                to="Tecnologias" onClick={(e) => isMobile && handleCollapseNavbar(e)}>Tecnologías</NavLink>
                            <div className="nav-item__underlined"></div>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({isActive}) => `nav-link ${isActive ? 'active' : ''}`} 
                                to="Descuentos" onClick={(e) => isMobile && handleCollapseNavbar(e)}>Descuentos</NavLink>
                            <div className="nav-item__underlined"></div>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({isActive}) => `nav-link ${isActive ? 'active' : ''}`} 
                                to="Profesionales" onClick={(e) => isMobile && handleCollapseNavbar(e)}>Profesionales</NavLink>
                            <div className="nav-item__underlined"></div>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({isActive}) => `nav-link ${isActive ? 'active' : ''}`} 
                                to="Blog" onClick={(e) => isMobile && handleCollapseNavbar(e)}>Blog</NavLink>
                            <div className="nav-item__underlined"></div>
                        </li>
                    </ul>
                </div>
                <div className='d-flex justify-content-between align-items-center navbar__phoneBtnContainer'>
                    <a className="btn-pd-primary ms-2" href="tel:+34913119242" target="_blank">
                        <img src={require("../assets/img/svg/phone.svg")} alt='icono de teléfono'></img>
                        <span className='ms-2'>91 311 92 42</span>
                    </a>
                </div>
            </div>
        </nav>
        
    )
}