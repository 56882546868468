import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useModalFunctions } from "../../Hooks/useModalFunctions";

import "../../Styles/CookieConsent.scss"

export const CookieConsent = () => {

    const { OpenModal, CloseModal } = useModalFunctions();

    const openCookieConsentModal = () =>{

        if(document.readyState === 'interactive' && document.cookie.indexOf('PD-cookieConsent=') === -1){

            setTimeout(() =>{
                OpenModal("#cookieConsentModal")
            }, 5000)
        }
    }

    const setCookie = (event, consent) =>{

        event.preventDefault();

        return new Promise((resolve, reject) =>{

            const date = new Date();
            date.setDate(date.getDate() + 30);
    
            const expires = `expires=${date.toUTCString()}`;
    
            document.cookie = `PD-cookieConsent=${consent};${expires};path=/`;

            resolve();

        })
        .then(() => {
            CloseModal("#cookieConsentModal");
        })

    }

    useEffect(() =>{
        openCookieConsentModal();
    }, []);

    return (
        <div id="cookieConsentModal" className="modal fade cookieConsent" tabIndex="-1" data-backdrop="static" data-keyboard="false" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content cookieConsent__content">
                    <div className="modal-header justify-content-center cookieConsent__content-header">
                        <h5 className="modal-title">Esta página web usa cookies</h5>
                    </div>
                    <div className="modal-body cookieConsent__content-body">
                        <p className="">Las cookies de la web de Pal Dental se usan para personalizar el contenido y los anuncios, ofrecer funciones de redes sociales y analizar el tráfico.</p>
                        <p className="mb-0">Puede obtener más información en nuestra página de <Link to="/politica-de-cookies">Política de cookies</Link>.</p>
                    </div>
                    <div className="modal-footer justify-content-between cookieConsent__content-footer">
                        <button 
                            type="button" 
                            className="btn-pd-primary-border" 
                            onClick={(e) => setCookie(e, false)}>
                            Rechazar
                        </button>
                        <button 
                            type="button" 
                            className="btn-pd-primary me-2" 
                            onClick={(e) => setCookie(e, true)}>
                            Aceptar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
