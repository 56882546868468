import { Link } from 'react-router-dom';
import { CallToActionButton } from './Utils/CallToActionButton.js';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import "../Styles/Footer.scss";
import "../Styles/Icons.scss"

export const Footer = () =>{

    return(
        <footer className="footer">
            <div id="footerLogo" className="row d-none d-md-block">
                <div className="col-12 text-center">
                    <img className="footer__logo ms-n4" src={require("../assets/img/logo-paldental.webp")} alt="logotipo de paldental"/>
                </div>
            </div>
            <div id="footerInfo" className="row justify-content-center mt-5">
                <div className="col-12 col-md-11 col-lg-10 d-flex flex-column flex-sm-wrap flex-sm-row">
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 footer__otherSections">
                        <h6 className="footer__header">Otras secciones</h6>
                        <ul className="mt-3 ms-4">
                            <li>
                                <Link to="/preguntas-frecuentes">
                                    <span className='d-none d-sm-block d-md-none d-lg-block'>Preguntas frecuentes</span>
                                    <span className='d-block d-sm-none d-md-block d-lg-none'>FAQ</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/terminos-legales">
                                    <span className='d-none d-sm-block'>Términos legales</span>
                                    <span className='d-block d-sm-none'>Condiciones</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/politica-de-cookies">
                                    <span className='d-none d-sm-block'>Política de cookies</span>
                                    <span className='d-block d-sm-none'>Cookies</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 footer__contactInfo ps-4">
                        <h6 className="footer__header ps-4 ms-2">Contáctanos</h6>
                        <ul className="mt-2">
                            <li>
                                <a href="tel:+34913119242" target="_blank">
                                    <i className="icon icon-phone-green"></i>
                                    <span>91 311 92 42</span>
                                </a>
                            </li>
                            <li>
                                <a href="https://api.whatsapp.com/send?phone=34605472132&text=Hola%20buenas%2C+Me+gustaría+informarme+sobre+alguno+de+sus+tratamientos" target="_blank">
                                    <i className="icon icon-whatsapp"></i>
                                    <span>605 47 21 32</span>
                                </a>
                            </li>
                            <li>
                                <a href="mailto:asesorespaldental@gmail.com?subject=Solicitud%20de%20información" target="_blank">
                                    <i className="icon icon-mail"></i>
                                    <span>asesorespaldental@gmail.com</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-4 col-lg-3 footer__address ps-5 ms-5">
                        <div className="footer__address-map">
                            <MapContainer center={[40.45376, -3.703348]} zoom={17} scrollWheelZoom={false} loading="lazy">
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <Marker position={[40.45376, -3.703348]}>
                                    <Popup>
                                        <a href="https://www.google.com/maps/place/C.+de+Bravo+Murillo,+185,+28020+Madrid,+Espa%C3%B1a/@40.45376,-3.703348,16z/data=!4m6!3m5!1s0xd4229aaac63fd5d:0x32085cbf9dabdf4c!8m2!3d40.4537599!4d-3.7033484!16s%2Fg%2F11bw444s6v?hl=es-ES" target="_blank">Ver en Google Maps</a>
                                    </Popup>
                                </Marker>
                            </MapContainer>

                            <a className="mt-2" href="https://www.google.com/maps/place/C.+de+Bravo+Murillo,+185,+28020+Madrid,+Espa%C3%B1a/@40.45376,-3.703348,16z/data=!4m6!3m5!1s0xd4229aaac63fd5d:0x32085cbf9dabdf4c!8m2!3d40.4537599!4d-3.7033484!16s%2Fg%2F11bw444s6v?hl=es-ES" target="_blank">
                                <i className="icon icon-home"></i>
                                <span>Calle Bravo Murillo 185 28020 <span className='d-none d-md-block'>Madrid</span></span>
                            </a>
                        </div>
                    </div>
                    <div className="col-12 col-lg-2 footer__timeShift ms-n4 mt-n3">
                        <div className="week">
                            <label className="footer__header">Lunes a viernes</label>
                            <p className='mb-2'>10:00 - 14:00 h</p>
                            <p>16:00 - 20:00 h</p>
                        </div>
                        <div className="weekend">
                            <label className="footer__header">Sábados</label>
                            <p>10:00 - 14:00 h</p>
                        </div>
                    </div>
                </div>
                
            </div>
            <div id="footerCTA" className="row align-items-center justify-content-center mt-5 pt-3 footer__cta">
                <div className="col-6 col-md-3 text-end footer__appointmentButton pe-4">
                    <CallToActionButton isAppointmentCta={true} customClassName="" />
                </div>
                <div className="col-6 col-md-3 footer__socialMedia ps-4">
                    <a href="https://es-es.facebook.com/clinicapaldental/" target="_blank" aria-label="ir a clínica pal dental en faceebook">
                        <i className="icon icon-facebook"></i>
                    </a>
                    <a href="https://twitter.com/paldental" target="_blank" aria-label="ir a clínica paldental twitter">
                        <i className="icon icon-twitter"></i>
                    </a>
                    <a href="https://www.instagram.com/clinica_paldental?igshid=OGQ5ZDc2ODk2ZA==" target="_blank" aria-label="ir al canal de instagram de la clínica pal dental">
                        <i className="icon icon-instagram"></i>
                    </a>
                    {/* <a href="https://www.youtube.com/channel/UC59-rh0Sgue-Ckh7Xvoofqg" target="_blank" aria-label="ir al canal de youtube de la clínica pal dental">
                        <i className="icon icon-youtube"></i>
                    </a> */}
                </div>
            </div>
            <div id="collaboratorsFooter" className="row justify-content-center mt-4 pt-2 footer__collaborators">
                <div className="col-12 text-center">
                    <h6 className="footer__collaborators-header">Ellos ya colaboran con nosotros</h6>
                </div>
                <div className="col-12 col-md-10 d-flex align-items-center justify-content-center footer__collaborators-logos mt-4">
                    <div className="col-6 col-md-2 text-center">
                        <img className="ziacom" src={require("../assets/img/Colaboradores/Ziacom-logo.webp")} alt="Ziacom logo"/>
                    </div>
                    <div className="col-6 col-md-3 text-center">
                        <img className="invisalign" src={require("../assets/img/Colaboradores/invisalign-logo.webp")} alt="Invisalign go logo"/>
                    </div>
                    <div className="col-4 col-md-2 text-center">
                        <img className="kavo" src={require("../assets/img/Colaboradores/Kavo-dental-excellence-logo.webp")} alt="Kavo dental excellence logo"/>
                    </div>
                    <div className="col-4 col-md-2 text-center">
                        <img className="shape" src={require("../assets/img/Colaboradores/3sshape-logo.webp")} alt="3shape logo"/>
                    </div>
                    <div className="col-4 col-md-3 text-center mt-n3">
                        <img className="martina" src={require("../assets/img/Colaboradores/sweden-martina-logo.webp")} alt="Sweden & Martina logo"/>
                    </div>
                </div>
            </div>
            <div id="authorOfWeb" className="row mt-4 pt-2 mb-3 footer__author justify-content-center">
                <div className='col-11'>
                    <p className='text-center'>Esta web ha sido diseñada por&nbsp;
                        <a href="https://www.linkedin.com/in/elenafrontinanjareno?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAABzhKDkBUQ-qNha8BhLcaQEvqlHRZqR0lX0&lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3BG75Cv9TASve3l3gk0vWYlg%3D%3D" target="_blank">
                            <span>Elena Frontiñán Jareño</span>
                        </a> 
                        &nbsp;y desarrollada por&nbsp;
                        <a href="https://www.linkedin.com/in/rafael-ac/" target="_blank">
                            <span>Rafael Álvarez Calvo</span>
                        </a>
                    </p>
                 </div>
            </div>
        </footer>
    )
}