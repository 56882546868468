import { useContext } from "react";
import { ContactModalContext } from "../../Contexts/ContactModalContext";
import { useModalFunctions } from "../../Hooks/useModalFunctions";
import { useLocation } from "react-router-dom";

import "../../Styles/CallToActions.scss";
import "../../Styles/Buttons.scss";
import "../../Styles/Icons.scss";

export const CallToActions = () =>{

    let location = useLocation();

    const ContactModalCtxt = useContext(ContactModalContext);
    const { OpenModal } = useModalFunctions()

    const handleContactModalContext = (event) => {

        event.preventDefault();
        OpenModal("#ContactModal");
        ContactModalCtxt.setContactModalCtx(true);
    }

    return(
        <div id="callToActionButtons" className={`${location.pathname === "/opinion" ? "d-none" : "d-flex flex-column"}`}>
            <button className="btn-pd-rounded primary" onClick={event => handleContactModalContext(event)} aria-label="abrir modal con formulario de contacto">
                <i className="icon icon-chat"></i>
            </button>
            <a className="btn-pd-rounded outline" href="https://api.whatsapp.com/send?phone=34605472132&text=Hola%20buenas%2C+Me+gustaría+informarme+sobre+alguno+de+sus+tratamientos" target="_blank" aria-label="Abrir conversación de whatsapp">
                <i className="icon icon-whatsapp"></i>
            </a>
        </div>
    )
}