import emailjs from "emailjs-com";
import { useState, useEffect } from "react";

export const useEmailJS = (formType, formValues, formReset, isCaptchaSent, verified, resetReCaptcha) =>{

    const [emailSent, setEmailSent] = useState(false);

    useEffect(() =>{

        const sendEmail = async() =>{

            try{
                
                let response;

                if(formType === "contactForm"){

                    let { NameSurname, Email, Phone , Comment } = formValues;

                    Phone = Phone === "" ? "No ha indicado número de teléfono." : Phone;

                    response = await emailjs.send(
                        process.env.EMAIL_JS_SERVICE_ID,
                        process.env.EMAIL_JS_CONTACT_TEMPLATE_ID,
                        { NameSurname, Email, Phone, Comment },
                        process.env.EMAIL_JS_USER_ID
                    );

                } else if(formType === "feedbackForm"){
                    let { NameSurname, Email, Comment, Rating } = formValues;

                    NameSurname = NameSurname === "" ? "No ha dado nombre y apellidos." : NameSurname;
                    Email = Email === "" ? "No ha indicado email." : Email;

                    response = await emailjs.send(
                        process.env.EMAIL_JS_SERVICE_ID,
                        process.env.EMAIL_JS_FEEDBACK_TEMPLATE_ID,
                        { NameSurname, Email, Comment, Rating },
                        process.env.EMAIL_JS_USER_ID
                    );

                }
            
                if(response.status === 200){
                    resetReCaptcha();
                    formReset();
                    setEmailSent(true);
                }
        
            }catch(error){
                setEmailSent("No se ha podido enviar el mensaje, por favor inténtelo más tarde")
            }
        }

        if(isCaptchaSent && verified){
            sendEmail();
        }
        
    }, [isCaptchaSent]);

    return {emailSent, setEmailSent};

}