import React, { Fragment, useEffect, useState } from 'react'
import { createPortal } from 'react-dom';
import { useLocation, useOutlet } from 'react-router-dom'

import { HelmetProvider } from 'react-helmet-async'
import { NavBar } from '../NavBar'
import { ContactModalProvider } from '../../Contexts/ContactModalContext'
import { AnimatePresence } from 'framer-motion'
import { CallToActions } from '../Utils/CallToActions'
import { Footer } from '../Footer'
import { CookieConsent } from '../Utils/CookieConsent'
import { ContactModal } from '../Utils/ContactModal'

import { Tooltip } from "bootstrap";
import '../../Styles/Layout.scss';

export const Layout = () => {

    let location = useLocation();

    const element = useOutlet();

    const helmetContext = {};

    const [contactModalCtx, setContactModalCtx] = useState({
        openedModal: false,
        modalType: "ContactModal",
        discountData: {
            title: "",
            price: "",
            img: ""
        }
    });

    useEffect(() => {
        var tooltipTriggerList = [].slice.call(
            document.querySelectorAll('[data-bs-toggle="tooltip"]')
        );
        tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
        });
    }, [])

    return (
        <Fragment>
            <HelmetProvider context={helmetContext}>
                <NavBar />
                <ContactModalProvider value={{ contactModalCtx, setContactModalCtx }}>
                    <div id="main-container">
                            {/* <Outlet /> */}
                        <AnimatePresence mode="wait" initial={false} onExitComplete={() => window.scrollTo({ top: 0, behavior: 'instant' })}>
                            {element && React.cloneElement(element, { key: location.pathname })}
                        </AnimatePresence>
                        <CallToActions />
                    </div>

                </ContactModalProvider>
                <Footer />
            </HelmetProvider>

            <ContactModalProvider value={{ contactModalCtx, setContactModalCtx }}>
                {createPortal(
                    <ContactModal />,
                    document.body
                )}
            </ContactModalProvider>

            {createPortal(
                <CookieConsent />,
                document.body
            )}

        </Fragment>
    )
}
