import { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Reaptcha from "reaptcha";
import * as Yup from "yup";
import { motion } from "framer-motion";
import { useContext } from "react";
import { ContactModalContext } from "../../Contexts/ContactModalContext";

import { useEmailJS } from "../../Hooks/useEmailJS";
import { useReaptcha } from "../../Hooks/useReaptcha";
import { useModalFunctions } from "../../Hooks/useModalFunctions";

import "../../Styles/Modal.scss";
import "../../Styles/ContactModal.scss";
import "../../Styles/Icons.scss";
import "../../Styles/Tooltips.scss";
import "../../Styles/Form.scss";

export const ContactModal = () =>{

    const rcRef = useRef(null);

    const [formValues, setFormValues] = useState({});

    const [isSubmitting, setIsSubmitting] = useState(false);

    const initialContactFormValues = {
        "bot-field": "",
        "form-name": "contact-form",
        NameSurname: "",
        Phone: "",
        Email: "",
        Comment: "",
        LegalConsent: false
    }

    const validationSchema = Yup.object().shape({
        NameSurname: Yup.string()
            .min(2, "* El nombre es demasiado corto")
            .max(100, "* El nombre es demasiado largo")
            .required("* Este campo es obligatorio"),
        Phone: Yup.string()
            .matches(/^(?:6[0-9]|7[1-9])[0-9]{7}$/, "* Introduce un teléfono válido"),
            // .required("* Este campo es obligatorio"),
        Email: Yup.string()
            .email("* Introduce un email válido.")
            .required("* Este campo es obligatorio"),
        Comment: Yup.string()
            .min(2, "* El comentario es demasiado corto")
            .max(1000, "* El comentario es demasiado largo")
            .required("* Este campo es obligatorio"),
        LegalConsent: Yup.bool().oneOf([true], "* Debe aceptar los términos y condiciones").required()
    });

    const {
        onLoad,
        onError,
        onVerify,
        onExpire,
        resetEverything,
        resetReCaptcha,
        isCaptchaSent,
        executing,
        captchaErrorMsg,
        formReset,
        verified,
        loaded,
        setExecuting } = useReaptcha(rcRef);

    const { emailSent, setEmailSent } = useEmailJS("contactForm", formValues, formReset, isCaptchaSent, verified, resetReCaptcha);

    const ContactModalCtxt = useContext(ContactModalContext);
    const { CloseModal } = useModalFunctions();

    const RenderSubmitButtonState = (executing, isSubmitting, resetForm) => {
        if (captchaErrorMsg) {
          return (
            <>
                <p className="form-group__error mt-n2 ps-0 pb-4">* No ha superado el Captcha</p>
                <button className="btn-pd-primary-border" onClick={() => resetEverything(resetForm)}>
                    Limpiar formulario
                </button>
            </>
          );
        } else if (isCaptchaSent) {
          return (
            <button className="btn-pd-primary" type="submit" disabled>Enviando</button>
          );
        } else {
          return (
            <button className="btn-pd-primary" type="submit" disabled={isSubmitting || executing}>
              Enviar
            </button>
          );
        }
    };

    const handleSubmitValues = (values) =>{

        setIsSubmitting(true);
        setFormValues({ ...values });
        setExecuting(true);
        rcRef.current.execute();

    }

    const resetContactForm = (event) => {

        event.preventDefault();
        return new Promise((resolve, reject) =>{

            setFormValues({});
            setIsSubmitting(false);
            ContactModalCtxt.setContactModalCtx(false);
            resolve();
            
        })
        .then(() => {
            setEmailSent(false);
            resetReCaptcha();
            CloseModal("#ContactModal");
        })
    };

    return(
        <div className="modal fade" id="ContactModal" tabIndex="-1" aria-labelledby="ContactModalLabel" data-backdrop="static" data-keyboard="false" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header justify-content-end">
                        <button type="button" className="btn-close" data-bs-dismiss={`${emailSent ? "" : "modal"}`} aria-label="Close" onClick={(event) => emailSent && resetContactForm(event)}></button>
                    </div>
                    { emailSent === false &&
                        <Formik
                        initialValues={initialContactFormValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmitValues}>
                        {({ resetForm }) => {
                            return(
                                <Form
                                    id="contactForm"
                                    name="contact-form"
                                    data-netlify="true"
                                    data-netlify-honeypot="bot-field"
                                    data-netlify-recaptcha="true"
                                    noValidate
                                >
                                    <Field type="hidden" name="bot-field" />
                                    <Field type="hidden" name="form-name" />
                                    <motion.div
                                        initial="visible"
                                        animate="visible"
                                        exit={{opacity: 0, transition: { duration: 0.4, ease: "easeInOut"}}}
                                        className="modal-body d-flex flex-column justify-content-start align-items-center mt-n4">
                                        <h5 className="modal-title" id="ContactModalLabel">Contacto</h5>
                                        <h3>Introduce tus datos</h3>

                                        <div className="form-group">
                                            <label className="form-group__label" htmlFor="NameSurname">
                                                Nombre y apellidos <span className="mandatory">*</span>
                                            </label>
                                            <Field id="NameSurnameInput" name="NameSurname" className="form-group__input"/>
                                            <ErrorMessage name="NameSurname" className="form-group__error" component="span"/>
                                        </div>

                                        <div className="form-group">
                                            <label className="form-group__label" htmlFor="Phone">Teléfono</label>
                                            <div className="input-group flex-nowrap">
                                                <Field id="phoneInput" name="Phone" className="form-group__input"/>
                                                <i className="input-group-text icon icon-info" data-bs-toggle="tooltip" data-bs-placement="bottom" title="El teléfono no puede contener espacios, tampoco es necesario el prefijo" data-bs-custom-class="custom-tooltip"></i>
                                            </div>
                                            <ErrorMessage name="Phone" className="form-group__error" component="span"/>
                                        </div>

                                        { ContactModalCtxt.contactModalCtx &&
                                            <Reaptcha
                                                ref={rcRef}
                                                sitekey={process.env.RECAPTCHA_SITE_KEY}
                                                data-netlify-recaptcha="true"
                                                onError={onError}
                                                onExpire={onExpire}
                                                onVerify={onVerify}
                                                onLoad={() => loaded || onLoad(() => resetForm)}
                                                size="invisible"
                                            />
                                        }

                                        <div className="form-group">
                                            <label className="form-group__label" htmlFor="Email">
                                                Email <span className="mandatory">*</span>
                                            </label>
                                            <Field id="emailInput" name="Email" className="form-group__input"/>
                                            <ErrorMessage name="Email" className="form-group__error" component="span"/>
                                        </div>

                                        <div className="form-group">
                                            <label className="form-group__label" htmlFor="Comment">
                                                Comentario <span className="mandatory">*</span>
                                            </label>
                                            <div className="comment">
                                                <Field as="textarea" id="commentInput" name="Comment" className="form-group__input" rows="5"/>
                                            </div>
                                            <ErrorMessage name="Comment" className="form-group__error" component="span" />
                                        </div>

                                        <div className="form-group__legal px-3">
                                            <div className="legal__container">
                                                <Field type="checkbox" id="legalConsentInput" name="LegalConsent" />
                                                <p>He leido y acepto las condiciones de <Link to="/terminos-legales" target="_blank">Aviso Legal</Link> y <Link to="/terminos-legales" target="_blank">Política de privacidad</Link></p>
                                            </div>
                                            <ErrorMessage name="LegalConsent" className="form-group__error ps-0" component="span"/>
                                        </div>
                                    </motion.div>
                                    <div className="modal-footer justify-content-center">
                                        {RenderSubmitButtonState(isSubmitting, executing, resetForm)}
                                    </div>
                                </Form>
                            )
                        }}
                        </Formik>
                    }

                    { emailSent === true &&
                        <motion.div
                            initial="hidden"
                            animate="visible"
                            exit={{opacity: 0, transition: { duration: 0.4, ease: "easeInOut"}}}
                            className="emailSent fadeIn modal-body d-flex flex-column justify-content-start align-items-center mt-n4">
                                <img className="emailSent__img" src={require("../../assets/img/svg/green-tick.svg")} alt="imagen de email enviado"/>
                                <h3 className="modal-title" id="ContactModalLabel">¡Genial!</h3>
                                <h3 className="mt-2">Gracias por contactar con nosotros</h3>
                        </motion.div>
                    }
                    { typeof emailSent === "string" &&
                        <motion.div
                            initial="hidden"
                            animate="visible"
                            exit={{opacity: 0, transition: { duration: 0.4, ease: "easeInOut"}}}
                            className="emailSent fadeIn modal-body d-flex flex-column justify-content-start align-items-center mt-n4">
                                <img className="emailSent__img" src={require("../../assets/img/svg/errorIcon.svg")} alt="imagen de error al enviar email"/>
                                <h3 className="modal-title" id="ContactModalLabel">¡Oops!</h3>
                                <h3 className="mt-2">{emailSent}</h3>
                        </motion.div>
                    }
                </div>
            </div>
        </div>
    )
}