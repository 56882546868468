import { Link } from 'react-router-dom';

export const CallToActionButton = ({ isAppointmentCta, isDiscountsCta, customClassName }) => {

  return (
    <>
        {isDiscountsCta && 
            <Link to="/Descuentos" className={`btn-pd-secondary ${customClassName}`}>
                <span className='d-none d-lg-block'>Descubre nuestras ofertas</span>
                <span className='d-block d-lg-none'>Ir a ofertas</span>
            </Link>
        }

        {isAppointmentCta && 
            <a href="https://clientes.gestiondeclinica.es/30/AreaPrivada/getapp.html?cif=B85236156&idc=30374747-9867-47c9-8b5c-3b26a6fa388b" target="_blank" className={`btn-pd-secondary ${customClassName}`}>
                Pide cita gratis
            </a>
        }
    </>
  )
}
