export const treatmentsTitlesForPath = [
  "Odontología General",
  "Cirugía Oral",
  "Higiene Dental",
  "Sedación Consciente",
  "Cirugía y Estética Dental",
  "Endodoncia",
  "Implantología",
  "Odontogeriatría",
  "Odontopediatría",
  "Ortodoncia",
  "Periodoncia",
  "Prevención Bucodental",
];

export const imgsLandingCarousel = [
  // {
  //     imgSrc: "Limpieza-dental-con-revisión",
  //     url: "limpieza-dental-con-ultrasonido",
  //     alt:"imagen Limpieza dental + revision"
  // },
  {
    imgSrc: "Tac-3d-una-arcada",
    url: "tac-3d-una-arcada",
    alt: "imagen Tomografia axial de 1 arcada",
  },
  {
    imgSrc: "Tac-3d-dos-arcadas",
    url: "tac-3d-completo-ambas-arcadas",
    alt: "imagen de Tomografia axial de 2 arcadas",
  },
  {
    imgSrc: "Ferula-descarga-michigan",
    url: "ferula-de-descarga-digitalizada-con-escaner-3shape",
    alt: "imagen ferula de descarga + limpieza bucal",
  },
];

export const treatmentsCards = [
  {
    id: "first-treatment_row",
    treatments: [
      {
        id: "generalOdontology",
        img: "odontología-general-icon.webp",
        title: "Odontología General",
        text: "Soluciones para mejorar su salud bucodental.",
        url: "odontologia-general",
      },
      {
        id: "maxillofacialSurgery",
        img: "cirugia-oral-icon.svg",
        title: "Cirugía Oral",
        text: "Resolvemos problemas mediante cirugía oral.",
        url: "cirugia-oral",
      },
      {
        id: "dentalHygiene",
        img: "higiene-dental-icon.webp",
        title: "Higiene Dental",
        text: "La prevención es el mejor tratamiento.",
        url: "higiene-dental",
      },
      {
        id: "consciousSedation",
        img: "sedación-consciente-icon.webp",
        title: "Sedación Consciente",
        text: "Sensación de bienestar sin perder el estado consciente.",
        url: "sedacion-consciente",
      },
    ],
  },
  {
    id: "second-treatment_row",
    treatments: [
      {
        id: "cosmeticDentalSurgery",
        img: "cirugia-estetica-dental-icon.svg",
        title: "Cirugía y Estética",
        text: "Una boca ordenada y sana da seguridad en uno mismo.",
        url: "cirugia-y-estetica-dental",
      },
      {
        id: "endodontics",
        img: "endodoncia-icon.svg",
        title: "Endodoncia",
        text: "La endodoncia es un tratamiento que se conoce como “matar el nervio”",
        url: "endodoncia",
      },
      {
        id: "implantology",
        img: "implantologia-icon.svg",
        title: "Implantología",
        text: "Hay solución para la pérdida de piezas dentales.",
        url: "implantologia",
      },
      {
        id: "geriatricDentistry",
        img: "odontogeriatria-icon.svg",
        title: "Odontogeriatría",
        text: "Prótesis adaptadas a las necesidades del paciente.",
        url: "odontogeriatria",
      },
    ],
  },
  {
    id: "third-treatment_row",
    treatments: [
      {
        id: "pediatricDentistry",
        img: "odontopediatria-icon.svg",
        title: "Odontopediatría",
        text: "La odontopediatría se ocupa de la salud oral de niños y adolescentes.",
        url: "odontopediatria",
      },
      {
        id: "orthodontics",
        img: "ortodoncia-icon.webp",
        title: "Ortodoncia",
        text: "Especialidad que estudia, previene y corrige las alteraciones del desarrollo.",
        url: "ortodoncia",
      },
      {
        id: "periodontics",
        img: "periodoncia-icon.svg",
        title: "Periodoncia",
        text: "Es importante un diagnóstico precoz.",
        url: "periodoncia",
      },
      {
        id: "oralPrevention",
        img: "prevencion-bucodental-icon.svg",
        title: "Prevención Dental",
        text: "Priorizamos la conservación de sus dientes.",
        url: "prevencion-bucodental",
      },
    ],
  },
];

export const treatmentsCardsForMobileCarousel = [
  {
    id: "generalOdontology",
    img: "odontología-general-icon.webp",
    title: "Odontología General",
    text: "Soluciones para mejorar su salud bucodental.",
    url: "odontologia-general",
  },
  {
    id: "maxillofacialSurgery",
    img: "cirugia-oral-icon.svg",
    title: "Cirugía Oral",
    text: "Resolvemos problemas mediante cirugía oral.",
    url: "cirugia-oral",
  },
  {
    id: "dentalHygiene",
    img: "higiene-dental-icon.webp",
    title: "Higiene Dental",
    text: "La prevención es el mejor tratamiento.",
    url: "higiene-dental",
  },
  {
    id: "consciousSedation",
    img: "sedación-consciente-icon.webp",
    title: "Sedación Consciente",
    text: "Sensación de bienestar sin perder el estado consciente.",
    url: "sedacion-consciente",
  },
  {
    id: "cosmeticDentalSurgery",
    img: "cirugia-estetica-dental-icon.svg",
    title: "Cirugía y Estética",
    text: "Una boca ordenada y sana da seguridad en uno mismo.",
    url: "cirugia-y-estetica-dental",
  },
  {
    id: "endodontics",
    img: "endodoncia-icon.svg",
    title: "Endodoncia",
    text: "La endodoncia es un tratamiento que se conoce como “matar el nervio”",
    url: "endodoncia",
  },
  {
    id: "implantology",
    img: "implantologia-icon.svg",
    title: "Implantología",
    text: "Hay solución para la pérdida de piezas dentales.",
    url: "implantologia",
  },
  {
    id: "geriatricDentistry",
    img: "odontogeriatria-icon.svg",
    title: "Odontogeriatría",
    text: "Prótesis adaptadas a las necesidades del paciente.",
    url: "odontogeriatria",
  },
  {
    id: "pediatricDentistry",
    img: "odontopediatria-icon.svg",
    title: "Odontopediatría",
    text: "La odontopediatría se ocupa de la salud oral de niños y adolescentes.",
    url: "odontopediatria",
  },
  {
    id: "orthodontics",
    img: "ortodoncia-icon.webp",
    title: "Ortodoncia",
    text: "Especialidad que estudia, previene y corrige las alteraciones del desarrollo.",
    url: "ortodoncia",
  },
  {
    id: "periodontics",
    img: "periodoncia-icon.svg",
    title: "Periodoncia",
    text: "Es importante un diagnóstico precoz.",
    url: "periodoncia",
  },
  {
    id: "oralPrevention",
    img: "prevencion-bucodental-icon.svg",
    title: "Prevención Dental",
    text: "Priorizamos la conservación de sus dientes.",
    url: "prevencion-bucodental",
  },
];

export const treatmentsPageContent = [
  {
    id: "generalOdontologyTreatment",
    treatment_Icon: "icon-odontology",
    Title: "Odontología General",
    Description: [
      "Para tener unos dientes sanos (y una boca sana, en general), además de los procedimientos avanzados de Implantes, Estética Dental y Ortodoncia Avanzada, existen numerosos tratamientos que son igualmente necesarios, como son la Odontología Preventiva, Odontología Conservadora, Cirugía Oral, Medicina Oral, Prótesis, Radiología, Odontopediatría, Odontogeriatría.",

      "Sin olvidar, naturalmente, la Periodoncia, en la medida en que la enfermedad periodontal (materializada en movilidad dental, sangrado e inflamación de encías, etc.), es la principal causa de problemas dentales entre los adultos, con gravísimas implicaciones incluso a nivel cardiovascular.",
    ],
    bold_Description:
      "Nuestro equipo de profesionales, le informará en cada caso de las alternativas más adecuadas a su situación.",
    img: "dentista-en-madrid.webp",
    url: "/Tratamientos/odontologia-general",
    shortDescriptionSEO:
      "Se menciona especialmente la importancia de la periodoncia, ya que la enfermedad periodontal puede causar problemas dentales graves, como movilidad dental, sangrado e inflamación de encías, y también puede tener implicaciones serias a nivel cardiovascular.",
  },
  {
    id: "maxillofacialSurgeryTreatment",
    treatment_Icon: "icon-maxillofacial-surgery",
    Title: "Cirugía Oral",
    Description: [
      "La cirugía oral incluye todos aquellos procedimientos realizados dentro de la cavidad oral para resolver problemas relacionados con la erupción de piezas dentarias, quistes en los maxilares y lesiones mucosas de la encía, entre otros.",

      "Estos procedimientos se realizan de forma cómoda para el paciente bajo anestesia local. Además, las cirugías se pueden realizar de forma ambulatoria y, siguiendo las indicaciones del especialista, el postoperatorio se desarrolla de forma cómoda disminuyendo posibles complicaciones.",

      "La cirugía oral se centra en el estudio, diagnóstico, tratamiento y rehabilitación de las enfermedades congénitas o adquiridas de la cavidad oral, el esqueleto facial y las estructuras cervicales relacionadas.",

      "Cuando se necesita corregir una anormalidad, ya sea congénita o adquirida relacionada con la estructura anatómica de la cara, los maxilares, los dientes y la cavidad oral, la mejor opción es recurrir a la cirugía oral.",
    ],
    bold_Description:
      "Nuestro equipo de profesionales, le informará en cada caso de las alternativas más adecuadas a su situación.",
    img: "cirugia.webp",
    url: "/Tratamientos/cirugia-oral",
    shortDescriptionSEO:
      "La cirugía oral es una especialidad médica que se enfoca en el estudio, diagnóstico, tratamiento y rehabilitación de enfermedades congénitas o adquiridas de la cavidad oral, el esqueleto facial y las estructuras cervicales. Nuestra clínica ofrece procedimientos seguros y avanzados para resolver problemas relacionados con la erupción de piezas dentarias, quistes maxilares, lesiones de encía y otras condiciones. Contamos con un equipo de profesionales altamente capacitados que brindan atención personalizada y un enfoque integral en el tratamiento de cada paciente. Confíe en nuestra experiencia en cirugía oral para corregir anormalidades faciales y dentales y mejorar su salud bucal en general.",
  },
  {
    id: "dentalHygieneTreatment",
    treatment_Icon: "icon-dental-hygiene",
    Title: "Higiene dental",
    Description: [
      "La higiene dental hace referencia al conjunto de cuidados que se realizan en la dentadura para mejorar su conservación y la salud general de nuestro organismo. La higiene bucodental engloba los dientes, las encías, la lengua y toda la cavidad bucal de manera general.",

      "Al cuidar adecuadamente sus dientes y encías, puede ayudar a prevenir problemas como la caries y la enfermedad de las encías (gingivitis o periodontitis). También debe ensañar a los niños cómo cepillarse y usar el hilo dental desde una edad temprana para ayudarles a proteger sus dientes.",

      "En PAL DENTAL creemos que la prevención es el mejor tratamiento para evitar patologías bucodentales. Por ello ponemos la máxima atención en dar consejos y recomendaciones a nuestros pacientes para evitar futuros problemas.",
    ],
    TitleDescription2: "¿Cuál es la importancia de la higiene dental?",
    Description2: [
      "La salud e higiene dental son fundamentales para el bienestar general de una persona. Una buena higiene dental no solo previene la caries dental y la enfermedad periodontal, sino que también puede prevenir otras enfermedades y trastornos médicos.",

      "La enfermedad de las encías, por ejemplo, se ha relacionado con enfermedades del corazón, derrames cerebrales y diabetes. Además, una boca sana y limpia mejora la apariencia de una persona y puede aumentar la confianza y autoestima.",

      "Es importante establecer una rutina de higiene dental diaria que incluya cepillado y uso del hilo dental, así como visitas regulares al dentista para exámenes y limpiezas profesionales. Además, llevar una dieta saludable y limitar el consumo de azúcar también puede contribuir a una buena salud dental.",

      "En resumen, la salud e higiene dental son esenciales para una vida saludable y una sonrisa hermosa.",
    ],
    TitleDescription3: "Consejos para una buena higiene",
    Description3: [
      "1. Cepíllate los dientes tres veces al día, tras cada comida",

      "2. Dedícale el tiempo necesario al cepillado",

      "3. Elige el cepillo de dientes adecuado",

      "4. Renueva tu cepillo de dientes",

      "5. Utiliza la seda dental y/o los cepillos interproximales",

      "6. Utiliza enjuagues bucales",

      "7. No te olvides de limpiar la lengua",

      "8. Acude a las revisiones con tu dentista",

      "9. Elige una pasta de dientes adecuada a tus necesidades",

      "10. Evita alimentos excesivamente azucarados",
    ],
    img: "higiene-dental.webp",
    url: "/Tratamientos/higiene-dental",
    shortDescriptionSEO:
      "Descubre la importancia de la higiene dental para mantener una sonrisa saludable. En nuestra página de higiene dental, encontrarás consejos prácticos y recomendaciones para mantener una buena salud bucal. Te ofrecemos información sobre técnicas de cepillado, uso de hilo dental, enjuagues bucales y otros productos de cuidado oral. Además, aprenderás sobre la prevención de enfermedades dentales, como la caries y la enfermedad de las encías. Nuestro equipo de profesionales también proporciona servicios de limpieza dental profesional para eliminar la placa y el sarro acumulados. ¡Cuida tu sonrisa y mejora tu salud bucal con nuestros recursos de higiene dental!",
  },
  {
    id: "consciousSedationTreatment",
    treatment_Icon: "icon-conscious-sedation",
    Title: "Sedación Consciente",
    Description: [
      "La sedación consciente en odontología es una técnica segura y efectiva para reducir la ansiedad y el miedo de los pacientes durante los procedimientos dentales. Durante la sedación consciente, se administra un sedante por vía oral o inhalada que ayuda al paciente a sentirse más relajado y cómodo, sin perder la conciencia. A diferencia de la anestesia general, el paciente puede respirar por sí mismo y responder a los estímulos durante todo el procedimiento.",

      "La sedación consciente es particularmente útil para pacientes que experimentan ansiedad extrema o fobia al dentista, o para procedimientos más complejos y prolongados. Los pacientes pueden sentirse somnolientos y tener una amnesia temporal del procedimiento, lo que ayuda a reducir el estrés y la incomodidad.",
    ],
    bold_Description:
      "En la Clínica PAL DENTAL disponemos de capacidad técnica y humana para realizar estos procedimientos con la máxima garantía y seguridad médica.",
    img: "sedacion-consciente.webp",
    url: "/Tratamientos/sedacion-consciente",
    shortDescriptionSEO:
      "Experimenta una odontología sin estrés con la sedación consciente. En nuestra página, te ofrecemos información sobre la sedación consciente en odontología, un método seguro y efectivo para ayudarte a relajarte durante los procedimientos dentales. Aprende cómo funciona la sedación consciente, sus beneficios y quiénes pueden beneficiarse de ella. Nuestro equipo de especialistas en sedación consciente está altamente capacitado y brinda un entorno cómodo y tranquilo para tu tratamiento dental. Descubre cómo superar el miedo y la ansiedad dental con la sedación consciente y disfruta de una experiencia dental relajada y libre de estrés.",
  },
  {
    id: "SurgeryAndDentalAestheticsTreatment",
    treatment_Icon: "icon-surgery-aesthetics",
    Title: "Cirugía y estética dental",
    Description: [
      "La cirugía y estética dental es un campo de la odontología que se enfoca en mejorar la apariencia de los dientes, encías y mandíbula. Los procedimientos de cirugía y estética dental pueden incluir blanqueamiento dental, ortodoncia, carillas dentales, implantes dentales, coronas y puentes.",

      "El blanqueamiento dental es un tratamiento popular que puede mejorar significativamente el aspecto de los dientes manchados y amarillentos. La ortodoncia se utiliza para corregir problemas de alineación dental, mientras que las carillas dentales son una solución para dientes desgastados o astillados.",
    ],
    bold_Description:
      "En la Clínica PAL DENTAL defendemos que cada cambio estético debe realizarse de un modo personalizado, de acuerdo con los criterios y deseos de cada persona expresados en cada caso.",
    TitleDescription2: "¿Qué beneficios se obtienen?",
    Description2: [
      "La cirugía y estética dental pueden mejorar la confianza y autoestima de una persona al mejorar la apariencia de su sonrisa.",

      "Si estás interesado en mejorar tu sonrisa, consulta con tu dentista para determinar qué opciones de cirugía y estética dental son adecuadas para ti.",
    ],
    TitleDescription3: "¿Cuáles son las buenas prácticas?",
    Description3: `Es preciso dedicar suficiente tiempo en imitar dientes (con técnicas multicapas o bien con técnica estratificada) hasta en los detalles menores, a fin de dominar los múltiples efectos ópticos que pueden obtenerse con los numerosos materiales que existen en la actualidad. Distintas técnicas de blanqueamiento dental nos permiten aclarar el color de los dientes cuando están bien posicionados pero son oscuros o amarillentos.`,
    img: "estetica-dental.webp",
    url: "/Tratamientos/cirugia-y-estetica-dental",
    shortDescriptionSEO:
      "Descubre las soluciones avanzadas en cirugía y estética dental para mejorar tu sonrisa y salud oral. En nuestra página, ofrecemos una amplia gama de tratamientos dentales que abarcan desde cirugía oral hasta procedimientos estéticos. Nuestro equipo de especialistas altamente capacitados utiliza técnicas modernas y tecnología de vanguardia para proporcionar resultados de alta calidad. Explora nuestras opciones de implantes dentales, blanqueamiento dental, carillas y remodelación de sonrisa. Ya sea que necesites una extracción dental, una reconstrucción oral o simplemente desees mejorar la apariencia de tus dientes, estamos aquí para ayudarte. Obtén una sonrisa radiante y saludable con nuestros servicios de cirugía y estética dental.",
  },
  {
    id: "endodoncyTreatment",
    treatment_Icon: "icon-endodoncy",
    Title: "Endodoncia",
    Description: [
      "La endodoncia es un tratamiento que se conoce vulgarmente como “matar el nervio”. Se basa en la extracción del paquete nervioso que se encuentra en el interior del diente y es el causante del dolor dentario.",

      "Por medio de instrumental especial se limpia el interior del diente dejándolo libre de infección. Cada diente tiene en su interior una red de vasos que se distribuyen dentro del diente formando una red de conductos. La endodoncia pretende limpiar cada conducto y dejar el diente apto para su posterior restauración.",

      "Es uno de los tratamientos más frecuentes en la clínica dental, ya que permite salvar dientes y su extracción evitando el uso de implantes.",

      "La endodoncia es un procedimiento seguro y efectivo que puede ayudar a mantener su sonrisa sana y completa. Además, a diferencia de lo que se cree, la endodoncia no es un procedimiento doloroso, ya que se realiza bajo anestesia local.",
    ],
    TitleDescription2: "¿Cuáles son los síntomas?",
    Description2: [
      "En realidad, los síntomas que indican la necesidad de una endodoncia son los que experimenta el paciente antes de realizarse el procedimiento.",

      "La endodoncia se realiza cuando el tejido blando dentro de un diente, conocido como pulpa, se inflama o infecta. Esto puede ocurrir debido a una caries profunda, una lesión en el diente o una enfermedad de las encías.",

      "Los síntomas comunes de una infección pulpar incluyen dolor de dientes, sensibilidad al frío o al calor, hinchazón en la cara o la encía, y dolor al morder o masticar. Si experimenta alguno de estos síntomas, es importante buscar atención dental de inmediato, ya que la infección puede propagarse a otras partes del cuerpo.",
    ],
    TitleDescription3: "¿Cuánto dura la intervención?",
    Description3: [
      "La duración de una endodoncia puede variar dependiendo de la complejidad del caso y del número de conductos radiculares que necesiten tratamiento. En general, una endodoncia puede tomar de 1 a 2 horas.",

      "Durante la intervención, el dentista o endodoncista removerá la pulpa infectada y limpiará el canal del diente, antes de sellarlo con un material especial.",

      "Una vez finalizada la endodoncia, el paciente puede sentir un poco de molestia y sensibilidad durante unos días, pero esto puede aliviarse con medicamentos para el dolor. Es importante seguir las instrucciones del dentista para una recuperación exitosa.",
    ],
    img: "endodoncia-unirradicular.webp",
    url: "/Tratamientos/endodoncia",
    shortDescriptionSEO:
      "Descubre los beneficios de la endodoncia y cómo podemos ayudarte a salvar tus dientes naturales. En nuestra página de endodoncia, ofrecemos tratamientos especializados para tratar y resolver problemas de la pulpa dental y las infecciones en el interior de los dientes. Nuestro equipo de endodoncistas altamente capacitados utiliza técnicas avanzadas y equipos de última generación para realizar procedimientos de endodoncia de manera eficiente y cómoda. Aprende sobre el proceso de tratamiento de conducto, la eliminación de la pulpa infectada y la restauración del diente afectado. Priorizamos tu comodidad y bienestar, brindando una atención personalizada y minimizando cualquier molestia durante el procedimiento. Confía en nuestra experiencia en endodoncia para preservar la salud y funcionalidad de tus dientes.",
  },
  {
    id: "implantologyTreatment",
    treatment_Icon: "icon-implantology",
    Title: "Implantología",
    Description: [
      "La implantología es una de las áreas de la odontología que más ha evolucionado en los últimos años. Materiales y técnicas cada vez más avanzadas permiten tratamientos más cómodos y fiables.",

      "El funcionamiento normal de los dientes estimula el hueso de su alrededor y este tiende a mantenerse. Sin embargo, al perder cualquier pieza dental el hueso empieza a atrofiarse y va desapareciendo poco a poco. Una de las características de la vejez es la pérdida de hueso en el tercio inferior de la cara, porque muchas personas han perdido sus dientes y sus muelas con el paso del tiempo.",
    ],
    bold_Description:
      "En la Clínica PAL DENTAL disponemos de la experiencia y de la tecnología más avanzada en el campo de la odontología. Llevamos colocando implantes desde hace más de 30 años y disponemos de un quirófano especialmente diseñado para la colocación de implantes en condiciones de perfecta esterilización.",
    TitleDescription2: "¿Qué son los implantes?",
    Description2: [
      "Los implantes dentales son unas piezas de titanio que se colocan en el hueso con una rosca en su interior para atornillar el pilar que soportará el futuro diente. Proporcionan una solución permanente ante la pérdida de dientes; los nuevos dientes se ven y se sienten como dientes propios. Mejora la autoimagen, el habla, el confort y la facilidad de comer. La sustitución de dientes y muelas perdidos con estos implantes permite recuperar de forma fija y cómoda todas sus funciones (masticación fonación, estética, estabilización de muelas vecinas y antagonistas, conservación del hueso de la zona, etc.) y todo ello sin necesidad de aplicar sobrecargas a los dientes vecinos, como harían un aparato removible o un puente tradicional.",

      "Dejar perder este hueso maxilar o mandibular es un serio inconveniente para cualquier tipo de rehabilitación oral, y la única forma de mantenerlo es devolviéndole la función mediante la colocación de implantes osteointegrados.",

      "Al sustituir un diente con un implante, estamos restaurando no solamente la parte externa, sino también su raíz. Por eso, cuanto más hueso nos queda, mejor pronóstico tendrá el caso, y por eso no conviene dejar pasar el tiempo sin colocar los implantes después de las extracciones de dientes y muelas.",
    ],
    TitleDescription3: "¿Cuáles son sus ventajas?",
    Description3: [
      "La implantología dental es una de las opciones más efectivas para reemplazar los dientes perdidos. Los implantes dentales ofrecen numerosas ventajas en comparación con otras opciones de tratamiento. Una de las principales ventajas es la durabilidad de los implantes.",

      "Los implantes están diseñados para integrarse con el hueso y convertirse en una parte permanente de la estructura dental, lo que significa que pueden durar toda la vida del paciente. Además, los implantes dentales ofrecen una funcionalidad y estética similar a los dientes naturales, lo que permite a los pacientes comer, hablar y sonreír con confianza. Otra ventaja de los implantes dentales es que no requieren la modificación de los dientes adyacentes, como ocurre con los puentes dentales.",

      "Esto significa que los implantes pueden preservar la estructura dental natural del paciente. Por último, los implantes dentales pueden prevenir la pérdida ósea en la mandíbula y mantener la salud oral a largo plazo. En nuestra clínica dental, ofrecemos una amplia variedad de opciones de implantes dentales para satisfacer las necesidades y preferencias individuales de nuestros pacientes.",
    ],
    img: "implantes-dentales.webp",
    url: "/Tratamientos/implantologia",
    shortDescriptionSEO:
      "Descubre cómo la implantología dental puede restaurar tu sonrisa y funcionalidad oral. En nuestra página de implantología, ofrecemos soluciones especializadas para reemplazar dientes perdidos o dañados. Nuestro equipo de especialistas en implantología dental utiliza técnicas avanzadas y materiales de alta calidad para colocar implantes dentales de forma segura y efectiva. Aprende sobre los beneficios de los implantes dentales, su durabilidad y naturalidad en la función y apariencia. Explora nuestras opciones de implantes unitarios, puentes o incluso una rehabilitación completa con implantes. Nos enfocamos en brindar una experiencia cómoda y personalizada, priorizando tus necesidades y objetivos. Recupera tu sonrisa y disfruta de una masticación adecuada con nuestros servicios de implantología dental.",
  },
  {
    id: "geriatricDentistryTreatment",
    treatment_Icon: "icon-geriatric-dentistry",
    Title: "Odontogeriatría",
    Description: [
      "La odontogeriatría es la parte de la odontología dedicada a la atención de la salud bucal para personas mayores de sesenta años de edad y cuyo estado físico general esté significativamente influenciado por procesos degenerativos y enfermedades que se asocian habitualmente con la persona mayor.",

      "A medida que pasan los años la gente vive más y necesita conservar cada vez más sus piezas dentales. Por ello sus demandas son cada vez mayores.",

      "No basta demostrar sensibilidad a las necesidades de las personas mayores; los problemas clínicos para planear y suministrar atención odontogeriatrica pueden ser muy grandes y a menudo obligan a adquirir nuevos conocimientos.",
    ],
    bold_Description:
      "En la Clínica PAL DENTAL tenemos profesionales especializados y preparados no solamente en las características odontológicas de los ancianos, sino también en aspectos médicos, gerontológicos y psicológicos, para poder llegar a un grado de comprensión indispensable que posibilite un buen diagnóstico y un plan de tratamiento adecuado.",
    TitleDescription2: "Prótesis dentales totales y parciales",
    Description2: [
      "Son estructuras destinadas a restaurar la anatomía y la funcionalidad de una o varias piezas dentales, restaurando también la relación entre los maxilares, devolviendo la corporalidad de la dentadura, y repone tanto la dentición natural como las estructuras periodontales.",

      "Dentro de estas hay varios tipos:",

      "1.- Las prótesis totales (de material acrílico)",

      "2.- Prótesis parciales removibles (cromo cobalto o acrílico)",

      "3.- Prótesis sobre ataches (sin metal visible y muy estéticas)",

      "4.- Prótesis flexibles (que se doblan y no se quiebran)",
    ],
    TitleDescription3: "Prótesis fijas y sobre implantes",
    Description3: [
      "Básicamente existen dos tipos de prótesis sobre implantes: el primero son las prótesis fijas sobre implantes dentales, mediante las que se reponen los dientes ausentes con unos “imitaciones” creados a medida, y que se atornillan o cementan directamente sobre los implantes dentales.",

      "El segundo tipo son las prótesis removibles, en las que los dientes ausentes se reponen con unos aparatos bucales que se sujetan firmemente a los maxilares mediante los implantes dentales, pero que se retiran de la boca para su higiene y mantenimiento por el propio paciente.",

      "Este tipo de prótesis consta a su vez de dos partes: una parte fija, que se adapta a los implantes dentales y no puede retirarse por el paciente; y una parte removible, que incorpora los dientes y el sistema de anclaje por el que se une firmemente a la parte fija.",
    ],
    img: "odontogeriatria.webp",
    url: "/Tratamientos/odontogeriatria",
    shortDescriptionSEO:
      "Descubre la importancia de la odontogeriatría para el cuidado oral de las personas mayores. En nuestra página de odontogeriatría, nos especializamos en brindar atención dental personalizada y adaptada a las necesidades de los adultos mayores. Con un enfoque en la salud bucal y el bienestar general, ofrecemos tratamientos y cuidados específicos para abordar los desafíos dentales asociados con el envejecimiento. Aprende sobre la prevención y manejo de enfermedades orales comunes en la tercera edad, como la periodontitis y la xerostomía. Nuestro equipo de profesionales está comprometido en proporcionar un entorno cálido y confortable, priorizando la comodidad y la calidad de vida de nuestros pacientes mayores. Confía en nuestra experiencia en odontogeriatría para mejorar y mantener una buena salud oral en la etapa de la vida adulta mayor.",
  },
  {
    id: "pediatricDentistryTreatment",
    treatment_Icon: "icon-pediatric-dentistry",
    Title: "Odontopediatría",
    Description: [
      "La odontopediatría se ocupa de la salud oral de niños y adolescentes. Su objetivo es prevenir y tratar todos los problemas dentales, tanto de los dientes temporales como de los dientes permanentes de los jóvenes.",

      "La primera visita al dentista nunca debería realizarse más tarde de los 3 años, con revisiones cada medio año aproximadamente para ayudar a los niños a que se habitúen a un medio desconocido, pierdan el miedo al dentista y aprendan que es importante cuidarse la boca y los dientes, mejorando así sus hábitos higiénicos y alimentarios. Siempre la primera impresión es la que queda, por lo tanto el primer contacto no puede traumatizar al niño, para ello nuestro equipo de odontopediatría está preparado para ofrecerle el mejor servicio al niño.",

      "También es necesario detectar malos hábitos, como el de mantener el chupete o succionarse el dedo después de los 2 años o solucionar problemas bastante habituales a edades tempranas cómo traumatismos, caries, malformación, problemas de erupción o de posición de los dientes, hábitos de dieta y de higiene, así como problemas de los tejidos blandos o de articulación.",
    ],
    bold_Description:
      "Como parte de estas medidas preventivas, y correctivas desde la Clínica PAL DENTAL también desarrollamos talleres de educación bucodental para niños de entre 6 y 9 años. Contamos con un completo programa de atención dental infantil, con revisiones gratuitas semestrales para los niños con edades comprendidas entre los 3 y 14 años. El programa incluye radiografías intraorales, fluoraciones y técnicas de higiene oral sin coste para el paciente y sin tener que suscribir ningún tipo de póliza dental.",
    img: "odontopediatria.webp",
    url: "/Tratamientos/odontopediatria",
    shortDescriptionSEO:
      "Descubre cómo brindamos una atención dental especializada y amigable para los niños en nuestra página de odontopediatría. Nos dedicamos a cuidar la salud oral de los más pequeños, ofreciendo servicios dentales adaptados a las necesidades y etapa de desarrollo de los niños. Aprende sobre la importancia de establecer buenos hábitos de higiene bucal desde temprana edad y cómo prevenir problemas dentales comunes en los niños, como las caries. Nuestro equipo de odontopediatras altamente capacitados crea un entorno acogedor y divertido para que los niños se sientan cómodos durante las visitas dentales. Nos esforzamos por brindar una experiencia positiva y educativa para promover una sonrisa saludable a lo largo de la infancia. Confía en nosotros para el cuidado dental de tus hijos y establecer una base sólida para su salud oral a lo largo de su vida.",
  },
  {
    id: "ortodoncyTreatment",
    treatment_Icon: "icon-ortodoncy",
    Title: "Ortodoncia",
    Description: [
      "La ortodoncia es la parte de la odontología que se ocupa del correcto alineamiento de los dientes. Hoy en día, son frecuentes tanto los tratamientos en niños como los tratamientos en adultos de todas las edades. Por lo general, cuanto antes se descubra una alteración, más sencillo será su tratamiento. Es aconsejable revisar a los niños a partir de los 4 años a fin de descartar o detectar y prevenir posibles alteraciones importantes.",

      "La ortodoncia se suele enfrentar con problemas que van mucho más allá de mover y alinear dientes. En ocasiones debemos corregir también alteraciones de posición y crecimiento de los maxilares, o incluso del perfil facial. Por todo ello es importantísimo realizar un correcto estudio de cada caso, tanto en niños como en adultos. Los problemas de mal posición dental son muy frecuentes, hay más de un 20% de la población con alguna desviación en su mordida. De acuerdo con el diagnóstico realizado, nuestro ortodontista desarrollará un plan de tratamiento adecuado a sus necesidades.",
    ],
    TitleDescription2: "¿Qué tipo de ortodoncia trabajamos?",
    Description2: [
      "Brackets cerámicos: Al ser del mismo color que el diente consiguen pasar más desapercibidos. Su material cerámico mantiene el color durante el tratamiento, favoreciendo su estética y discreción.",

      "Brackets linguales: Tanto el bracket como el alambre quedan escondidos en la cara interior de los dientes aumentando la estética y la discreción.",

      "Invisalign: Sin bracket ni alambres, usamos férulas transparentes imperceptibles para corregir la alineación de los dientes. Son nuestra técnica más discreta.",
    ],
    TitleDescription3: "¿Qué beneficios tiene la ortodoncia?",
    Description3: [
      "Los tratamientos de ortodoncia pueden tener múltiples ventajas para la salud y la estética dental.",

      "En primer lugar, una correcta alineación dental puede mejorar la mordida y reducir la tensión en la mandíbula, evitando el desgaste dental y otros problemas a largo plazo. Además, una sonrisa más recta y alineada puede aumentar la confianza y mejorar la autoestima del paciente.",

      "En nuestra clínica dental, ofrecemos una amplia variedad de tratamientos de ortodoncia para satisfacer las necesidades y preferencias individuales de nuestros pacientes.",
    ],
    img: "ortodoncia.webp",
    url: "/Tratamientos/ortodoncia",
    shortDescriptionSEO:
      "Transforma tu sonrisa y mejora la alineación de tus dientes con nuestros servicios de ortodoncia. En nuestra página especializada en ortodoncia, ofrecemos una amplia gama de tratamientos para corregir maloclusiones y lograr una sonrisa perfectamente alineada. Nuestro equipo de ortodoncistas altamente capacitados utiliza técnicas avanzadas y aparatos ortodónticos de última generación para brindar resultados eficaces y estéticamente agradables. Aprende sobre los beneficios de la ortodoncia, desde mejorar la apariencia dental hasta corregir problemas de mordida y aliviar la tensión en la mandíbula. Nos enfocamos en brindar una experiencia cómoda y personalizada, adaptada a tus necesidades y metas estéticas. Confía en nuestra experiencia en ortodoncia para obtener una sonrisa hermosa y funcional que te haga sentir seguro y satisfecho.",
  },
  {
    id: "periodoncyTreatment",
    treatment_Icon: "icon-periodoncy",
    Title: "Periodoncia",
    Description: [
      "La periodoncia es una especialidad de la odontología que se enfoca en el cuidado de las encías y los tejidos que sostienen los dientes. Los tratamientos de periodoncia pueden tener múltiples beneficios para la salud dental y general del paciente.",

      "La enfermedad periodontal, también conocida como enfermedad de las encías, puede provocar la inflamación y la pérdida de hueso alrededor de los dientes, lo que puede resultar en la pérdida dental a largo plazo. La periodoncia ayuda a prevenir, diagnosticar y tratar esta enfermedad, así como otras afecciones relacionadas con las encías y los tejidos blandos de la boca.",

      "Los tratamientos de periodoncia pueden incluir limpiezas dentales profundas, raspado y alisado radicular, y en casos más avanzados, cirugía periodontal.",
    ],
    bold_Description:
      "Uno de los objetivos primordiales en Clínica PAL DENTAL es conservar la dentadura sana y por eso es muy importante tratar la enfermedad periodontal antes de su inicio con un cepillado correcto de los dientes después de cada comida, higienes bucales frecuentes y revisiones periódicas con el dentista.",
    img: "periodoncia.webp",
    url: "/Tratamientos/periodoncia",
    shortDescriptionSEO:
      "En nuestra página de periodoncia, ofrecemos soluciones especializadas para el cuidado de las encías y el tratamiento de enfermedades periodontales. Nuestro equipo de periodoncistas altamente capacitados se enfoca en preservar la salud de las encías y prevenir la progresión de enfermedades como la gingivitis y la periodontitis. Aprende sobre la importancia de una buena salud periodontal y cómo mantener unas encías fuertes y libres de infecciones. Explora nuestros tratamientos periodontales, que incluyen limpiezas profundas, raspado y alisado radicular, y cirugía periodontal cuando es necesario. Nos esforzamos por brindar una experiencia cómoda y personalizada, priorizando la salud bucal integral. Confía en nuestra experiencia en periodoncia para mantener tus encías en óptimas condiciones y preservar la salud de tus dientes a largo plazo.",
  },
  {
    id: "oralPreventionTreatment",
    treatment_Icon: "icon-oral-prevention",
    Title: "Prevención Bucodental",
    Description: [
      "La prevención bucodental es el mejor tratamiento para evitar patologías bucodentales. Patologías orales como la caries, la enfermedad periodontal o el cáncer oral no se pueden prevenir al 100%, puesto que en ellas el carácter genético es innegable, pero si se diagnostican precozmente y se tratan en los momentos iniciales de la enfermedad, el pronóstico mejorará enormemente.",

      "Mediante un seguimiento personalizado de los pacientes, nuestro personal se pondrá en contacto con usted y le recordará la necesidad de realizar las revisiones periódicas para realizar un detallado estudio que nos permitirá mantener su boca en perfecto estado de salud e higiene.",
    ],
    bold_Description:
      "Es por ello que en la Clínica PAL DENTAL ponemos la máxima atención en aconsejar y recomendar a nuestros pacientes cómo pueden mejorar su salud bucodental.",
    img: "prevencion-bucodental.webp",
    url: "/Tratamientos/prevencion-bucodental",
    shortDescriptionSEO:
      "En nuestra página de prevención bucodental, te brindamos información y recursos para mantener una óptima salud oral y prevenir problemas dentales. Aprende sobre la importancia de la higiene bucal adecuada, el cuidado de los dientes y encías, y la prevención de enfermedades orales comunes. Descubre consejos prácticos para mantener una sonrisa saludable, como el cepillado y el uso adecuado del hilo dental, y la importancia de las visitas regulares al dentista. Nuestro objetivo es ayudarte a establecer buenos hábitos de cuidado oral y proporcionarte herramientas para prevenir la aparición de caries, enfermedades de las encías y otros problemas bucodentales. Confía en nuestra experiencia en prevención bucodental para mantener una sonrisa radiante y una buena salud oral a largo plazo.",
  },
];

export const treatmentsOptionsForSelect = [
  { value: "-1", label: "Selecciona un tratamiento" },
  { value: "Odontologia-General", label: "Odontología General" },
  { value: "Cirugia-Oral", label: "Cirugía Oral" },
  { value: "Higiene-Dental", label: "Higiene Dental" },
  { value: "Sedacion-Consciente", label: "Sedación Consciente" },
  { value: "Cirugia-y-Estetica-Dental", label: "Cirugía y Estética Dental" },
  { value: "Endodoncia", label: "Endodoncia" },
  { value: "Implantología", label: "Implantología" },
  { value: "Odontogeriatría", label: "Odontogeriatría" },
  { value: "Odontopediatría", label: "Odontopediatría" },
  { value: "Ortodoncia", label: "Ortodoncia" },
  { value: "Periodoncia", label: "Periodoncia" },
  { value: "Prevención Bucodental", label: "Prevención Bucodental" },
];

export const customerComments = [
  [
    {
      id: "commentBox1",
      name: "Ana Gallego Vera",
      comment:
        "Encantada de haber conocido esta clínica. Maravillosa atención por parte de todo el equipo, mi tratamiento era muy complicado y consiguieron solucionar el problema de la mejor manera. Auténticos PROFESIONALES que siempre me han brindado lo mejor.",
      collapsedComment: "Muchas gracias por el trato y la atención recibida.",
    },
    {
      id: "commentBox2",
      name: "Wisbel Leon",
      comment:
        "Lugar increíble, con increíbles profesionales. Me hice una limpieza dental que encontré en Groupon, no venía no muchas expectativas, menos mal estuvo todo maravilloso, mis dientes quedaron impecables a demás que me ayudaron con valoración de mi dentadura gratis.",
      collapsedComment:
        "La profesional que me atendió Jhoana (disculpame si no lo escribí bien) es tan amable, talentosa y profesional. RECOMENDADO AL 100% VOLVERÉ",
    },
    {
      id: "commentBox3",
      name: "Cristian Cuenca Ruiz",
      comment:
        "Estoy muy contento con mi tratamiento acabo de terminar y me quedó la boca perfecta, muchas gracias a todos los profesionales desde la recepción, gracias a Celia que me llevó la ortodoncia a la Dra Carranza por los implantes y Dr Fabio la estética.",
      collapsedComment:
        "Y por su puesto a Delcy que qué ha tenido mucha paciencia y profesionalidad a la hora de conseguir todo lo que necesitaba para que mi tratamiento quede perfecto. Recomendadisimo.",
    },
  ],
  [
    {
      id: "commentBox4",
      name: "Elena M",
      comment:
        "Llevo 2 años con ellos y estoy encantada. El trato que recibo cada mes que voy por parte de Celia y Diana, la paciencia que me tienen y me resuelven todas las dudas que puedan surgirme cada vez que voy…» Anda que son pocas».",
      collapsedComment:
        "Siempre me atienden con una sonrisa y se preocupan por como estoy o me siento. En recepción el trato es igual, la chica muy amable. Recomendado cien por cien.",
    },
    {
      id: "commentBox5",
      name: "Carlos Arteaga",
      comment:
        "Muy contenta con mi Ferula rápida e indoloro, gracias por el trato recibido en la Clínica Pal Dental. Desde el personal de recepción, hasta los especialistas dentales. Se nota la profesionalidad del personal. Gracias a todos ellos.",
      collapsedComment: "",
    },
    {
      id: "commentBox6",
      name: "Javier Nevado Polo",
      comment:
        "Hace un año, estando muy contento con el servicio de limpieza dental, empecé mi tratamiento de ortodoncia y no puedo estar más contento. Siempre atienden de forma puntual, amable y profesional. Todos son muy atentos y profesionales.",
      collapsedComment:
        "Voy una vez al mes y nunca he salido con una mala experiencia. Recomendado 100%",
    },
  ],
  [
    {
      id: "commentBox7",
      name: "Felipe García",
      comment:
        "Aproveche mi visita a Madrid para acercarme a la clínica y realizarme un TAC dental. Me atendió Juan Carlos, muy amable, cercano, profesional y competente, todo un ejemplo de SABER ATENDER A UN PACIENTE. Me lleve mi estudio y mejor impresión de la clínica.",
      collapsedComment: "",
    },
    {
      id: "commentBox8",
      name: "Alba E",
      comment:
        "Sufro de bruxismo desde hace muchos años y no confiaba en las ferulas de descarga porque las que tenia me hacian daño y no notaba efecto. Decidi hacermela en pal dental y estoy super contenta porque ademas de ser la ferula Michigan real (nada que ver con las otras).",
      collapsedComment:
        "Es super comoda y efectiva ( ya no tengo que ir al fisio cada mes). Poco tiempo de espera, atención de 10 ( super agradables) e instalaciones bien cuidadas.",
    },
    {
      id: "commentBox9",
      name: "Rosa Escobar",
      comment:
        "Excelente trato al cliente por parte de recepcionistas y las 2 doctoras que me atendieron y rápidos. Me hicieron una placa y todo para descartar caries radiológicas y ver que todo estaba bien, la férula tipo Michigan es fantástica. Relación calidad/precio de 10.",
      collapsedComment:
        "Dato de interés: si vas en coche justo en frente hay un parking no muy caro, si vas en metro la clínica está en la misma boca del metro.",
    },
  ],
];

export const customerCommentsForMobile = [
  {
    id: "commentBox1",
    name: "Ana Gallego Vera",
    comment:
      "Encantada de haber conocido esta clínica. Maravillosa atención por parte de todo el equipo, mi tratamiento era muy complicado y consiguieron solucionar el problema de la mejor manera. Auténticos PROFESIONALES que siempre me han brindado lo mejor.",
    collapsedComment: "Muchas gracias por el trato y la atención recibida.",
  },
  {
    id: "commentBox2",
    name: "Wisbel Leon",
    comment:
      "Lugar increíble, con increíbles profesionales. Me hice una limpieza dental que encontré en Groupon, no venía no muchas expectativas, menos mal estuvo todo maravilloso, mis dientes quedaron impecables a demás que me ayudaron con valoración de mi dentadura gratis.",
    collapsedComment:
      "La profesional que me atendió Jhoana (disculpame si no lo escribí bien) es tan amable, talentosa y profesional. RECOMENDADO AL 100% VOLVERÉ",
  },
  {
    id: "commentBox3",
    name: "Cristian Cuenca Ruiz",
    comment:
      "Estoy muy contento con mi tratamiento acabo de terminar y me quedó la boca perfecta, muchas gracias a todos los profesionales desde la recepción, gracias a Celia que me llevó la ortodoncia a la Dra Carranza por los implantes y Dr Fabio la estética.",
    collapsedComment:
      "Y por su puesto a Delcy que qué ha tenido mucha paciencia y profesionalidad a la hora de conseguir todo lo que necesitaba para que mi tratamiento quede perfecto. Recomendadisimo.",
  },
  {
    id: "commentBox4",
    name: "Elena M",
    comment:
      "Llevo 2 años con ellos y estoy encantada. El trato que recibo cada mes que voy por parte de Celia y Diana, la paciencia que me tienen y me resuelven todas las dudas que puedan surgirme cada vez que voy…» Anda que son pocas».",
    collapsedComment:
      "Siempre me atienden con una sonrisa y se preocupan por como estoy o me siento. En recepción el trato es igual, la chica muy amable. Recomendado cien por cien.",
  },
  {
    id: "commentBox5",
    name: "Carlos Arteaga",
    comment:
      "Muy contenta con mi Ferula rápida e indoloro, gracias por el trato recibido en la Clínica Pal Dental. Desde el personal de recepción, hasta los especialistas dentales. Se nota la profesionalidad del personal. Gracias a todos ellos.",
    collapsedComment: "",
  },
  {
    id: "commentBox6",
    name: "Javier Nevado Polo",
    comment:
      "Hace un año, estando muy contento con el servicio de limpieza dental, empecé mi tratamiento de ortodoncia y no puedo estar más contento. Siempre atienden de forma puntual, amable y profesional. Todos son muy atentos y profesionales.",
    collapsedComment:
      "Voy una vez al mes y nunca he salido con una mala experiencia. Recomendado 100%",
  },
  {
    id: "commentBox7",
    name: "Felipe García",
    comment:
      "Aproveche mi visita a Madrid para acercarme a la clínica y realizarme un TAC dental. Me atendió Juan Carlos, muy amable, cercano, profesional y competente, todo un ejemplo de SABER ATENDER A UN PACIENTE. Me lleve mi estudio y mejor impresión de la clínica.",
    collapsedComment: "",
  },
  {
    id: "commentBox8",
    name: "Alba E",
    comment:
      "Sufro de bruxismo desde hace muchos años y no confiaba en las ferulas de descarga porque las que tenia me hacian daño y no notaba efecto. Decidi hacermela en pal dental y estoy super contenta porque ademas de ser la ferula Michigan real (nada que ver con las otras).",
    collapsedComment:
      "Es super comoda y efectiva ( ya no tengo que ir al fisio cada mes). Poco tiempo de espera, atención de 10 ( super agradables) e instalaciones bien cuidadas.",
  },
  {
    id: "commentBox9",
    name: "Rosa Escobar",
    comment:
      "Excelente trato al cliente por parte de recepcionistas y las 2 doctoras que me atendieron y rápidos. Me hicieron una placa y todo para descartar caries radiológicas y ver que todo estaba bien, la férula tipo Michigan es fantástica. Relación calidad/precio de 10.",
    collapsedComment:
      "Dato de interés: si vas en coche justo en frente hay un parking no muy caro, si vas en metro la clínica está en la misma boca del metro.",
  },
];

export const professionals = [
  [
    {
      Name: "Fabio Muscillo",
      Charge: "Subdirector médico",
      Description: "Odontología estetica y cirugía",
      Image: "img/Empleados/FABIO-MUSCILLO-ESTÉTICA-DENTAL-Y-PERIODONCIA.webp",
      Scaled: true,
    },
    {
      Name: "Rosa Adriana Carranza Caudillo",
      Charge: "Directora asistencial",
      Description: "Cirugía oral e implantología",
      Image:
        "img/Empleados/Rosa-Adriana-Carranza-Caudillo-Directora-Medica.webp",
      Scaled: false,
    },
    {
      Name: "Francesco Caldareri",
      Charge: "Endodoncista",
      Description: "Estética dental y endodoncia",
      Image: "img/Empleados/Franchesco-caldareri-endodoncista-estetica.webp",
      Scaled: false,
    },
    {
      Name: "Antonio Muscogiuri",
      Charge: "Cirujano periodoncista",
      Description: "Cirugía oral y periodoncia",
      Image: "img/Empleados/muscogiuri-cirujano-periodoncista.webp",
      Scaled: false,
    },
    {
      Name: "Celia Pisonero",
      Charge: "Ortodoncista",
      Description: "Odontología general y ortodoncia",
      Image: "img/Empleados/onero-ortodoncia-multidisciplinar.webp",
      Scaled: false,
    },
  ],
  [
    {
      Name: "Katherine Martínez",
      Charge: "Auxiliar clínico",
      Image: "img/Empleados/Katherine-Martínez-Auxiliar-clínico.webp",
      Scaled: true,
    },
    {
      Name: "Silvana Petralia",
      Charge: "Higienista",
      Image: "img/Empleados/Silvana-Petralia-Higienista.webp",
      Scaled: true,
    },
    {
      Name: "Antonella Delgado",
      Charge: "Auxiliar clínico",
      Image: "img/Empleados/Antonella-Delgado-Auxiliar-Clínico.webp",
      Scaled: true,
    },
    {
      Name: "Diana Castillo",
      Charge: "Auxiliar clínico",
      Image: "img/Empleados/diana-castillo-aux-clinico.webp",
      Scaled: false,
    },
  ],
  [
    {
      Name: "Juan Carlos Muñoz",
      Charge: "Director General",
      Image: "img/Empleados/Juan-Carlos-Muñoz-Director-General.webp",
      Scaled: false,
    },
    {
      Name: "Adolfo Flores",
      Charge: "Director Gerente",
      Image: "img/Empleados/Adolfo-Flores.webp",
      Scaled: false,
    },
  ],
];

export const faqLinks = [
  [
    {
      Text: "Blanqueamientos dentales",
      Url: "/preguntas-frecuentes/blanqueamientos-dentales",
    },
    {
      Text: "Bruxismo",
      Url: "/preguntas-frecuentes/bruxismo",
    },
    {
      Text: "Cáncer de boca",
      Url: "/preguntas-frecuentes/cancer-de-boca",
    },
    {
      Text: "Caries",
      Url: "/preguntas-frecuentes/caries",
    },
    {
      Text: "Carillas",
      Url: "/preguntas-frecuentes/carillas",
    },
    {
      Text: "Consejos de higiene dental",
      Url: "/preguntas-frecuentes/consejos-higiene-dental",
    },
    {
      Text: "Endodoncia",
      Url: "/preguntas-frecuentes/endodoncia",
    },
    {
      Text: "Estética dental",
      Url: "/preguntas-frecuentes/estetica-dental",
    },
  ],
  [
    {
      Text: "Extracciones",
      Url: "/preguntas-frecuentes/extracciones",
    },
    {
      Text: "Halitosis",
      Url: "/preguntas-frecuentes/halitosis",
    },
    {
      Text: "Implantes",
      Url: "/preguntas-frecuentes/implantes",
    },
    {
      Text: "Odontopediatría",
      Url: "/preguntas-frecuentes/odontopediatria",
    },
    {
      Text: "Ortodoncia",
      Url: "/preguntas-frecuentes/ortodoncia",
    },
    {
      Text: "Periodontitis",
      Url: "/preguntas-frecuentes/periodontitis",
    },
    {
      Text: "Prótesis",
      Url: "/preguntas-frecuentes/protesis",
    },
    {
      Text: "Sedaciones conscientes",
      Url: "/preguntas-frecuentes/sedaciones-conscientes",
    },
  ],
];

export const faqs = [
  {
    title: "BLANQUEAMIENTOS DENTALES",
    breadcrumb: "Blanqueamientos dentales",
    topic: "blanqueamientos-dentales",
    shortDescriptionSEO:
      "Obtén respuestas a tus preguntas más comunes sobre blanqueamientos dentales en nuestra página de preguntas frecuentes. Explora nuestra recopilación de información clara y concisa sobre los diferentes tipos de blanqueamiento dental, el procedimiento, la duración, los resultados esperados y los cuidados posteriores. Nuestro equipo de expertos ha preparado respuestas basadas en su amplio conocimiento y experiencia en el campo de la odontología estética. Encuentra información relevante sobre la seguridad, eficacia y consideraciones específicas para cada caso. Utiliza nuestras preguntas frecuentes como una guía confiable para tomar decisiones informadas sobre tu tratamiento de blanqueamiento dental. Estamos comprometidos en brindarte la información que necesitas para obtener una sonrisa más brillante y radiante.",
    questions: [
      {
        question:
          "¿Qué tipo de blanqueamiento dental se realiza en una clínica dental?",
        answers: [
          "En una clínica dental, se puede realizar un blanqueamiento dental profesional utilizando productos blanqueadores de mayor concentración que los que se encuentran en los productos de blanqueamiento en el hogar.",

          "Por lo general, el gel blanqueador se activa con una luz especial que ayuda a acelerar el proceso de blanqueamiento. Antes de recomendar un tipo de blanqueamiento dental, el dentista evalúa la salud dental del paciente y determina si el tratamiento es adecuado para ellos.",
        ],
      },
      {
        question: "¿Cuánto dura el blanqueamiento dental?",
        answers: [
          "El tratamiento de blanqueamiento dental en una clínica dental suele durar entre 60 y 90 minutos. En algunos casos, se pueden necesitar varias sesiones para lograr los resultados deseados.",

          "Durante el tratamiento, el dentista aplicará un gel blanqueador en los dientes del paciente y lo activará con una luz especial. Después del tratamiento, el paciente puede notar una mejora en el color de sus dientes inmediatamente.",
        ],
      },
      {
        question: "¿Es doloroso el blanqueamiento dental?",
        answers: [
          "El blanqueamiento dental en una clínica dental generalmente no es doloroso. Sin embargo, algunas personas pueden experimentar sensibilidad temporal en los dientes después del tratamiento. El dentista puede proporcionar productos que ayuden a reducir la sensibilidad y hacer que el paciente se sienta más cómodo durante el procedimiento.",
        ],
      },
      {
        question: "¿Es seguro el blanqueamiento dental?",
        answers: [
          "El blanqueamiento dental en una clínica dental es seguro si se realiza correctamente. Los dentistas están capacitados para utilizar productos blanqueadores seguros y controlar cualquier posible efecto secundario. El paciente debe informar al dentista sobre cualquier problema dental previo, como caries o gingivitis, para evitar complicaciones.",

          "Es importante seguir las instrucciones del dentista después del tratamiento y mantener una buena higiene dental para prolongar los resultados del blanqueamiento dental.",
        ],
      },
      {
        question: "¿Cuánto cuesta el blanqueamiento dental?",
        answers: [
          "El costo del blanqueamiento dental en una clínica dental puede variar según la ubicación geográfica y la clínica. En general, el blanqueamiento dental en una clínica dental es más costoso que los productos de blanqueamiento en el hogar. Sin embargo, los resultados de un tratamiento de blanqueamiento dental en una clínica dental son más efectivos y duraderos.",

          "Es importante hablar con el dentista para conocer los precios y opciones de financiamiento disponibles. En nuestra clínica ofrecemos planes de pago o descuentos especiales para pacientes nuevos.",
        ],
      },
      {
        question: "¿Se pueden blanquear los dientes sensibles?",
        answers: [
          "Sí, los dientes sensibles pueden ser blanqueados en una clínica dental. El dentista puede utilizar geles de blanqueamiento con concentraciones más bajas de peróxido de hidrógeno o recomendar un tratamiento con flúor para reducir la sensibilidad. Es importante informar al dentista sobre cualquier problema de sensibilidad antes del tratamiento para asegurarse de que se tomen las medidas necesarias antes de la intervención",
        ],
      },
    ],
  },
  {
    title: "BRUXISMO",
    breadcrumb: "Bruxismo",
    topic: "bruxismo",
    shortDescriptionSEO:
      "Encuentra respuestas a tus preguntas más frecuentes sobre el bruxismo en nuestra página de preguntas frecuentes. Explora nuestra recopilación de información detallada y concisa sobre este trastorno involuntario de apretar o rechinar los dientes. Nuestro equipo de expertos ha preparado respuestas basadas en su amplio conocimiento y experiencia en odontología y trastornos del sueño. Descubre las causas, síntomas, diagnóstico y opciones de tratamiento disponibles para el bruxismo. Obtén consejos útiles sobre cómo manejar el estrés y adoptar hábitos saludables para reducir los efectos del bruxismo en tu salud dental y bienestar general. Utiliza nuestras preguntas frecuentes como una valiosa fuente de información para comprender y abordar el bruxismo de manera efectiva.",
    questions: [
      {
        question: "¿Qué es el bruxismo dental?",
        answers: [
          "El bruxismo es un trastorno en el que una persona aprieta o rechina los dientes involuntariamente. El bruxismo dental puede ocurrir durante el día o la noche, y el bruxismo nocturno es el tipo más común. Puede ser causado por el estrés, la ansiedad, la mala alineación dental, problemas de la articulación temporomandibular (ATM), o una combinación de estos factores.",

          "El bruxismo dental es un problema común que afecta a muchas personas. Aunque puede no parecer un problema grave, puede tener consecuencias graves si no se trata adecuadamente. El bruxismo dental puede causar desgaste dental, fracturas dentales, dolor crónico en la mandíbula y los músculos de la cara, trastornos del sueño y otros problemas de salud.",
        ],
      },
      {
        question: "¿Cuáles son los síntomas del bruxismo dental?",
        answers: [
          "Los síntomas del bruxismo dental pueden variar de persona a persona. Algunos de los síntomas más comunes incluyen dolor de cabeza, dolor de mandíbula, dientes sensibles, desgaste dental, y dolor de cuello y hombros.",

          "También puede causar trastornos del sueño, como insomnio o sueño interrumpido. En algunos casos, el bruxismo dental puede ser lo suficientemente grave como para causar dolor en los oídos o trastornos temporomandibulares (TMD).",
        ],
      },
      {
        question: "¿Cómo se diagnostica el bruxismo dental?",
        answers: [
          "El bruxismo dental puede ser diagnosticado por un dentista o un médico. Durante el examen, el profesional de la salud puede buscar signos de desgaste dental, dolor en los músculos de la mandíbula y la cara, y otros síntomas. También puede ser necesario realizar radiografías o imágenes de resonancia magnética (IRM) para evaluar la gravedad del problema.",
        ],
      },
      {
        question: "¿Cómo se trata el bruxismo dental?",
        answers: [
          "El tratamiento del bruxismo dental depende de la causa y la gravedad del problema. Si se debe al estrés o la ansiedad, se pueden recomendar técnicas de relajación o terapia cognitivo-conductual. En algunos casos, se pueden utilizar dispositivos como férulas o placas de mordida para proteger los dientes y reducir el desgaste dental. En casos más graves, se pueden recetar medicamentos para reducir el dolor y la inflamación.",

          "Además, existen algunas medidas que se pueden tomar en casa para ayudar a reducir el bruxismo dental. Estas pueden incluir evitar alimentos y bebidas que contengan cafeína y alcohol, practicar técnicas de relajación, y usar protección dental durante los deportes de contacto.",

          "Es importante buscar tratamiento si se sospecha de bruxismo para evitar complicaciones graves.",
        ],
      },
      {
        question: "¿Es común el bruxismo dental?",
        answers: [
          "Sí, el bruxismo dental es un trastorno común que afecta a muchas personas en todo el mundo. Aunque se puede producir en personas de todas las edades, es más común en adultos jóvenes y de mediana edad.",

          "Los estudios sugieren que hasta el 20% de los adultos experimentan bruxismo dental en algún momento de su vida.",
        ],
      },
      {
        question:
          "¿Cómo afecta el bruxismo dental a la salud bucal en general?",
        answers: [
          "El bruxismo dental puede tener consecuencias graves para la salud bucal en general. El desgaste dental puede afectar la mordida y la estética dental, y puede requerir tratamientos costosos como la colocación de coronas o puentes dentales.",

          "Además, el bruxismo dental puede aumentar el riesgo de caries dental, enfermedad de las encías, y otros problemas dentales. En algunos casos, el bruxismo dental también puede causar dolor crónico en la mandíbula y los músculos de la cara, lo que puede afectar la calidad de vida en general.",
        ],
      },
    ],
  },
  {
    title: "CÁNCER DE BOCA",
    breadcrumb: "Cáncer de boca",
    topic: "cancer-de-boca",
    shortDescriptionSEO:
      "Obtén respuestas a tus preguntas más frecuentes sobre el cáncer de boca en nuestra página de preguntas frecuentes. Explora nuestra recopilación de información detallada y concisa sobre este tipo de cáncer, sus causas, síntomas, diagnóstico y opciones de tratamiento disponibles. Nuestro equipo de expertos en oncología y odontología ha preparado respuestas basadas en su amplio conocimiento y experiencia en el campo. Descubre los factores de riesgo, cómo realizar autoexámenes y cuándo buscar atención médica. Obtén consejos prácticos sobre la prevención y el cuidado bucal para reducir el riesgo de desarrollar cáncer de boca. Utiliza nuestras preguntas frecuentes como una valiosa herramienta para educarte y tomar decisiones informadas sobre tu salud bucal. Estamos aquí para brindarte información confiable y apoyo en tu lucha contra el cáncer de boca.",
    questions: [
      {
        question: "¿Qué es el cáncer de boca?",
        answers: [
          "El cáncer de boca es una enfermedad en la que se forman células malignas en los tejidos de la boca. Puede afectar los labios, la lengua, las encías, las mejillas, el paladar duro y la parte posterior de la garganta. Si no se detecta y se trata tempranamente, el cáncer de boca puede propagarse a otras partes del cuerpo y ser potencialmente mortal.",
        ],
      },
      {
        question: "¿Cuáles son los síntomas del cáncer de boca?",
        answers: [
          "Los síntomas del cáncer de boca pueden variar dependiendo de la ubicación y el tamaño del tumor. Algunos de los síntomas más comunes incluyen una llaga o úlcera en la boca que no cicatriza, dolor en la boca o la garganta, dificultad para tragar, cambios en la voz, bultos en el cuello y sangrado en la boca.",

          "Si experimenta alguno de estos síntomas, es importante consultar a un médico o dentista lo antes posible.",
        ],
      },
      {
        question: "¿Qué causa el cáncer de boca?",
        answers: [
          "El cáncer de boca puede ser causado por una variedad de factores, como el tabaquismo, el consumo de alcohol, la exposición al sol sin protección en los labios, y la infección por el virus del papiloma humano (VPH).",

          "El tabaquismo y el consumo excesivo de alcohol son los principales factores de riesgo del cáncer de boca, y aquellos que fuman y beben en exceso tienen un mayor riesgo de desarrollar la enfermedad.",
        ],
      },
      {
        question: "¿Cómo se diagnostica el cáncer de boca?",
        answers: [
          "El cáncer de boca se puede diagnosticar mediante una variedad de pruebas, como una biopsia, una tomografía computarizada (TC), una resonancia magnética (RM), y una endoscopia.",

          "Una biopsia es el método más común de diagnóstico, en el que se extrae una pequeña muestra de tejido de la zona afectada y se examina bajo un microscopio para determinar si hay células cancerosas presentes.",
        ],
      },
      {
        question: "¿Cómo se trata el cáncer de boca?",
        answers: [
          "El tratamiento del cáncer de boca depende del tamaño y la ubicación del tumor, así como de la salud general del paciente. Las opciones de tratamiento pueden incluir cirugía, radioterapia, quimioterapia, y terapia dirigida.",

          "En algunos casos, se puede usar una combinación de estas opciones. Es importante trabajar con un equipo médico experimentado para determinar el mejor plan de tratamiento para cada caso individual.",
        ],
      },
      {
        question: "¿Se puede prevenir el cáncer de boca?",
        answers: [
          "Sí, se pueden tomar medidas para reducir el riesgo de cáncer de boca. Evitar fumar y consumir alcohol en exceso es una de las formas más efectivas de prevenir la enfermedad.",

          "Además, usar protección solar en los labios y practicar una buena higiene oral, como cepillarse los dientes y usar hilo dental regularmente, también puede ayudar a reducir el riesgo de cáncer de boca.",

          "También es importante realizarse exámenes dentales regulares y consultar a un médico o dentista si se experimenta algún síntoma sospechoso.",
        ],
      },
    ],
  },
  {
    title: "CARIES",
    breadcrumb: "Caries",
    topic: "caries",
    shortDescriptionSEO:
      "Obtén respuestas a tus preguntas más frecuentes sobre las caries en nuestra página de preguntas frecuentes. Explora nuestra recopilación de información detallada y concisa sobre este problema dental común, incluyendo sus causas, síntomas, prevención y tratamientos disponibles. Nuestro equipo de expertos en odontología ha preparado respuestas basadas en su amplio conocimiento y experiencia en el campo. Descubre cómo prevenir las caries a través de una adecuada higiene bucal, una dieta saludable y visitas regulares al dentista. Aprende sobre los diferentes tipos de tratamientos disponibles, como empastes y endodoncias, y cómo pueden ayudar a tratar las caries. Utiliza nuestras preguntas frecuentes como una valiosa fuente de información para mantener tu salud bucal y prevenir futuros problemas. Estamos aquí para brindarte orientación y consejos prácticos sobre las caries y cómo cuidar tu sonrisa de manera efectiva.",
    questions: [
      {
        question: "¿Qué son las caries?",
        answers: [
          "Las caries dentales son pequeñas áreas de desmineralización en el esmalte dental que pueden crecer y convertirse en cavidades. Estas cavidades son causadas por ácidos producidos por las bacterias en la placa dental, que se acumulan en los dientes y pueden dañarlos con el tiempo.",
        ],
      },
      {
        question: "¿Cuáles son los síntomas de las caries?",
        answers: [
          "Los síntomas de las caries dentales pueden variar dependiendo de la gravedad y la ubicación de la caries. Algunos de los síntomas más comunes incluyen sensibilidad dental, dolor al morder o masticar, manchas blancas en los dientes, y agujeros visibles en los dientes.",

          "Si experimenta alguno de estos síntomas, es importante consultar a un dentista lo antes posible.",
        ],
      },
      {
        question: "¿Qué causa las caries?",
        answers: [
          "Las caries dentales son causadas por una combinación de factores, incluyendo una dieta rica en carbohidratos y azúcares, una higiene dental deficiente, y la falta de flúor en el agua potable.",

          "Las bacterias en la placa dental se alimentan de los carbohidratos y azúcares en la boca, produciendo ácidos que pueden dañar el esmalte dental y causar caries.",
        ],
      },
      {
        question: "¿Cómo se pueden prevenir las caries?",
        answers: [
          "Las caries dentales se pueden prevenir mediante una buena higiene oral, que incluye cepillarse los dientes al menos dos veces al día, usar hilo dental regularmente, y limitar el consumo de alimentos y bebidas azucarados.",

          "Además, el flúor en el agua potable y los productos dentales puede ayudar a prevenir las caries dentales.",
        ],
      },
      {
        question: "¿Cómo se tratan las caries?",
        answers: [
          "El tratamiento de las caries dentales depende de la gravedad y la ubicación de la caries.",

          "En general, el tratamiento puede incluir la eliminación de la caries y el relleno de la cavidad con un material restaurador, como una amalgama o una resina compuesta. En casos más graves, puede ser necesario un tratamiento de conducto o incluso la extracción del diente afectado.",
        ],
      },
      {
        question: "¿Cómo puedo saber si tengo caries?",
        answers: [
          "La mejor manera de saber si tiene caries dentales es mediante una visita al dentista.",

          "Durante un examen dental, el dentista puede examinar los dientes y buscar signos de caries, como manchas blancas, agujeros o sensibilidad dental.",

          "También puede tomar radiografías para detectar caries que no son visibles a simple vista. Es importante realizar exámenes dentales regulares para detectar y tratar las caries dentales en etapas tempranas.",
        ],
      },
    ],
  },
  {
    title: "CARILLAS",
    breadcrumb: "Carillas",
    topic: "carillas",
    shortDescriptionSEO:
      "Obtén respuestas a tus preguntas más frecuentes sobre las carillas dentales en nuestra página de preguntas frecuentes. Explora nuestra recopilación de información detallada y concisa sobre este tratamiento estético dental, incluyendo sus beneficios, el procedimiento, los tipos de carillas y las consideraciones antes y después del tratamiento. Nuestro equipo de expertos en odontología estética ha preparado respuestas basadas en su amplio conocimiento y experiencia en el campo. Descubre si las carillas dentales son adecuadas para ti, cómo se colocan y cuánto tiempo pueden durar. Obtén consejos útiles sobre el cuidado y mantenimiento adecuados de las carillas para asegurar resultados duraderos y una sonrisa radiante. Utiliza nuestras preguntas frecuentes como una valiosa fuente de información para tomar decisiones informadas sobre el tratamiento de carillas dentales y mejorar tu estética dental. Estamos comprometidos en brindarte la información necesaria para que logres una sonrisa hermosa y confiada.",
    questions: [
      {
        question: "¿Qué son las carillas dentales?",
        answers: [
          "Las carillas dentales son una solución estética para corregir problemas como manchas, decoloración, fracturas, desgaste y otros problemas cosméticos en los dientes.",

          "Las carillas son capas finas de porcelana o composite que se adhieren a la superficie de los dientes para mejorar su apariencia. Las carillas dentales son una opción popular para aquellos que buscan mejorar su sonrisa sin la necesidad de someterse a tratamientos dentales más invasivos.",
        ],
      },
      {
        question: "¿Cómo se colocan las carillas dentales?",
        answers: [
          "El proceso de colocación de las carillas dentales puede variar según cada caso individual, pero por lo general, el proceso implica la eliminación de una pequeña cantidad de esmalte dental de la superficie del diente para hacer espacio para la carilla. Luego, se toma una impresión del diente para crear una carilla personalizada que se adapte perfectamente a la forma y tamaño del diente.",

          "Una vez que se haya creado la carilla, se adhiere de forma permanente al diente utilizando un adhesivo dental especial.",
        ],
      },
      {
        question: "¿Cuánto tiempo duran las carillas dentales?",
        answers: [
          "La duración de las carillas dentales puede variar dependiendo del tipo de material utilizado, la técnica de colocación y el cuidado dental del paciente. En general, las carillas de porcelana pueden durar de 10 a 15 años o más, mientras que las carillas de composite pueden durar de 5 a 7 años.",

          "Es importante seguir una buena higiene dental y evitar hábitos como morder objetos duros para maximizar la vida útil de las carillas.",
        ],
      },
      {
        question: "¿Las carillas dentales duelen?",
        answers: [
          "El proceso de colocación de las carillas dentales es generalmente indoloro y se realiza con anestesia local para minimizar cualquier molestia. Después del procedimiento, algunos pacientes pueden experimentar sensibilidad dental temporal que desaparece en unos pocos días.",
        ],
      },
      {
        question: "¿Las carillas dentales son adecuadas para todos?",
        answers: [
          "Las carillas dentales pueden ser una buena opción para aquellos que buscan mejorar la apariencia de sus dientes, pero no son adecuadas para todos. Si los dientes están severamente dañados o cariados, es posible que sea necesario someterse a tratamientos dentales más extensos.",

          "Además, aquellos con enfermedades dentales activas o bruxismo (rechinar de dientes) pueden no ser buenos candidatos para las carillas dentales.",
        ],
      },
      {
        question: "¿Cuánto cuestan las carillas dentales?",
        answers: [
          "El costo de las carillas dentales puede variar según varios factores, como el tipo de material utilizado, el número de dientes que se están tratando y la ubicación geográfica del consultorio dental.",

          "En general, las carillas de porcelana tienden a ser más costosas que las de composite, con un rango de precios que va desde unos pocos cientos a varios miles de euros por diente.",

          "Es importante hablar con su dentista para obtener un presupuesto preciso y discutir las opciones de financiamiento disponibles. En la clínica pal dental disponemos de grandes especialistas en carillas dentales pide cita gratis ahora mismo e ¡infórmate con nosotros!",
        ],
      },
    ],
  },
  {
    title: "HIGIENE DENTAL",
    breadcrumb: "Higiene dental",
    topic: "consejos-higiene-dental",
    shortDescriptionSEO:
      "Encuentra respuestas a tus preguntas más frecuentes sobre la higiene dental en nuestra página de preguntas frecuentes. Explora nuestra recopilación de información detallada y concisa sobre cómo mantener una adecuada salud bucal, incluyendo técnicas de cepillado, uso de hilo dental, enjuague bucal y más. Nuestro equipo de expertos en odontología ha preparado respuestas basadas en su amplio conocimiento y experiencia en el campo. Descubre la importancia de la higiene dental para prevenir enfermedades bucales, como caries, enfermedad periodontal y mal aliento. Obtén consejos prácticos sobre cómo elegir los productos adecuados para la higiene dental y cómo incorporar una rutina efectiva en tu cuidado oral diario. Utiliza nuestras preguntas frecuentes como una valiosa fuente de información para mantener una sonrisa saludable y radiante. Estamos comprometidos en brindarte el conocimiento necesario para cuidar tu salud bucal y mejorar tu bienestar general.",
    questions: [
      {
        question: "¿Cuál es la importancia de la higiene dental?",
        answers: [
          "La higiene dental adecuada es esencial para mantener una buena salud bucal. La falta de higiene dental puede llevar a la acumulación de placa bacteriana, lo que puede provocar caries dentales, enfermedades de las encías y otros problemas de salud bucal.",

          "Además, los problemas de salud bucal pueden tener un impacto en la salud general del cuerpo, ya que pueden estar asociados con enfermedades crónicas como la diabetes, enfermedades cardíacas y la enfermedad de Alzheimer.",
        ],
      },
      {
        question: "¿Cómo se debe cepillar los dientes adecuadamente?",
        answers: [
          "Para cepillarse los dientes adecuadamente, es importante utilizar una técnica correcta.",

          "Coloque el cepillo de dientes en un ángulo de 45 grados hacia las encías y cepille suavemente en círculos pequeños. Asegúrese de cepillar todas las superficies de los dientes, incluidas las superficies internas y externas, así como la lengua y las encías.",

          "También es importante cepillarse los dientes durante al menos dos minutos dos veces al día, preferiblemente después de cada comida.",
        ],
      },
      {
        question: "¿Es importante el uso del hilo dental?",
        answers: [
          "Sí, el uso del hilo dental es muy importante para mantener una buena salud bucal. El hilo dental ayuda a eliminar la placa y los restos de alimentos que el cepillo de dientes no puede alcanzar, especialmente entre los dientes y a lo largo de la línea de las encías.",

          "Se recomienda usar hilo dental al menos una vez al día, preferiblemente antes de cepillarse los dientes por la noche.",
        ],
      },
      {
        question:
          "¿Cuál es la mejor manera de prevenir la enfermedad de las encías?",
        answers: [
          "La prevención de la enfermedad de las encías comienza con una buena higiene dental, incluyendo el cepillado adecuado y el uso regular del hilo dental.",

          "Además, es importante tener una dieta saludable y limitar los alimentos y bebidas azucaradas. También es importante programar regularmente visitas al dentista para chequeos y limpiezas dentales profesionales.",
        ],
      },
      {
        question: "¿Cómo puedo blanquear mis dientes de forma segura?",
        answers: [
          "Hay varias opciones seguras para blanquear los dientes, incluyendo tiras blanqueadoras, geles y pastas dentales blanqueadoras, y tratamientos dentales profesionales. Es importante hablar con su dentista para determinar cuál es la mejor opción para usted y seguir las instrucciones cuidadosamente para evitar cualquier daño a los dientes o las encías.",
        ],
      },
      {
        question: "¿Qué puedo hacer si tengo miedo de ir al dentista?",
        answers: [
          "Si tiene miedo de ir al dentista, puede ser útil hablar con su dentista sobre sus preocupaciones para que puedan trabajar juntos para encontrar una solución.",

          "También puede considerar opciones como la sedación consciente, que puede ayudar a reducir la ansiedad durante los procedimientos dentales.",

          "Además, puede ser útil encontrar un dentista que tenga experiencia en trabajar con pacientes ansiosos o que ofrezca opciones de entretenimiento, como televisión o música, durante los procedimientos dentales.",
        ],
      },
    ],
  },
  {
    title: "ENDODONCIA",
    breadcrumb: "Endodoncia",
    topic: "endodoncia",
    shortDescriptionSEO:
      "Encuentra respuestas a tus preguntas más frecuentes sobre la endodoncia en nuestra página de preguntas frecuentes. Explora nuestra recopilación de información detallada y concisa sobre este procedimiento dental, también conocido como tratamiento de conducto, que se utiliza para tratar y salvar dientes con infecciones o lesiones en la pulpa dental. Nuestro equipo de expertos en endodoncia ha preparado respuestas basadas en su amplio conocimiento y experiencia en el campo. Descubre cómo se realiza la endodoncia, cuáles son sus beneficios y posibles complicaciones. Obtén información sobre los síntomas que indican la necesidad de un tratamiento de conducto y qué esperar durante y después del procedimiento. Utiliza nuestras preguntas frecuentes como una valiosa fuente de información para comprender mejor la endodoncia y tomar decisiones informadas sobre tu salud dental. Estamos comprometidos en brindarte la información necesaria para cuidar tus dientes y preservar tu sonrisa.",
    questions: [
      {
        question: "¿Qué es una endodoncia?",
        answers: [
          "Una endodoncia es un procedimiento dental que se realiza para tratar la pulpa dental, el tejido blando dentro del diente que contiene nervios, vasos sanguíneos y tejido conectivo.",

          "Cuando la pulpa se inflama o infecta debido a una caries profunda, una fractura dental o una lesión, se puede realizar una endodoncia para salvar el diente y prevenir la propagación de la infección.",
        ],
      },
      {
        question: "¿Cómo sé si necesito una endodoncia?",
        answers: [
          "Los síntomas comunes que indican que se necesita una endodoncia incluyen dolor de dientes intenso, sensibilidad al calor o al frío, hinchazón y sensibilidad al morder o masticar.",

          "Si experimenta alguno de estos síntomas, es importante visitar a su dentista lo antes posible para un examen y diagnóstico adecuados.",
        ],
      },
      {
        question: "¿Qué sucede durante una endodoncia?",
        answers: [
          "Durante una endodoncia, se realiza una pequeña abertura en la corona del diente para acceder a la pulpa dental. Luego, se extrae la pulpa dañada y se limpia el interior del diente. Después, se sella el diente y se coloca una corona o relleno dental para protegerlo y restaurar su funcionalidad.",
        ],
      },
      {
        question: "¿Es dolorosa una endodoncia?",
        answers: [
          "No necesariamente. Antes del procedimiento, se administra anestesia local para adormecer la zona y minimizar el dolor. Además, la mayoría de los pacientes experimentan un alivio significativo del dolor después de la endodoncia.",
        ],
      },
      {
        question:
          "¿Cuánto tiempo tarda en recuperarse después de una endodoncia?",
        answers: [
          "La mayoría de los pacientes experimentan una recuperación completa en unos pocos días después de una endodoncia. Es posible que experimente una ligera incomodidad o sensibilidad en los días posteriores al procedimiento, pero esto puede aliviarse con analgésicos de venta libre.",
        ],
      },
      {
        question: "¿Hay alguna alternativa a la endodoncia?",
        answers: [
          "En algunos casos, como cuando la pulpa dental está demasiado dañada o hay una fractura dental significativa, puede ser necesario extraer el diente. Sin embargo, esto no es siempre la mejor opción, ya que la extracción dental puede tener consecuencias a largo plazo en la salud oral y la función dental.",

          "Es importante discutir todas las opciones con su dentista para determinar el mejor plan de tratamiento para usted.",
        ],
      },
    ],
  },
  {
    title: "ESTÉTICA DENTAL",
    breadcrumb: "Estética dental",
    topic: "estetica-dental",
    shortDescriptionSEO:
      "Encuentra respuestas a tus preguntas más frecuentes sobre la estética dental en nuestra página de preguntas frecuentes. Explora nuestra recopilación de información detallada y concisa sobre los diferentes procedimientos y tratamientos de estética dental disponibles para mejorar la apariencia de tu sonrisa. Nuestro equipo de expertos en odontología estética ha preparado respuestas basadas en su amplio conocimiento y experiencia en el campo. Descubre las opciones de blanqueamiento dental, carillas dentales, contorneado de encías y otros tratamientos que pueden transformar tu sonrisa. Obtén información sobre los beneficios, duración, cuidados y consideraciones antes y después de cada procedimiento estético dental. Utiliza nuestras preguntas frecuentes como una valiosa fuente de información para tomar decisiones informadas sobre la estética dental y lograr la sonrisa que siempre has deseado. Estamos comprometidos en brindarte el conocimiento necesario para mejorar tu confianza y bienestar dental.",
    questions: [
      {
        question: "¿Qué es la estética dental?",
        answers: [
          "La estética dental es una rama de la odontología que se enfoca en mejorar la apariencia de los dientes y la sonrisa de una persona. A través de una variedad de tratamientos, la estética dental puede corregir imperfecciones dentales, como manchas, decoloraciones, dientes torcidos, faltantes o desgastados, para mejorar la apariencia general de la sonrisa.",

          "Los tratamientos de estética dental más comunes incluyen blanqueamiento dental, carillas dentales, coronas y puentes dentales. El blanqueamiento dental se utiliza para eliminar manchas y decoloraciones de los dientes, mientras que las carillas dentales son delgadas capas de material que se adhieren a la superficie de los dientes para mejorar su apariencia. Las coronas y los puentes dentales se utilizan para restaurar dientes dañados o faltantes para mejorar la función y la apariencia dental.",

          "La estética dental no solo mejora la apariencia de los dientes, sino que también puede tener un impacto positivo en la autoestima y la confianza de una persona. Los tratamientos de estética dental pueden ayudar a una persona a sentirse más segura de su sonrisa y a tener una mejor calidad de vida. Por lo tanto, la estética dental se ha vuelto cada vez más popular y es una opción viable para aquellos que buscan mejorar la apariencia de sus dientes y sonrisa.",
        ],
      },
      {
        question: "¿Cómo puede mejorar mi sonrisa la estética dental?",
        answers: [
          "Uno de los problemas más comunes que se pueden abordar con la estética dental es la decoloración dental, que puede ser causada por el consumo de ciertos alimentos y bebidas, el tabaquismo y el envejecimiento natural. El blanqueamiento dental es uno de los tratamientos más populares para mejorar la decoloración dental y puede hacer que los dientes parezcan más brillantes y más blancos.",

          "Otra imperfección dental que puede abordarse con la estética dental es la alineación de los dientes. Los dientes torcidos o apiñados pueden ser corregidos con tratamientos como los brackets o los alineadores invisibles. Las carillas dentales también pueden utilizarse para cubrir las irregularidades en la alineación dental.",

          "Los dientes dañados, astillados o con fracturas también pueden ser restaurados con tratamientos de estética dental, como las carillas dentales, las coronas o los puentes dentales. Estos tratamientos pueden mejorar tanto la apariencia como la función dental.",

          "En resumen, la estética dental puede mejorar su sonrisa corrigiendo imperfecciones dentales como la decoloración, la alineación dental y los dientes dañados. Los tratamientos de estética dental pueden no solo mejorar la apariencia de sus dientes, sino también aumentar su autoestima y confianza. Si está interesado en mejorar la apariencia de su sonrisa, hable con su dentista sobre las opciones de estética dental que podrían funcionar para usted.",
        ],
      },
      {
        question:
          "¿Cuáles son los tratamientos de estética dental más comunes?",
        answers: [
          "La estética dental ofrece una amplia gama de tratamientos para mejorar la apariencia de los dientes y la sonrisa de una persona. Los tratamientos de estética dental más comunes incluyen:",

          "1.- Blanqueamiento dental: es uno de los tratamientos más populares y efectivos para mejorar la decoloración dental. Puede realizarse en la clínica dental o en casa con un kit de blanqueamiento dental proporcionado por el dentista.",

          "2.- Carillas dentales: son una cubierta delgada y personalizada que se coloca sobre la superficie frontal de los dientes para corregir la forma, el tamaño o el color de los dientes.",

          "3.- Coronas dentales: se utilizan para restaurar los dientes dañados o con fracturas. Una corona dental es una cubierta hecha de porcelana, metal o una combinación de ambos que se coloca sobre el diente natural para protegerlo y mejorar su apariencia.",

          "4.- Implantes dentales: se utilizan para reemplazar dientes faltantes y restaurar la función dental. Un implante dental es una raíz artificial de titanio que se coloca en la mandíbula y se cubre con una corona dental personalizada.",

          "5.- Ortodoncia: se utiliza para corregir la alineación dental y mejorar la sonrisa. Los tratamientos de ortodoncia incluyen brackets tradicionales, brackets estéticos y alineadores invisibles.",

          "Es importante hablar con su dentista sobre las opciones de tratamiento de estética dental disponibles para usted y elegir el tratamiento que mejor se adapte a sus necesidades y objetivos estéticos.",
        ],
      },
      {
        question: "¿Cuánto tiempo duran los tratamientos de estética dental?",
        answers: [
          "La duración de los resultados de los tratamientos de estética dental varía según el tipo de tratamiento y los cuidados posteriores del paciente. En general, el blanqueamiento dental puede durar de seis meses a dos años, dependiendo del estilo de vida del paciente y los hábitos alimenticios. Las carillas dentales pueden durar de 10 a 15 años con un cuidado adecuado, mientras que las coronas dentales y los puentes dentales pueden durar de 5 a 15 años o más.",

          "Es importante tener en cuenta que los tratamientos de estética dental no son permanentes y pueden requerir mantenimiento y reemplazo en el futuro. Es recomendable que los pacientes mantengan una buena higiene dental y visiten regularmente al dentista para mantener la salud dental y prolongar la duración de los resultados de los tratamientos de estética dental.",

          "Además, ciertos hábitos, como morder objetos duros o masticar hielo, pueden dañar los tratamientos de estética dental y acortar su duración. Los pacientes deben evitar estos hábitos para preservar sus tratamientos de estética dental durante el mayor tiempo posible.",

          "En resumen, la duración de los resultados de los tratamientos de estética dental varía según el tipo de tratamiento y los cuidados posteriores del paciente. Es importante que los pacientes mantengan una buena higiene dental y visiten regularmente al dentista para prolongar la duración de los resultados de los tratamientos de estética dental. Además, evitar ciertos hábitos puede ayudar a preservar los tratamientos de estética dental durante más tiempo.",
        ],
      },
      {
        question: "¿Cuál es el costo de los tratamientos de estética dental?",
        answers: [
          "El costo de los tratamientos de estética dental puede variar ampliamente según el tipo de tratamiento, la ubicación geográfica, el dentista y otros factores. Algunos tratamientos pueden ser más caros que otros, pero también pueden ofrecer resultados más duraderos y efectivos.",

          "Es importante recordar que los tratamientos de estética dental no siempre están cubiertos por el seguro dental, y que los costos pueden variar según el dentista y la ubicación geográfica. Sin embargo, en nuestra clínica ofrecemos opciones de financiamiento y planes de pago para ayudar a que los tratamientos de estética dental sean más accesibles y asequibles para nuestros pacientes.",
        ],
      },
      {
        question:
          "¿Quiénes son candidatos para los tratamientos de estética dental?",
        answers: [
          "En general, los candidatos ideales para la estética dental son aquellos que tienen dientes sanos y encías saludables, pero que están insatisfechos con la apariencia de su sonrisa.",

          "Los tratamientos de estética dental pueden ser especialmente beneficiosos para personas con dientes manchados, astillados, desalineados o desgastados. También pueden ser una buena opción para aquellos que tienen espacios entre los dientes o dientes irregulares. Sin embargo, es importante que cualquier problema dental subyacente, como caries o enfermedad periodontal, se trate antes de considerar la estética dental.",

          "En última instancia, el mejor candidato para un tratamiento de estética dental es aquel que tiene una buena salud dental general y está buscando mejorar la apariencia de su sonrisa. Un dentista capacitado en estética dental puede ayudar a determinar qué tratamiento sería el mejor para lograr los resultados deseados.",
        ],
      },
    ],
  },
  {
    title: "EXTRACCIONES",
    breadcrumb: "Extracciones",
    topic: "extracciones",
    shortDescriptionSEO:
      "Encuentra respuestas a tus preguntas más frecuentes sobre las extracciones dentales en nuestra página de preguntas frecuentes. Explora nuestra recopilación de información detallada y concisa sobre este procedimiento dental que consiste en la remoción de un diente o muela. Nuestro equipo de expertos en odontología ha preparado respuestas basadas en su amplio conocimiento y experiencia en el campo. Descubre cuándo es necesario realizar una extracción dental, qué esperar durante el procedimiento y cómo cuidar adecuadamente la zona después de la extracción. Obtén información sobre los posibles riesgos y complicaciones, así como las medidas de precaución recomendadas. Utiliza nuestras preguntas frecuentes como una valiosa fuente de información para comprender mejor las extracciones dentales y sentirte más preparado/a para tu cita con el dentista. Estamos comprometidos en brindarte el conocimiento necesario para cuidar de tu salud dental y proporcionarte una experiencia dental cómoda y segura.",
    questions: [
      {
        question: "¿Qué es una extracción dental y cuándo se necesita?",
        answers: [
          "Una extracción dental es el procedimiento en el cual se remueve un diente de su alvéolo dental. La extracción dental se realiza cuando el diente está demasiado dañado o no tiene la posibilidad de ser salvado por tratamientos como la endodoncia o la restauración dental. Los dentistas también pueden recomendar una extracción dental si los dientes de una persona están demasiado apiñados y no hay suficiente espacio para que los dientes crezcan, o si hay una infección dental que pueda extenderse a otras partes del cuerpo.",

          "Antes de la extracción, el dentista examinará la boca del paciente y tomará radiografías para determinar la mejor forma de extraer el diente. Luego, se aplicará anestesia local para adormecer la zona de la extracción. En algunos casos, se puede requerir anestesia general. Después de la extracción, el paciente puede experimentar un poco de dolor y sangrado, pero siguiendo las instrucciones del dentista para el cuidado adecuado de la extracción, la recuperación debería ser rápida.",

          "Si un diente necesita ser extraído, no espere a que el dolor se vuelva insoportable o la infección se extienda. Es importante buscar atención dental temprana para evitar complicaciones y lograr una recuperación más rápida.",
        ],
      },
      {
        question: "¿Es doloroso el proceso de extracción dental?",
        answers: [
          "La idea de someterse a una extracción dental puede ser preocupante para muchas personas, especialmente si están preocupados por el dolor. Sin embargo, con la tecnología moderna y el uso de anestesia local, la extracción dental no debería ser dolorosa.",

          "El dentista aplicará anestesia local para adormecer la zona de la extracción, lo que significa que el paciente no sentirá ningún dolor durante el procedimiento. Además, si se necesita una extracción más complicada, como la extracción de un molar impactado, se puede proporcionar anestesia general.",

          "Después del procedimiento, el paciente puede experimentar un poco de dolor y molestia, pero esto es normal y puede ser manejado con analgésicos recetados por el dentista. Es importante seguir las instrucciones del dentista para el cuidado adecuado después de la extracción para evitar complicaciones y asegurar una recuperación rápida.",

          "Si el dolor persiste más allá de unos pocos días, es importante contactar al dentista para evaluar la situación y garantizar una curación adecuada. En general, la extracción dental no es dolorosa gracias a los avances tecnológicos y la aplicación adecuada de anestesia local.",
        ],
      },
      {
        question: "¿Cómo es el proceso de extracción dental?",
        answers: [
          "El proceso de extracción dental comienza con una evaluación del dentista, quien determinará si la extracción es necesaria. Si se decide que la extracción es necesaria, el dentista programará una cita para llevar a cabo el procedimiento.",

          "Antes de comenzar la extracción, el dentista aplicará anestesia local para adormecer la zona de la extracción, lo que asegura que el paciente no sienta dolor durante el procedimiento. En algunos casos, como la extracción de un molar impactado, se puede proporcionar anestesia general.",

          "Una vez que se ha adormecido la zona, el dentista utilizará herramientas especializadas para aflojar el diente y retirarlo cuidadosamente. En algunos casos, el dentista puede necesitar dividir el diente en secciones más pequeñas para facilitar la extracción.",

          "Después de la extracción, el dentista puede colocar puntos de sutura para cerrar la herida y promover una curación adecuada. También se proporcionarán instrucciones detalladas sobre cómo cuidar el área de la extracción para prevenir complicaciones y acelerar la recuperación.",

          "Es normal experimentar un poco de dolor y molestia después de la extracción, pero esto puede ser manejado con analgésicos recetados por el dentista. Si el dolor persiste o se desarrollan complicaciones, es importante contactar al dentista de inmediato para recibir tratamiento adicional. En general, el proceso de extracción dental es rápido y sencillo cuando se realiza por un dentista experimentado y calificado.",
        ],
      },
      {
        question:
          "¿Qué precauciones debo tomar después de una extracción dental?",
        answers: [
          "Después de una extracción dental, es importante seguir ciertas precauciones para promover una buena cicatrización y reducir el riesgo de complicaciones. En primer lugar, es común experimentar un poco de dolor e hinchazón después del procedimiento, por lo que se puede aplicar una compresa fría en la zona afectada para reducir la inflamación. También se puede tomar algún analgésico prescrito por el dentista para controlar el dolor.",

          "Es recomendable evitar comer alimentos duros, calientes o picantes durante las primeras horas después de la extracción dental, y beber líquidos fríos o a temperatura ambiente para no molestar la zona afectada. También se debe evitar fumar o consumir alcohol durante al menos 24 horas después de la extracción dental, ya que puede aumentar el riesgo de sangrado y retrasar la cicatrización.",

          "Es importante mantener una buena higiene bucal después de la extracción dental, cepillando suavemente los dientes cercanos a la zona afectada, evitando cepillar directamente en el lugar de la extracción. El dentista puede proporcionar instrucciones específicas y recomendaciones para el cuidado posterior de la extracción dental según el caso individual de cada paciente. Seguir estas precauciones ayudará a una buena recuperación después de una extracción dental.",
        ],
      },
      {
        question:
          "¿Cuánto tiempo tarda la recuperación después de una extracción dental?",
        answers: [
          "El tiempo de recuperación después de una extracción dental varía según la complejidad del procedimiento y la capacidad del paciente para sanar. En general, se espera que la recuperación total tome alrededor de una semana a diez días. Durante los primeros días después de la extracción dental, se recomienda descansar lo suficiente y evitar cualquier actividad física intensa que pueda aumentar la presión sanguínea en la boca.",

          "Es normal experimentar hinchazón y dolor en el área afectada después de la extracción dental, y se puede utilizar hielo para reducir la hinchazón y analgésicos para controlar el dolor. También se recomienda evitar alimentos duros y calientes, así como tabaco y alcohol durante la recuperación. Los pacientes deben seguir cuidadosamente las instrucciones de su dentista sobre cómo cuidar la zona afectada y tomar cualquier medicamento recetado.",

          "Si experimenta síntomas como fiebre, dolor intenso o sangrado excesivo después de la extracción dental, debe comunicarse con su dentista de inmediato. En general, es importante mantener una buena higiene dental después de la extracción para evitar complicaciones y garantizar una recuperación rápida y efectiva.",
        ],
      },
      {
        question:
          "¿Cuáles son las posibles complicaciones después de una extracción dental?",
        answers: [
          "Después de una extracción dental, es posible que surjan algunas complicaciones, aunque esto no es muy común. Algunas de las complicaciones más comunes son dolor, hinchazón, sangrado, infección y sequedad en la boca.",

          "El dolor es común después de una extracción dental y puede ser controlado con analgésicos recetados por el dentista. La hinchazón es normal y puede durar unos días. La aplicación de hielo en la zona afectada durante las primeras 24 horas puede ayudar a reducir la hinchazón.",

          "El sangrado leve es normal después de una extracción dental, pero si el sangrado es excesivo, debe informarse al dentista. La infección puede ocurrir si la zona de extracción no se mantiene limpia o si se toca la zona con las manos sucias. El enjuague con agua salada tibia puede ayudar a prevenir la infección.",

          "La sequedad en la boca es común después de la extracción de un diente, ya que el área afectada puede estar sensible y no producir tanta saliva. Es importante mantenerse hidratado y evitar alimentos secos y pegajosos.",

          "Si experimenta alguna complicación después de una extracción dental, es importante comunicarse con el dentista de inmediato para recibir tratamiento y evitar complicaciones mayores.",
        ],
      },
    ],
  },
  {
    title: "HALITOSIS",
    breadcrumb: "Halitosis",
    topic: "halitosis",
    shortDescriptionSEO:
      "Encuentra respuestas a tus preguntas más frecuentes sobre la halitosis en nuestra página de preguntas frecuentes. Explora nuestra recopilación de información detallada y concisa sobre este problema común de mal aliento. Nuestro equipo de expertos en odontología ha preparado respuestas basadas en su amplio conocimiento y experiencia en el campo. Descubre las posibles causas de la halitosis, desde la higiene bucal deficiente hasta problemas de salud subyacentes. Obtén información sobre cómo prevenir y tratar el mal aliento, incluyendo recomendaciones de cuidado oral, cambios en la dieta y otros consejos útiles. Utiliza nuestras preguntas frecuentes como una valiosa fuente de información para comprender mejor la halitosis y encontrar soluciones efectivas para mantener un aliento fresco y una buena salud bucal. Estamos comprometidos en brindarte el conocimiento necesario para superar este problema y disfrutar de una sonrisa saludable y confiada.",
    questions: [
      {
        question: "¿Qué es la halitosis y cuáles son sus causas más comunes?",
        answers: [
          "La halitosis, más comúnmente conocida como mal aliento, es un problema muy común que afecta a muchas personas. Se caracteriza por el olor desagradable que emana de la boca, y puede ser causado por una variedad de factores. Las causas más comunes de la halitosis son la falta de higiene oral adecuada, la presencia de bacterias en la boca, la sequedad bucal, la ingesta de ciertos alimentos y bebidas, el tabaquismo y la presencia de enfermedades dentales y sistémicas.",

          "La falta de higiene oral adecuada es una de las principales causas de la halitosis, ya que la acumulación de placa bacteriana y alimentos en los dientes y encías puede causar la liberación de compuestos de olor desagradable. La presencia de bacterias en la boca también puede ser una causa importante de la halitosis, ya que estas bacterias pueden liberar gases y compuestos que contribuyen al mal aliento.",

          "La sequedad bucal también puede contribuir a la halitosis, ya que la saliva ayuda a mantener la boca húmeda y neutralizar los ácidos producidos por las bacterias. La ingesta de ciertos alimentos y bebidas, como el ajo, la cebolla y el café, también puede contribuir al mal aliento. El tabaquismo es otro factor importante, ya que el humo del tabaco puede producir un olor desagradable en la boca y contribuir a la sequedad bucal.",

          "La presencia de enfermedades dentales y sistémicas, como la caries dental, la enfermedad periodontal, la sinusitis y la diabetes, también puede ser una causa de la halitosis. Es importante consultar a un dentista para determinar la causa subyacente de la halitosis y recibir el tratamiento adecuado.",
        ],
      },
      {
        question:
          "¿Cuáles son los síntomas de la halitosis y cómo puedo saber si la tengo?",
        answers: [
          "La halitosis, comúnmente conocida como mal aliento, puede ser un problema difícil de detectar por uno mismo. Los síntomas más evidentes son el olor desagradable en la boca, que puede ser notado por las personas que están cerca, así como el sabor amargo o metálico en la boca. Además, algunas personas pueden tener una capa blanca o amarillenta en la lengua, así como la sensación de sequedad en la boca.",

          "Si tienes dudas sobre si tienes halitosis, puedes preguntarle a alguien de confianza si notas que evita acercarse demasiado a ti o si cubre su nariz cuando hablas. También puedes frotar un dedo en la parte posterior de la lengua y olerlo para comprobar si hay mal aliento.",

          "Es importante mencionar que algunas personas pueden tener halitosis crónica debido a ciertas condiciones de salud o hábitos de estilo de vida, mientras que otras pueden experimentarla solo ocasionalmente. Si tienes dudas o estás preocupado por el mal aliento persistente, es recomendable que consultes a un profesional dental para recibir una evaluación y un diagnóstico preciso.",
        ],
      },
      {
        question:
          "¿Cómo puedo prevenir y tratar la halitosis de manera efectiva?",
        answers: [
          "La halitosis, también conocida como mal aliento, puede ser un problema embarazoso y desagradable. Afortunadamente, hay varios métodos efectivos para prevenir y tratar la halitosis.",

          "La primera medida para prevenir la halitosis es mantener una buena higiene oral. Esto incluye cepillar los dientes dos veces al día y usar hilo dental a diario. También es importante cepillar la lengua y las encías, ya que las bacterias pueden acumularse en estas áreas y causar mal aliento.",

          "Además de la higiene oral, es importante evitar ciertos alimentos y bebidas que pueden causar halitosis, como el ajo, la cebolla, el café y el alcohol. También se recomienda beber mucha agua para mantener la boca hidratada y reducir la sequedad bucal, que puede contribuir al mal aliento.",

          "Si la halitosis persiste a pesar de tomar estas medidas preventivas, es posible que se necesite tratamiento adicional. En algunos casos, puede ser necesario tratar problemas de salud subyacentes que están causando el mal aliento, como la enfermedad de las encías o problemas digestivos.",

          "En resumen, prevenir y tratar la halitosis implica mantener una buena higiene oral, evitar ciertos alimentos y bebidas, beber suficiente agua y, si es necesario, buscar tratamiento médico para problemas de salud subyacentes. Con estos métodos efectivos, puedes mantener tu aliento fresco y tu confianza alta.",
        ],
      },
      {
        question:
          "¿La halitosis está relacionada con problemas de salud más graves?",
        answers: [
          "Aunque la halitosis es comúnmente asociada con problemas de higiene bucal, también puede ser un síntoma de problemas de salud más graves. La halitosis puede ser causada por enfermedades en las encías, caries, placa bacteriana, y otros problemas dentales. Sin embargo, también puede ser un síntoma de problemas de salud más serios, como enfermedades respiratorias, trastornos metabólicos, enfermedades del hígado o riñones, o incluso ciertos tipos de cáncer.",

          "Si la halitosis se acompaña de otros síntomas, como fiebre, tos, dolor de garganta, dificultad para respirar, pérdida de peso, o fatiga, es importante buscar atención médica inmediata. Los problemas de salud subyacentes pueden ser tratados y prevenidos, lo que a su vez puede ayudar a mejorar la halitosis.",

          "En general, la prevención de la halitosis implica una buena higiene bucal, incluyendo cepillarse los dientes al menos dos veces al día, usar hilo dental, enjuagues bucales y visitar al dentista regularmente. Además, se recomienda evitar ciertos alimentos, como ajo y cebolla, que pueden causar mal aliento, y mantenerse hidratado bebiendo suficiente agua. En caso de que la halitosis persista a pesar de estas medidas, es importante buscar atención médica y dental para determinar la causa subyacente y recibir tratamiento adecuado.",
        ],
      },
      {
        question:
          "¿Cómo afecta la halitosis a las relaciones interpersonales y la autoestima?",
        answers: [
          "La halitosis o mal aliento puede tener un impacto negativo en las relaciones interpersonales y la autoestima de una persona. A menudo, las personas que sufren de halitosis pueden sentirse avergonzadas o inseguras acerca de su aliento y evitar situaciones sociales. Además, el mal aliento puede afectar la calidad de las relaciones personales, ya que las personas pueden sentirse incómodas al interactuar con alguien que tiene un aliento desagradable.",

          "La halitosis también puede tener un impacto en la autoestima, ya que las personas pueden sentirse avergonzadas de su aliento y tener una baja autoconfianza en situaciones sociales. Esto puede llevar a una disminución en la calidad de vida y afectar la salud mental.",

          "Es importante abordar la halitosis de manera efectiva para mejorar la autoestima y las relaciones interpersonales. Un tratamiento adecuado de la halitosis puede mejorar la confianza en uno mismo y la calidad de vida en general. Los dentistas pueden ayudar a identificar la causa subyacente de la halitosis y ofrecer tratamientos efectivos para prevenirla y tratarla.",
        ],
      },
      {
        question:
          "¿Cuándo debo consultar a un dentista o especialista si tengo halitosis persistente?",
        answers: [
          "Si experimentas halitosis persistente, es importante consultar a un dentista o especialista para determinar la causa subyacente. En algunos casos, la halitosis puede ser un síntoma de una afección subyacente más grave, como enfermedades periodontales, caries, enfermedad de las encías, sinusitis, diabetes o incluso cáncer.",

          "El dentista o especialista realizará un examen dental completo y revisará tu historial médico para determinar la causa de tu halitosis. Pueden recomendarte tratamientos específicos según la causa subyacente de tu halitosis, como la eliminación de la placa dental, la limpieza de las encías y el tratamiento de las caries. También pueden recomendarte cambios en tu dieta, mejorar la higiene oral o incluso derivarte a un especialista para un tratamiento adicional.",

          "En resumen, si experimentas halitosis persistente, es importante consultar a un dentista o especialista para determinar la causa subyacente y recibir tratamiento adecuado para prevenir complicaciones graves en la salud oral y general.",
        ],
      },
    ],
  },
  {
    title: "IMPLANTES",
    breadcrumb: "Implantes",
    topic: "implantes",
    shortDescriptionSEO:
      "Encuentra respuestas a tus preguntas más frecuentes sobre los implantes dentales en nuestra página de preguntas frecuentes. Explora nuestra recopilación de información detallada y concisa sobre este tratamiento innovador para reemplazar dientes perdidos. Nuestro equipo de expertos en odontología ha preparado respuestas basadas en su amplio conocimiento y experiencia en el campo. Descubre qué son los implantes dentales, cómo funcionan y cuáles son sus beneficios en comparación con otras opciones de reemplazo dental. Obtén información sobre el proceso de colocación de implantes, tiempos de cicatrización, cuidados posteriores y resultados esperados. Utiliza nuestras preguntas frecuentes como una valiosa fuente de información para entender mejor los implantes dentales y tomar decisiones informadas sobre tu salud bucal. Estamos comprometidos en brindarte el conocimiento necesario para restaurar tu sonrisa y disfrutar de una función dental óptima.",
    questions: [
      {
        question: "¿Qué son los implantes dentales y cómo funcionan?",
        answers: [
          "Los implantes dentales son una solución duradera y efectiva para reemplazar dientes faltantes. Consisten en pequeñas estructuras de titanio que se colocan en el hueso de la mandíbula o maxilar, y actúan como raíces artificiales que sostienen una prótesis dental, como una corona o puente.",

          "Los implantes dentales funcionan integrándose en el hueso y creando una conexión estable y segura. Una vez colocados, los implantes necesitan un período de tiempo para que el hueso los integre completamente, lo que se conoce como osteointegración. Después de este proceso, se puede colocar una prótesis dental personalizada sobre el implante, que se verá y funcionará como un diente natural.",

          "Los implantes dentales son una opción popular para aquellos que buscan una alternativa a las dentaduras postizas o puentes. Además, pueden ayudar a mantener la estructura ósea facial y la alineación dental al prevenir la reabsorción ósea y la migración de los dientes adyacentes.",

          "Si estás considerando implantes dentales, es importante programar una consulta con un dentista o especialista en implantes para evaluar tu salud dental y determinar si eres un candidato adecuado para este tratamiento.",
        ],
      },
      {
        question: "¿Soy un candidato para los implantes dentales?",
        answers: [
          "Los implantes dentales son una excelente opción para reemplazar dientes perdidos o dañados y recuperar la funcionalidad y estética de la boca. Si estás considerando un implante dental, es importante saber si eres un buen candidato para el procedimiento.",

          "En general, los candidatos ideales para los implantes dentales son aquellos que tienen buena salud bucal y general, y que tienen suficiente densidad ósea en la mandíbula para soportar el implante. También es importante que no fumen, ya que el tabaco puede interferir en el proceso de curación.",

          "Sin embargo, incluso si no cumples con todos estos requisitos, aún podrías ser un candidato para los implantes dentales. En algunos casos, se pueden tomar medidas para mejorar la salud bucal o la densidad ósea antes del procedimiento.",

          "Para determinar si eres un candidato para los implantes dentales, es importante programar una consulta con un dentista o especialista en implantes dentales. Ellos realizarán un examen completo de tu boca y radiografías para evaluar la salud de tus dientes y huesos, y discutirán tus opciones de tratamiento contigo.",
        ],
      },
      {
        question:
          "¿Cuánto tiempo dura el proceso de colocación de implantes dentales?",
        answers: [
          "El proceso de colocación de implantes dentales generalmente toma varios meses y puede variar según las necesidades específicas de cada paciente. En una primera consulta, el dentista o especialista evaluará la salud bucal del paciente, incluyendo la calidad del hueso y la cantidad de espacio disponible para el implante. Si se requiere algún procedimiento previo, como la extracción de dientes o la realización de un injerto óseo, esto puede prolongar el proceso.",

          "Una vez que se completa la evaluación, se programará la cirugía de colocación del implante dental. Durante la cirugía, se colocará un pequeño tornillo de titanio en el hueso maxilar o mandibular. Después de la cirugía, se requerirá un tiempo de cicatrización para que el implante se fusione con el hueso circundante en un proceso llamado osteointegración. Esto puede tomar de 3 a 6 meses dependiendo de cada caso.",

          "Una vez que se complete la osteointegración, se colocará el pilar o abutment sobre el implante y finalmente la corona o prótesis dental, restaurando la función y estética de la dentadura. Es importante seguir las instrucciones del dentista durante todo el proceso para asegurar una recuperación adecuada y un resultado exitoso.",
        ],
      },
      {
        question:
          "¿Es doloroso el proceso de colocación de implantes dentales?",
        answers: [
          "La colocación de implantes dentales es un procedimiento quirúrgico que puede parecer intimidante para muchas personas. Una de las preguntas más comunes que los pacientes hacen es si el proceso es doloroso. Afortunadamente, los avances en técnicas quirúrgicas y anestesia han hecho que la colocación de implantes sea un procedimiento bastante cómodo para la mayoría de los pacientes.",

          "Antes de comenzar el procedimiento, el dentista o cirujano oral aplicará anestesia local para adormecer la zona en la que se colocará el implante. Esto significa que no debería sentir ningún dolor durante la cirugía. Además, algunos pacientes pueden optar por sedación para ayudar a relajarse durante el procedimiento.",

          "Después de la cirugía, puede esperar cierto grado de incomodidad e hinchazón en el área de la boca, pero se controla fácilmente con medicamentos para el dolor y la inflamación recetados por el dentista o cirujano oral.",

          "En resumen, el proceso de colocación de implantes dentales es un procedimiento seguro y relativamente indoloro gracias a la anestesia local y los medicamentos para el dolor. Si tiene más preguntas sobre el procedimiento, consulte con su dentista o cirujano oral.",
        ],
      },
      {
        question: "¿Cuánto tiempo duran los implantes dentales?",
        answers: [
          "Los implantes dentales son una solución duradera y efectiva para reemplazar los dientes perdidos. A diferencia de las prótesis dentales removibles, los implantes son una opción permanente y pueden durar toda la vida si se cuidan adecuadamente.",

          "La duración de los implantes dentales depende de varios factores, como la calidad del hueso, la ubicación del implante y la salud bucal general del paciente. Si el implante se coloca en una zona con buena densidad ósea y se cuida adecuadamente, puede durar muchos años. Los pacientes que fuman o tienen problemas de salud como la diabetes pueden tener un mayor riesgo de complicaciones y una menor duración de los implantes.",

          "Es importante recordar que los implantes dentales no son indestructibles y pueden sufrir daños si se someten a fuerzas excesivas o si no se cuidan adecuadamente. Para maximizar la duración de los implantes dentales, se recomienda mantener una buena higiene bucal, visitar regularmente al dentista y evitar hábitos como morder objetos duros o masticar hielo.",
        ],
      },
      {
        question:
          "¿Cuáles son los cuidados necesarios después de colocar implantes dentales?",
        answers: [
          "Después de la colocación de los implantes dentales, es importante seguir ciertas precauciones para garantizar una buena cicatrización y mantener la salud de la boca. En general, se recomienda mantener una buena higiene dental, cepillando los dientes dos veces al día y usando hilo dental diariamente. También se debe evitar fumar y limitar el consumo de alcohol.",

          "En los días siguientes a la colocación de los implantes dentales, es común experimentar cierta inflamación y dolor. Para aliviar estos síntomas, se pueden utilizar compresas frías y medicamentos recetados por el dentista, como analgésicos o antiinflamatorios.",

          "Es importante seguir una dieta suave y evitar alimentos duros o pegajosos que puedan dañar los implantes dentales y la zona en la que se han colocado. También se debe evitar masticar con la zona afectada y seguir las recomendaciones del dentista en cuanto a la limpieza y cuidado de la boca.",

          "En resumen, seguir los cuidados necesarios después de la colocación de los implantes dentales es fundamental para asegurar su durabilidad y mantener una buena salud oral. Si se presentan complicaciones o dudas, es importante consultar al dentista.",
        ],
      },
    ],
  },
  {
    title: "ODONTOPEDIATRÍA",
    breadcrumb: "Odontopediatría",
    topic: "odontopediatria",
    shortDescriptionSEO:
      "Encuentra respuestas a tus preguntas más frecuentes sobre odontopediatría en nuestra página de preguntas frecuentes. Explora nuestra recopilación de información detallada y concisa sobre el cuidado dental infantil. Nuestro equipo de odontopediatras ha preparado respuestas basadas en su experiencia y conocimientos especializados en el campo. Descubre la importancia de la odontopediatría y cómo garantizar una buena salud bucal desde la infancia. Obtén información sobre los cuidados dentales específicos para niños, incluyendo la prevención de caries, el desarrollo de una buena higiene oral y la visita regular al dentista. Utiliza nuestras preguntas frecuentes como una valiosa fuente de información para entender mejor el cuidado dental de los más pequeños y tomar decisiones informadas sobre su salud bucal. Estamos comprometidos en brindarte el conocimiento necesario para fomentar sonrisas saludables y duraderas desde temprana edad.",
    questions: [
      {
        question:
          "¿Desde qué edad es necesario llevar a los niños al dentista?",
        answers: [
          "La salud dental es importante para todas las edades, incluyendo los niños pequeños. De hecho, los expertos recomiendan llevar a los niños al dentista por primera vez antes de su primer cumpleaños o dentro de los seis meses posteriores a la aparición del primer diente. Esto se debe a que la caries dental y otras enfermedades dentales pueden comenzar a desarrollarse temprano, incluso antes de que todos los dientes hayan salido.",

          "Además, llevar a los niños al dentista desde una edad temprana puede ayudar a establecer hábitos de higiene dental adecuados y ayudarles a sentirse cómodos en el consultorio del dentista, lo que puede reducir la ansiedad dental en el futuro. Los dentistas pueden examinar la boca de los niños para detectar cualquier problema, como caries, maloclusión o problemas en el desarrollo de los dientes y la mandíbula.",

          "Es importante recordar que la prevención es clave para una buena salud dental, y llevar a los niños al dentista desde temprana edad puede ayudar a prevenir problemas más graves en el futuro. Si tienes un niño pequeño, asegúrate de programar su primera visita al dentista lo antes posible para asegurarte de que su salud dental esté en buen estado.",
        ],
      },
      {
        question:
          "¿Cómo preparar a mi hijo para su primera visita al dentista?",
        answers: [
          "La primera visita al dentista puede ser una experiencia abrumadora para los niños, especialmente si no están acostumbrados a los exámenes dentales. Afortunadamente, hay algunas cosas que los padres pueden hacer para ayudar a preparar a sus hijos para su primera visita al dentista y hacer que la experiencia sea menos estresante.",

          "En primer lugar, es importante hablar con tu hijo sobre lo que sucederá en la visita al dentista. Explícale que el dentista revisará sus dientes para asegurarse de que estén sanos y que no le dolerá. También puedes leer libros o ver videos sobre visitas al dentista para ayudar a tu hijo a entender mejor lo que sucederá.",

          "También es útil programar la visita para una hora del día en que tu hijo esté descansado y cómodo. Asegúrate de llegar a la cita con suficiente tiempo de antelación para que tu hijo pueda explorar el consultorio y conocer al personal antes de la cita.",

          "Por último, es importante elogiar a tu hijo y recompensarlo después de la visita al dentista. Hacer que la experiencia sea positiva puede ayudar a reducir la ansiedad dental en el futuro y hacer que las visitas al dentista sean más fáciles en general.",

          "En resumen, hablar con tu hijo sobre la visita al dentista, programar la cita en un momento adecuado y recompensar a tu hijo después de la visita son algunas cosas que puedes hacer para ayudar a preparar a tu hijo para su primera visita al dentista.",
        ],
      },
      {
        question:
          "¿Cuáles son las principales enfermedades bucodentales en la infancia?",
        answers: [
          "La salud dental es importante para todas las edades, especialmente para los niños, ya que pueden ser más susceptibles a ciertas enfermedades bucodentales. Algunas de las principales enfermedades bucodentales en la infancia incluyen:",

          "1.- Caries dental: La caries dental es una de las enfermedades bucodentales más comunes en los niños. Se produce cuando los ácidos de las bacterias de la placa dental erosionan el esmalte dental, lo que puede causar dolor y sensibilidad dental.",

          "2.- Maloclusión: La maloclusión es una afección en la que los dientes del niño no se alinean correctamente, lo que puede causar problemas en la masticación y la articulación de la mandíbula.",

          "3.- Gingivitis: La gingivitis es una inflamación de las encías que puede causar sangrado, enrojecimiento e hinchazón en la zona afectada.",

          "4.- Traumatismos dentales: Los traumatismos dentales pueden ocurrir cuando un niño sufre un golpe o una caída que afecta a los dientes. Esto puede provocar fracturas, desplazamiento o pérdida de dientes.",

          "Es importante que los padres supervisen la higiene dental de sus hijos desde una edad temprana y los lleven a revisiones regulares con el dentista para prevenir y detectar cualquier problema bucodental. Con una buena higiene dental y cuidado preventivo, se pueden prevenir muchas enfermedades bucodentales en la infancia.",
        ],
      },
      {
        question:
          "¿Qué cuidados debo tener con la alimentación de mi hijo para prevenir problemas dentales?",
        answers: [
          "La alimentación juega un papel importante en la salud dental de los niños. Una dieta equilibrada y saludable puede ayudar a prevenir problemas dentales, como caries y enfermedades de las encías. A continuación se presentan algunos consejos para ayudar a los padres a cuidar la alimentación de sus hijos:",

          "1.- Limitar el consumo de alimentos azucarados: Los alimentos con alto contenido de azúcar, como dulces y refrescos, pueden aumentar el riesgo de caries dentales. Los padres deben limitar el consumo de estos alimentos y fomentar opciones más saludables, como frutas y verduras.",

          "2.- Elegir alimentos ricos en calcio: Los alimentos ricos en calcio, como los productos lácteos, son importantes para el desarrollo de huesos y dientes fuertes.",

          "3.- Fomentar una buena hidratación: Beber suficiente agua es importante para la salud dental, ya que puede ayudar a prevenir la sequedad bucal y promover la producción de saliva, que ayuda a proteger los dientes.",

          "4.- Evitar alimentos pegajosos: Los alimentos pegajosos, como los caramelos y las gomitas, pueden quedarse pegados a los dientes y aumentar el riesgo de caries. Es mejor evitar estos alimentos o asegurarse de que los niños se cepillen los dientes después de comerlos.",

          "En resumen, una dieta saludable y equilibrada puede ayudar a prevenir problemas dentales en los niños. Limitar el consumo de alimentos azucarados, elegir alimentos ricos en calcio, fomentar una buena hidratación y evitar alimentos pegajosos son algunos consejos útiles para cuidar la alimentación de los niños y proteger su salud dental.",
        ],
      },
      {
        question: "¿Cómo prevenir el miedo al dentista en los niños?",
        answers: [
          "El miedo al dentista es un problema común entre los niños y puede dificultar la atención dental adecuada. A continuación se presentan algunos consejos para ayudar a prevenir el miedo al dentista en los niños:",

          "1.- Empezar las visitas temprano: Es importante llevar a los niños al dentista desde una edad temprana, incluso antes de que tengan dientes. Esto ayuda a familiarizarlos con el ambiente y las herramientas del dentista desde una edad temprana.",

          "2.- Usar un lenguaje positivo: Los padres deben evitar hablar de las visitas al dentista en términos negativos o asustar a los niños con historias de terror. En su lugar, deben usar un lenguaje positivo y explicar de forma clara y sencilla lo que sucederá en la visita al dentista.",

          "3.- Fomentar el juego de roles: Los padres pueden ayudar a los niños a sentirse cómodos en el dentista jugando a ser el dentista o el paciente en casa. Esto puede ayudar a los niños a comprender lo que sucederá en la visita real.",

          "4.- Ofrecer recompensas: Los padres pueden ofrecer una pequeña recompensa, como un juguete o una golosina saludable, después de una visita exitosa al dentista. Esto puede ayudar a los niños a asociar la visita al dentista con algo positivo.",

          "En resumen, la prevención del miedo al dentista en los niños requiere de una combinación de factores, incluyendo el inicio temprano de las visitas, el uso de un lenguaje positivo, la práctica del juego de roles y la oferta de recompensas. Al tomar medidas preventivas para hacer que las visitas al dentista sean más cómodas y seguras, los padres pueden ayudar a garantizar una buena salud dental para sus hijos a largo plazo.",
        ],
      },
      {
        question:
          "¿Cuáles son las opciones de tratamiento para corregir los dientes torcidos o apiñados en los niños?",
        answers: [
          "Los dientes torcidos o apiñados son comunes en los niños y pueden afectar su salud dental y su autoestima. Afortunadamente, existen varias opciones de tratamiento para corregir estos problemas. A continuación se presentan algunas de las opciones de tratamiento más comunes:",

          "1.- Brackets: Los brackets son aparatos que se adhieren a los dientes y se conectan con un arco para aplicar presión y mover los dientes a su posición correcta. Los brackets pueden ser de metal o cerámica y son una opción popular para corregir problemas de alineación dental.",

          "2.- Alineadores transparentes: Los alineadores transparentes, como Invisalign, son una opción popular para corregir los dientes torcidos o apiñados en los niños. Estos alineadores son removibles y se cambian periódicamente para aplicar una presión gradual y mover los dientes a su posición correcta.",

          "3.- Expansión dental: La expansión dental es un proceso que implica la colocación de un dispositivo en la boca del niño para expandir el arco dental y crear más espacio para los dientes. Este proceso puede ser útil para corregir los dientes apiñados y mejorar la mordida.",

          "4.- Extracción dental: En algunos casos, puede ser necesario extraer uno o más dientes para corregir problemas de alineación dental.",

          "En resumen, los brackets, los alineadores transparentes, la expansión dental y la extracción dental son opciones comunes de tratamiento para corregir los dientes torcidos o apiñados en los niños. La elección del tratamiento adecuado dependerá de la gravedad del problema de alineación dental y de las necesidades y preferencias del niño y de sus padres.",
        ],
      },
    ],
  },
  {
    title: "ORTODONCIA",
    breadcrumb: "Ortodoncia",
    topic: "ortodoncia",
    shortDescriptionSEO:
      "Encuentra respuestas a tus preguntas más frecuentes sobre ortodoncia en nuestra página de preguntas frecuentes. Explora nuestra recopilación de información detallada y concisa sobre el tratamiento ortodóntico. Nuestro equipo de ortodoncistas ha preparado respuestas basadas en su experiencia y conocimientos especializados en el campo. Descubre qué es la ortodoncia, los diferentes tipos de aparatos utilizados y los beneficios de corregir problemas dentales y de alineación. Obtén información sobre el proceso de tratamiento ortodóntico, la duración, el cuidado de los aparatos y los resultados esperados. Utiliza nuestras preguntas frecuentes como una valiosa fuente de información para entender mejor la ortodoncia y tomar decisiones informadas sobre tu salud bucal y estética dental. Estamos comprometidos en brindarte el conocimiento necesario para lograr una sonrisa alineada y saludable.",
    questions: [
      {
        question: "¿Cuánto tiempo durará mi tratamiento de ortodoncia?",
        answers: [
          "La duración del tratamiento de ortodoncia puede variar dependiendo de varios factores, como la gravedad del problema dental, la edad del paciente, el tipo de aparato de ortodoncia utilizado y la cooperación del paciente en seguir las recomendaciones de su ortodoncista.",

          "En general, el tratamiento de ortodoncia puede durar de 1 a 3 años. Durante este tiempo, el ortodoncista trabajará para mover los dientes a su posición correcta mediante el uso de aparatos, como brackets o alineadores transparentes. Es importante que el paciente asista a sus citas de seguimiento programadas regularmente para garantizar el éxito del tratamiento.",

          "Es posible que los pacientes sientan alguna incomodidad o dolor después de la colocación de los aparatos de ortodoncia o después de los ajustes. Sin embargo, la mayoría de los pacientes se acostumbran rápidamente y el dolor disminuye con el tiempo.",

          "Es importante recordar que cada paciente es único y que su tratamiento de ortodoncia será personalizado según sus necesidades y objetivos específicos. Es recomendable hablar con su ortodoncista para discutir su caso en particular y tener una idea más precisa del tiempo de duración de su tratamiento de ortodoncia.",
        ],
      },
      {
        question: "¿Es doloroso el tratamiento de ortodoncia?",
        answers: [
          "Es normal que los pacientes se pregunten si el tratamiento de ortodoncia es doloroso. La respuesta es que puede haber algunas molestias, pero no necesariamente dolor intenso.",

          "Durante el tratamiento de ortodoncia, los pacientes pueden experimentar una pequeña incomodidad o dolor después de la colocación de los brackets o alineadores transparentes, o después de los ajustes de los aparatos. Sin embargo, la mayoría de los pacientes se acostumbran rápidamente y el dolor disminuye con el tiempo. Además, existen algunos tratamientos que causan menos dolor que otros, como los alineadores transparentes.",

          "Los pacientes pueden tomar medidas para reducir la incomodidad, como usar cera de ortodoncia para proteger las mejillas y los labios de las áreas de fricción, tomar analgésicos de venta libre, como el ibuprofeno, y evitar alimentos duros o pegajosos que puedan dañar los aparatos.",

          "Es importante recordar que el dolor o la incomodidad son temporales y que el resultado final de un tratamiento de ortodoncia exitoso puede cambiar significativamente la vida de una persona. Es recomendable hablar con su ortodoncista para discutir cualquier preocupación que tenga sobre el dolor durante el tratamiento de ortodoncia.",
        ],
      },
      {
        question:
          "¿Qué alimentos debo evitar durante mi tratamiento de ortodoncia?",
        answers: [
          "Durante el tratamiento de ortodoncia, es importante tener en cuenta qué alimentos se deben evitar para proteger los aparatos y garantizar que el tratamiento sea efectivo. Algunos alimentos que se deben evitar incluyen:",

          "1.- Alimentos duros: como caramelos, nueces, hielo, palomitas de maíz y alimentos crujientes, como manzanas y zanahorias crudas.",

          "2.- Alimentos pegajosos: como chicles, caramelos masticables y dulces pegajosos, que pueden dañar los brackets y los alambres.",

          "3.- Alimentos azucarados: que pueden aumentar el riesgo de caries dentales y desmineralización del esmalte dental.",

          "4.- Bebidas carbonatadas: como refrescos y bebidas energéticas, que contienen ácido y azúcar, y pueden erosionar el esmalte dental.",

          "5.- Alimentos difíciles de morder: como panes y pizza crujientes, que pueden despegar los brackets y hacer que el tratamiento dure más tiempo.",

          "Es recomendable optar por alimentos suaves y fáciles de masticar, como verduras al vapor, frutas suaves, proteínas magras y productos lácteos. Además, los pacientes deben cepillarse los dientes después de cada comida para mantener una buena higiene oral y evitar la acumulación de placa y bacterias.",
        ],
      },
      {
        question:
          "¿Puedo practicar deportes durante el tratamiento de ortodoncia?",
        answers: [
          "Sí, es posible practicar deportes durante el tratamiento de ortodoncia, pero es importante tomar medidas de precaución para evitar lesiones en los aparatos y en la boca.",

          "Los pacientes que practican deportes de contacto o deportes que involucran un alto riesgo de lesiones orales, como el baloncesto, el fútbol, el hockey y el boxeo, deben usar protectores bucales personalizados para evitar lesiones en los dientes y los aparatos de ortodoncia. Los protectores bucales personalizados son diseñados específicamente para adaptarse a los aparatos de ortodoncia y proporcionan una protección adicional contra golpes y caídas.",

          "Además, los pacientes deben tomar medidas para evitar lesiones en los labios y las mejillas, como usar cera de ortodoncia para suavizar los bordes afilados de los aparatos y evitar comer alimentos duros o pegajosos antes de los juegos o prácticas.",

          "En algunos casos, es posible que se requiera la eliminación temporal de los aparatos de ortodoncia durante los deportes de contacto o de alto impacto. Sin embargo, esto debe ser discutido previamente con el ortodoncista.",

          "En general, practicar deportes durante el tratamiento de ortodoncia es posible, pero se deben tomar medidas de precaución para evitar lesiones en la boca y proteger los aparatos de ortodoncia.",
        ],
      },
      {
        question: "¿Cómo debo cuidar mis dientes y mis aparatos de ortodoncia?",
        answers: [
          "Durante el tratamiento de ortodoncia, es importante cuidar tanto los dientes como los aparatos para garantizar que el tratamiento sea efectivo y que se logre una sonrisa saludable y hermosa. Aquí hay algunos consejos sobre cómo cuidar los dientes y los aparatos de ortodoncia:",

          "1.- Cepillado y uso del hilo dental: Los pacientes deben cepillarse los dientes después de cada comida y antes de acostarse, utilizando un cepillo de dientes suave y pasta de dientes con flúor. Además, deben usar hilo dental diariamente para eliminar los restos de alimentos que quedan atrapados entre los dientes y los aparatos.",

          "2.- Evitar alimentos pegajosos y duros: Los pacientes deben evitar comer alimentos pegajosos o duros que puedan dañar los aparatos, como caramelos, nueces, palomitas de maíz y alimentos crujientes.",

          "3.- Evitar morder objetos: Los pacientes deben evitar morder objetos como lápices, bolígrafos, y las uñas, ya que pueden dañar los aparatos.",

          "4.- Cera de ortodoncia: Si los aparatos causan irritación en los labios y las mejillas, los pacientes pueden utilizar cera de ortodoncia para suavizar los bordes afilados de los aparatos.",

          "5.- Visitas regulares al ortodoncista: Es importante que los pacientes asistan a sus citas regulares con el ortodoncista para ajustar los aparatos y monitorear el progreso del tratamiento.",

          "Al cuidar adecuadamente los dientes y los aparatos de ortodoncia, los pacientes pueden asegurarse de que su tratamiento sea efectivo y que su sonrisa sea hermosa y saludable.",
        ],
      },
      {
        question: "¿La ortodoncia es solo para niños?",
        answers: [
          "No, la ortodoncia no es solo para niños. De hecho, cada vez son más los adultos que optan por recibir tratamiento ortodóntico para mejorar la apariencia y la salud de su sonrisa. La ortodoncia se puede aplicar a cualquier persona que desee corregir la posición de sus dientes y mandíbula para mejorar su función y su apariencia.",

          "A diferencia de los niños, los adultos pueden requerir tratamientos de ortodoncia más complejos debido a que los dientes y las mandíbulas ya han completado su desarrollo. A menudo, los adultos también pueden requerir tratamientos adicionales, como implantes dentales o cirugía ortognática, para lograr los resultados deseados.",

          "Los adultos que se someten a tratamiento de ortodoncia tienen varias opciones de aparatos, incluyendo brackets tradicionales, brackets transparentes, alineadores transparentes y aparatos linguales. El ortodoncista elegirá la mejor opción de tratamiento según las necesidades individuales de cada paciente.",

          "En resumen, la ortodoncia no es solo para niños, sino que es una opción para cualquier persona que busque mejorar la apariencia y la salud de su sonrisa, sin importar su edad.",
        ],
      },
    ],
  },
  {
    title: "PERIODONTITIS",
    breadcrumb: "Periodontitis",
    topic: "periodontitis",
    shortDescriptionSEO:
      "Encuentra respuestas a tus preguntas más frecuentes sobre la periodontitis en nuestra página de preguntas frecuentes. Explora nuestra recopilación de información detallada y concisa sobre esta enfermedad periodontal. Nuestro equipo de especialistas en periodoncia ha preparado respuestas basadas en su experiencia y conocimientos especializados en el campo. Descubre qué es la periodontitis, sus causas, síntomas y factores de riesgo. Obtén información sobre los tratamientos disponibles, las técnicas de prevención y los cuidados dentales recomendados para controlar la enfermedad. Utiliza nuestras preguntas frecuentes como una valiosa fuente de información para entender mejor la periodontitis y tomar medidas para mantener una salud bucal óptima. Estamos comprometidos en brindarte el conocimiento necesario para combatir la periodontitis y preservar la salud de tus encías y dientes.",
    questions: [
      {
        question: "¿Qué es la periodontitis?",
        answers: [
          "La periodontitis es una enfermedad periodontal que afecta a los tejidos que rodean y sostienen los dientes, incluyendo las encías, el hueso alveolar y el ligamento periodontal. Se produce cuando las bacterias presentes en la placa dental se acumulan en los dientes y las encías, causando inflamación y daño a los tejidos periodontales.",

          "Los síntomas de la periodontitis pueden incluir encías inflamadas, sensibilidad dental, mal aliento persistente, sangrado de las encías al cepillarse o usar hilo dental y separación de los dientes. Si no se trata, la periodontitis puede provocar la pérdida de dientes y la diseminación de las bacterias dañinas a otras partes del cuerpo.",

          "El tratamiento de la periodontitis puede incluir una limpieza dental profesional, raspado y alisado radicular, terapia antimicrobiana y, en casos graves, cirugía periodontal. Para prevenir la periodontitis, es importante mantener una buena higiene bucal, como cepillarse y usar hilo dental regularmente, así como visitas regulares al dentista para una limpieza dental profesional y una evaluación de la salud de las encías.",
        ],
      },
      {
        question: "¿Cuáles son los síntomas de la periodontitis?",
        answers: [
          "La periodontitis es una enfermedad periodontal que puede causar una variedad de síntomas desagradables y, si no se trata, puede llevar a la pérdida de dientes y otros problemas de salud. Los síntomas comunes de la periodontitis incluyen:",

          "1.- Encías inflamadas y enrojecidas: Las encías pueden parecer hinchadas, enrojecidas y sensibles al tacto.",

          "2.- Sangrado de las encías: Las encías pueden sangrar durante el cepillado, el uso del hilo dental o incluso espontáneamente.",

          "3.- Mal aliento persistente: La periodontitis puede causar un mal aliento persistente que no desaparece incluso después de cepillarse los dientes.",

          "4.- Sensibilidad dental: La periodontitis puede hacer que los dientes sean sensibles al calor, al frío o al contacto.",

          "5.- Separación de los dientes: Los dientes pueden separarse debido a la pérdida del tejido que los mantiene en su lugar.",

          "6.- Pus alrededor de los dientes y las encías: En casos avanzados, la periodontitis puede causar la acumulación de pus alrededor de los dientes y las encías.",

          "Si experimenta alguno de estos síntomas, es importante que consulte a su dentista lo antes posible para recibir un tratamiento adecuado y prevenir daños adicionales en los dientes y las encías.",
        ],
      },
      {
        question: "¿Cómo se diagnostica la periodontitis?",
        answers: [
          "El diagnóstico de la periodontitis se basa en una evaluación detallada de la salud de las encías y los dientes, así como en una revisión de la historia clínica y dental del paciente. Los signos que indican la presencia de periodontitis incluyen encías inflamadas, sangrado de las encías, separación de los dientes, mal aliento y sensibilidad dental.",

          "Durante un examen dental, el dentista puede medir la profundidad de las bolsas periodontales, que son espacios entre los dientes y las encías donde se acumula la placa y el sarro. Un examen de radiografías también puede ser útil para determinar la gravedad de la periodontitis y la cantidad de hueso perdido alrededor de los dientes.",

          "El dentista también puede usar un instrumento llamado sonda periodontal para medir la profundidad de las bolsas periodontales. Si la profundidad de las bolsas es mayor de lo normal, esto puede ser una señal de periodontitis. Además, se pueden realizar pruebas para determinar la cantidad de bacterias en la boca y la respuesta del cuerpo a la inflamación.",

          "En general, es importante programar exámenes dentales regulares y estar atento a cualquier signo de problemas dentales o periodontales, para poder tratarlos lo antes posible y prevenir complicaciones más graves.",
        ],
      },
      {
        question: "¿Cómo se trata la periodontitis?",
        answers: [
          "El tratamiento de la periodontitis depende de la gravedad de la enfermedad y puede incluir diferentes métodos de limpieza y eliminación de la placa y el sarro acumulados en las bolsas periodontales.",

          "En casos leves, el tratamiento puede consistir en una limpieza dental profunda, conocida como curetaje y raspado radicular, en la que se limpian las superficies dentales y se eliminan las bacterias acumuladas. También se pueden recetar antibióticos para tratar la infección.",

          "En casos más avanzados, puede ser necesario realizar una cirugía periodontal para reducir la profundidad de las bolsas periodontales y restaurar el tejido dañado. Este procedimiento puede incluir injertos de tejido blando o duro para regenerar el hueso y el tejido de las encías perdido.",

          "Es importante que los pacientes con periodontitis sigan una buena higiene oral en casa, incluyendo el cepillado y el uso de hilo dental de forma regular, y se sometan a revisiones dentales regulares para controlar la progresión de la enfermedad.",

          "En resumen, la periodontitis es una enfermedad dental grave que requiere tratamiento profesional para evitar complicaciones a largo plazo. Si experimentas síntomas de periodontitis, como inflamación de las encías o sangrado, debes consultar a tu dentista para obtener un diagnóstico y tratamiento adecuados.",
        ],
      },
      {
        question: "¿Qué sucede si no se trata la periodontitis?",
        answers: [
          "Si no se trata, la periodontitis puede provocar una serie de complicaciones dentales y de salud general. A medida que la enfermedad avanza, puede causar daño irreversible en los tejidos y huesos que soportan los dientes, lo que puede provocar la pérdida de los mismos.",

          "Además, la periodontitis puede tener un impacto negativo en la salud general del cuerpo. Se ha demostrado que las bacterias presentes en la placa dental pueden ingresar al torrente sanguíneo y provocar inflamación en otras partes del cuerpo, lo que aumenta el riesgo de enfermedades cardiovasculares, diabetes y complicaciones durante el embarazo.",

          "La periodontitis también puede causar problemas en la salud bucal como halitosis (mal aliento), abscesos dentales y caries.",

          "Por lo tanto, es importante tratar la periodontitis lo antes posible para prevenir complicaciones graves. La detección temprana y el tratamiento adecuado pueden ayudar a detener la progresión de la enfermedad y a prevenir la pérdida de dientes y otros problemas de salud.",
        ],
      },
      {
        question: "¿Cómo puedo prevenir la periodontitis?",
        answers: [
          "La periodontitis es una enfermedad periodontal grave que puede provocar la pérdida de dientes y otras complicaciones de salud general. Sin embargo, hay una serie de medidas preventivas que pueden ayudar a reducir el riesgo de desarrollar periodontitis. A continuación, se presentan algunos consejos para prevenir la periodontitis:",

          "1.- Cepillado y uso de hilo dental: Cepillarse los dientes al menos dos veces al día y usar hilo dental diariamente es una parte importante de la higiene oral y ayuda a prevenir la acumulación de placa dental, una de las principales causas de la periodontitis.",

          "2.- Visitar regularmente al dentista: Las visitas regulares al dentista para una limpieza dental profesional y una evaluación de la salud bucal son fundamentales para la prevención de la periodontitis.",

          "3.- Evitar el tabaco: El tabaco es un factor de riesgo importante para la periodontitis y puede aumentar el riesgo de desarrollar la enfermedad.",

          "4.- Mantener una dieta equilibrada: Una dieta rica en nutrientes es importante para la salud general del cuerpo, incluyendo la salud bucal.",

          "5.- Controlar el estrés: El estrés crónico puede debilitar el sistema inmunológico, lo que puede aumentar el riesgo de desarrollar enfermedades, incluyendo la periodontitis.",

          "6.- Tratamiento temprano: La detección temprana y el tratamiento adecuado pueden prevenir la progresión de la periodontitis y evitar complicaciones graves.",

          "Siguiendo estas medidas preventivas, se puede reducir el riesgo de desarrollar periodontitis y mantener una buena salud bucal.",
        ],
      },
    ],
  },
  {
    title: "PRÓTESIS DENTALES",
    breadcrumb: "Prótesis dentales",
    topic: "protesis",
    shortDescriptionSEO:
      "Encuentra respuestas a tus preguntas más frecuentes sobre las prótesis dentales en nuestra página de preguntas frecuentes. Explora nuestra recopilación de información detallada y concisa sobre este tratamiento odontológico. Nuestro equipo de especialistas en prótesis dentales ha preparado respuestas basadas en su experiencia y conocimientos especializados en el campo. Descubre qué son las prótesis dentales, los diferentes tipos de prótesis disponibles y los beneficios que ofrecen. Obtén información sobre el proceso de colocación de las prótesis, los cuidados necesarios y las recomendaciones para mantener una buena salud bucal con prótesis. Utiliza nuestras preguntas frecuentes como una valiosa fuente de información para entender mejor las prótesis dentales y tomar decisiones informadas sobre tu salud y bienestar dental. Estamos comprometidos en brindarte el conocimiento necesario para ayudarte a recuperar la funcionalidad y estética de tu sonrisa con prótesis dentales de calidad.",
    questions: [
      {
        question: "¿Qué son las prótesis dentales y para qué se utilizan?",
        answers: [
          "Las prótesis dentales son dispositivos artificiales diseñados para reemplazar dientes faltantes o dañados y restaurar la función y la estética de la boca. Están hechas de materiales seguros y duraderos como la cerámica, el metal y la resina acrílica y se pueden personalizar para adaptarse a la forma y el tamaño de la boca del paciente.",

          "Las prótesis dentales se utilizan para una variedad de razones, como reemplazar uno o varios dientes perdidos, mejorar la apariencia de la sonrisa, mejorar la masticación y la capacidad para hablar y prevenir la aparición de problemas dentales adicionales, como la migración de los dientes.",

          "Hay varios tipos de prótesis dentales disponibles, que incluyen prótesis dentales parciales, prótesis dentales completas, prótesis dentales fijas y prótesis dentales removibles. Cada tipo de prótesis dental tiene sus propias ventajas y desventajas, por lo que es importante trabajar con un dentista experimentado para determinar cuál es la mejor opción para las necesidades individuales del paciente.",
        ],
      },
      {
        question:
          "¿Cuánto tiempo duran las prótesis dentales y cuándo necesitan ser reemplazadas?",
        answers: [
          "Las prótesis dentales son dispositivos diseñados para reemplazar los dientes faltantes y restaurar la función y apariencia de la boca. El tiempo de duración de las prótesis dentales varía dependiendo del tipo de prótesis y los cuidados que se les brinden.",

          "Las prótesis dentales removibles suelen durar de cinco a ocho años, siempre y cuando se realice una limpieza adecuada y se evite comer alimentos duros o pegajosos que puedan dañarlas. Las prótesis dentales fijas, como los puentes y las coronas, suelen durar entre 10 y 15 años con los cuidados adecuados. Sin embargo, algunas prótesis dentales pueden durar mucho más tiempo.",

          "Es importante llevar a cabo revisiones regulares con el dentista para evaluar la condición de las prótesis dentales y determinar si es necesario reemplazarlas. Algunas señales de que puede ser necesario reemplazar una prótesis dental incluyen dolor, incomodidad, movilidad, fracturas o desgaste excesivo.",

          "En general, el tiempo de duración de las prótesis dentales depende de varios factores, incluyendo el tipo de prótesis, los cuidados y la salud bucal en general del paciente. Es importante mantener una buena higiene oral y acudir a las revisiones regulares con el dentista para prolongar la vida útil de las prótesis dentales.",
        ],
      },
      {
        question:
          "¿Qué tipos de prótesis dentales existen y cuál es la adecuada para mí?",
        answers: [
          "Existen diferentes tipos de prótesis dentales que pueden ser utilizadas para reemplazar dientes perdidos. La elección de la prótesis adecuada depende de varios factores, como el número de dientes que se deben reemplazar, la salud bucal general del paciente y su preferencia estética.",

          "1.- Prótesis fijas: Las prótesis fijas se adhieren permanentemente a los dientes adyacentes. Este tipo de prótesis incluye puentes dentales y coronas dentales.",

          "2.- Prótesis removibles: Las prótesis removibles pueden ser retiradas de la boca para la limpieza y el mantenimiento. Este tipo de prótesis incluye dentaduras parciales y completas.",

          "3.- Prótesis implantosoportadas: Las prótesis implantosoportadas se sujetan a los implantes dentales, que son pequeños tornillos de titanio insertados en el hueso de la mandíbula. Este tipo de prótesis es la más duradera y natural en cuanto a apariencia y función.",

          "Es importante hablar con un dentista para determinar qué tipo de prótesis dental es la más adecuada para cada caso individual. La elección correcta de la prótesis dental puede mejorar la calidad de vida y restaurar la sonrisa del paciente.",
        ],
      },
      {
        question:
          "¿Cómo se colocan las prótesis dentales y es un proceso doloroso?",
        answers: [
          "La colocación de prótesis dentales es un proceso que se lleva a cabo para reemplazar dientes faltantes o dañados. En la mayoría de los casos, el procedimiento es relativamente indoloro y se lleva a cabo en varias citas con el dentista.",

          "El proceso de colocación de prótesis dentales implica la toma de impresiones de la boca y los dientes, para crear una prótesis personalizada que se adapte perfectamente a su boca y le brinde una apariencia natural. En algunos casos, es posible que sea necesario extraer un diente o preparar los dientes adyacentes antes de colocar la prótesis.",

          "La colocación de la prótesis en sí misma no suele ser dolorosa, aunque puede sentir algo de incomodidad o sensibilidad mientras se adapta a la nueva prótesis en su boca. En algunos casos, es posible que se necesite algún tiempo para ajustar la prótesis y lograr una sensación cómoda y natural en la boca.",

          "Es importante tener en cuenta que, como con cualquier procedimiento dental, puede haber algunas molestias menores después de la colocación de la prótesis, como inflamación o dolor leve. Su dentista le dará instrucciones sobre cómo cuidar adecuadamente su prótesis y mantener una buena higiene dental para evitar problemas.",
        ],
      },
      {
        question:
          "¿Qué cuidados necesitan las prótesis dentales y cómo se deben limpiar?",
        answers: [
          "Las prótesis dentales son una solución común para reemplazar dientes perdidos o dañados. Para mantenerlas en buen estado, es importante seguir algunos cuidados básicos y una buena higiene oral. En este sentido, es fundamental retirar la prótesis dental después de comer para limpiarla adecuadamente y cepillarla con un cepillo de cerdas suaves y agua. También se pueden utilizar productos de limpieza específicos para prótesis dentales.",

          "Asimismo, es importante evitar morder alimentos duros o pegajosos que puedan dañar la prótesis dental, así como abstenerse de fumar, ya que esto puede causar manchas y deteriorar el material. Es recomendable llevar la prótesis dental a un dentista para una revisión regular, de esta forma podrá detectar y reparar cualquier problema antes de que se convierta en un problema mayor.",

          "Si se presentan síntomas como dolor, molestia o dificultad para masticar, es importante acudir a un dentista lo antes posible para evitar complicaciones. Con los cuidados adecuados y una buena higiene oral, las prótesis dentales pueden durar muchos años y mantener su funcionalidad y estética.",
        ],
      },
      {
        question:
          "¿Las prótesis dentales afectan la capacidad para hablar o comer?",
        answers: [
          "Las prótesis dentales son una excelente opción para reemplazar los dientes faltantes y restaurar la apariencia y función de la dentadura. Muchas personas pueden preocuparse por cómo afectará la prótesis a su capacidad para hablar y comer. La buena noticia es que con el cuidado y la adaptación adecuados, las prótesis dentales no deben afectar significativamente su capacidad para hablar o comer.",

          "Es posible que al principio se necesite algo de tiempo para acostumbrarse a las prótesis dentales. La dentadura postiza puede sentirse un poco incómoda en la boca, y puede ser necesario adaptar la forma de hablar y comer. Sin embargo, con el tiempo, la mayoría de las personas se adaptan a su prótesis y pueden hablar y comer con normalidad.",

          "Es importante seguir las instrucciones del dentista para el cuidado de las prótesis dentales, como limpiarlas regularmente y evitar alimentos pegajosos o duros que puedan dañarlas. Además, se deben visitar regularmente al dentista para asegurarse de que la prótesis esté en buenas condiciones y realizar ajustes necesarios.",

          "En resumen, con los cuidados adecuados y la adaptación necesaria, las prótesis dentales no deberían afectar significativamente la capacidad para hablar o comer.",
        ],
      },
    ],
  },
  {
    title: "SEDACIONES CONSCIENTES",
    breadcrumb: "Sedaciones conscientes",
    topic: "sedaciones-conscientes",
    shortDescriptionSEO:
      "Encuentra respuestas a tus preguntas más frecuentes sobre las sedaciones conscientes en nuestra página de preguntas frecuentes. Explora nuestra recopilación de información detallada y concisa sobre este procedimiento odontológico. Nuestro equipo de especialistas en sedación consciente ha preparado respuestas basadas en su experiencia y conocimientos especializados en el campo. Descubre qué es la sedación consciente, cómo se realiza y qué beneficios ofrece. Obtén información sobre los diferentes tipos de sedación consciente disponibles, así como las precauciones y cuidados necesarios. Utiliza nuestras preguntas frecuentes como una valiosa fuente de información para entender mejor las sedaciones conscientes y tomar decisiones informadas sobre tu comodidad y bienestar durante los tratamientos odontológicos. Estamos comprometidos en brindarte el conocimiento necesario para ayudarte a superar la ansiedad y el miedo al dentista, proporcionándote una experiencia dental relajante y libre de estrés.",
    questions: [
      {
        question: "¿Qué es la sedación consciente y en qué consiste?",
        answers: [
          "La sedación consciente es un procedimiento dental que se utiliza para ayudar a los pacientes a sentirse más cómodos y relajados durante los tratamientos. Consiste en administrar una combinación de medicamentos para sedar suavemente al paciente, lo que permite que el dentista realice el procedimiento con mayor facilidad y menos estrés para el paciente.",

          "La sedación consciente es especialmente útil para pacientes que experimentan ansiedad o miedo al dentista, aquellos con dificultades para sentarse durante largos períodos de tiempo, o para aquellos que requieren un tratamiento extenso o complejo. El paciente permanece consciente durante todo el procedimiento, pero experimenta un estado de relajación y tranquilidad.",

          "La sedación consciente se administra a través de una máscara facial o una pequeña aguja en la vena del brazo, y el nivel de sedación se puede ajustar según las necesidades del paciente. Una vez que se completa el procedimiento dental, el paciente se recupera rápidamente y puede regresar a casa el mismo día.",

          "La sedación consciente es una opción segura y efectiva para muchos pacientes dentales, pero debe ser administrada por un profesional capacitado en sedación consciente para minimizar los riesgos y asegurar la seguridad del paciente.",
        ],
      },
      {
        question: "¿Qué tipos de sedación consciente existen?",
        answers: [
          "La sedación consciente es un método utilizado en odontología para ayudar a los pacientes a sentirse relajados y cómodos durante los procedimientos dentales. Existen diferentes tipos de sedación consciente que pueden ser utilizados dependiendo de las necesidades del paciente y la complejidad del tratamiento. La sedación inhalada es uno de los métodos más comunes y utiliza una mezcla de óxido nitroso y oxígeno para crear una sensación de relajación y reducir la ansiedad. La sedación oral implica la administración de un sedante por vía oral antes del procedimiento. La sedación intravenosa implica la administración de un sedante a través de una aguja en la vena del paciente y es la forma más profunda de sedación consciente.",

          "También existe la sedación consciente basada en el uso de dispositivos como la musicoterapia, la aromaterapia y la terapia de relajación. Estas opciones pueden no ser tan profundas como las anteriores, pero pueden ser útiles para pacientes con ansiedad leve o para procedimientos dentales simples. En todos los casos, la sedación consciente es segura y efectiva cuando es administrada por un profesional capacitado en un ambiente controlado.",
        ],
      },
      {
        question:
          "¿Cuáles son los beneficios de la sedación consciente en procedimientos dentales?",
        answers: [
          "La sedación consciente es una técnica de anestesia que se utiliza en procedimientos dentales para ayudar a los pacientes a sentirse más cómodos y relajados durante el tratamiento. Esta técnica permite al paciente estar consciente y capaz de responder a las instrucciones del dentista, pero al mismo tiempo, reducir la ansiedad y el dolor que puede sentir durante el procedimiento.",

          "Entre los beneficios de la sedación consciente se encuentra la disminución del miedo y la ansiedad asociados con los procedimientos dentales. También puede reducir el dolor y el malestar, especialmente en tratamientos más invasivos. La sedación consciente también puede ayudar a que los pacientes se sientan más cómodos durante procedimientos largos o complejos, lo que puede aumentar la eficacia del tratamiento.",

          "Además, la sedación consciente permite a los dentistas realizar tratamientos más complejos en menos tiempo y con menos estrés para el paciente, lo que puede llevar a mejores resultados. La sedación consciente es segura y se puede ajustar según las necesidades individuales de cada paciente. Sin embargo, es importante que la sedación sea administrada por un profesional capacitado y con experiencia en su uso.",
        ],
      },
      {
        question:
          "¿Es segura la sedación consciente en procedimientos dentales?",
        answers: [
          "Es común que los pacientes se sientan nerviosos o incómodos durante las visitas al dentista, por lo que la sedación consciente puede ser una buena opción para reducir el estrés y garantizar una experiencia más cómoda.",

          "En cuanto a la seguridad, la sedación consciente es considerada una técnica segura y efectiva cuando es administrada por un profesional capacitado y experimentado. Además, el paciente está monitoreado constantemente durante el procedimiento para garantizar su bienestar y seguridad.",

          "Sin embargo, es importante informar a su dentista acerca de cualquier condición médica preexistente o medicamentos que se esté tomando antes del procedimiento, para garantizar que la sedación consciente sea adecuada y segura.",

          "En general, la sedación consciente puede ser una excelente opción para aquellos pacientes que experimentan ansiedad o miedo durante los procedimientos dentales, pero siempre es importante hablar con su dentista para determinar la mejor opción para su caso particular.",
        ],
      },
      {
        question:
          "¿Qué precauciones se deben tomar antes y después de una sedación consciente?",
        answers: [
          "La sedación consciente es un procedimiento seguro y efectivo que se utiliza comúnmente en odontología para ayudar a los pacientes a sentirse más relajados y cómodos durante los procedimientos dentales. Sin embargo, es importante tomar ciertas precauciones antes y después del procedimiento para garantizar la seguridad del paciente.",

          "Antes del procedimiento, el dentista o anestesiólogo debe realizar una evaluación médica completa para asegurarse de que el paciente es un buen candidato para la sedación consciente. También se deben seguir las instrucciones del dentista con respecto a la ingesta de alimentos y líquidos antes del procedimiento, que generalmente requiere que el paciente ayune durante varias horas.",

          "Después del procedimiento, el paciente debe ser supervisado de cerca para garantizar que se recupere adecuadamente de la sedación consciente. Se recomienda que alguien acompañe al paciente a casa después del procedimiento, ya que puede sentirse somnoliento o desorientado. Además, se deben seguir las instrucciones del dentista con respecto a la actividad física y la ingesta de alimentos y líquidos después del procedimiento.",

          "Al tomar estas precauciones, se puede garantizar una experiencia segura y efectiva de sedación consciente para los pacientes durante los procedimientos dentales. Es importante seguir las instrucciones del dentista y comunicar cualquier inquietud o pregunta antes y después del procedimiento.",
        ],
      },
      {
        question:
          "¿Quién puede administrar la sedación consciente en un procedimiento dental?",
        answers: [
          "En general, la administración de la sedación consciente debe ser llevada a cabo por un profesional médico altamente capacitado y con licencia. En algunos casos, un anestesiólogo o un especialista en sedación consciente puede administrar la sedación. Sin embargo, en otros casos, el dentista puede estar capacitado y autorizado para administrar la sedación consciente.",

          "Es importante verificar que el profesional encargado de la administración de la sedación esté debidamente capacitado y acreditado en la técnica de sedación consciente. También se debe asegurar que se utilice equipo de monitoreo adecuado para supervisar los signos vitales del paciente durante todo el procedimiento.",

          "Es importante seguir las instrucciones del dentista para el cuidado de las prótesis dentales, como limpiarlas regularmente y evitar alimentos pegajosos o duros que puedan dañarlas. Además, se deben visitar regularmente al dentista para asegurarse de que la prótesis esté en buenas condiciones y realizar ajustes necesarios.",

          "Antes de programar cualquier procedimiento dental que involucre sedación consciente, es importante preguntar al dentista acerca de su capacitación y experiencia en la administración de la sedación consciente y asegurarse de seguir todas las instrucciones de cuidado posteriores al procedimiento.",
        ],
      },
    ],
  },
];

export const discountsCards = [
  {
    titleDiscountSection: "Férulas de descarga y Tac 3D",
    discounts: [
      {
        id: "digitalOrthopantomography",
        img: "ortopantomografia-digital.webp",
        title: "Tac 3D una sola arcada",
        url: "tac-3d-una-arcada",
        buyUrl: "https://buy.stripe.com/8wMdU0fYW1cXdNu4gh",
        price: 20.0,
        description: [
          "Te presentamos una oportunidad única para obtener una imagen completa y detallada de tus estructuras dentales y maxilares con nuestra oferta de Ortopantomografía Digital.",

          "Este estudio radiográfico avanzado es esencial para una evaluación exhaustiva de tu salud bucal y dental.",
        ],
      },
      {
        id: "full3DCTBothArches",
        img: "tac-3d-ambas-arcadas.webp",
        title: "TAC 3D completo",
        url: "tac-3d-completo-ambas-arcadas",
        buyUrl: "https://buy.stripe.com/5kA5nu1429Jt8ta002",
        price: 37.0,
        description: [
          "Este estudio de imágenes avanzado proporciona una visión tridimensional completa de tu boca, permitiendo evaluar con precisión problemas y planificar tratamientos.",

          "Nuestra oferta combina tecnología de vanguardia con una tarifa asequible para un diagnóstico dental completo.",
        ],
      },
      {
        id: "digitizedDischargeSplintWith3ShapeScanner",
        img: "ferula-descarga-michigan.webp",
        title: "Férula de descarga michigan",
        url: "ferula-de-descarga-digitalizada-con-escaner-3shape",
        buyUrl: "https://buy.stripe.com/3cs9DKfYW3l5bFmeUX",
        price: 80.0,
        description: [
          "Un dispositivo dental diseñado para tratar problemas como el bruxismo (rechinar de dientes) y el trastorno de la articulación temporomandibular (ATM).",

          "En esta oferta, te proporcionamos una solución integral para el cuidado de tu salud bucal a través de la Ferula de Descarga Michigan.",
        ],
      },
      // {
      //     id: "digitizedDischargeSplintNobrux",
      //     img: "ferula-descarga-nobrux.webp",
      //     title: "Férula de descarga Nobrux",
      //     url: "ferula-de-descarga-nobrux",
      //     buyUrl: "https://buy.stripe.com/9AQ3fmaECf3NcJqbIU",
      //     price: 150.00,
      //     description: [
      //         "¿Padeces de bruxismo severo? Entonces entiendes la urgencia de una defensa de calidad superior.",

      //         "Concebida explícitamente para los afectados con un desgaste dental extremo, garantizándote una protección insuperable.",
      //     ],
      // }
    ],
  },
  {
    titleDiscountSection: "Limpiezas dentales",
    discounts: [
      {
        id: "dentalCleaningWithUltrasound",
        img: "limpieza-dental-ultrasonido-y-pulidor.webp",
        title: "Limpieza con ultrasonido",
        url: "limpieza-dental-con-ultrasonido",
        buyUrl: "https://buy.stripe.com/fZe17eh302h1fVCdR3",
        price: 20.0,
        description: [
          "Te presentamos una oportunidad excepcional para mantener tu salud bucal en óptimas condiciones con nuestra oferta de Limpieza dental con ultrasonido.",

          "Este tratamiento, recomendado especialmente para aquellos sin complicaciones periodontales.",
        ],
      },
      {
        id: "deepDentalCleaningOrMaintenance",
        img: "limpieza-periodontal.webp",
        title: "Limpieza profunda",
        url: "limpieza-profunda-periodontal",
        buyUrl: "https://buy.stripe.com/4gw17edQO3l510I4gu",
        price: 40.0,
        description: [
          "Diseñada específicamente para aquellos pacientes con gingivitis leve, problemas de inflamación en las encías y sangrado ocasional.",

          "La gingivitis leve, aunque pueda parecer inocua, puede ser la puerta de entrada a complicaciones más serias si no se maneja adecuadamente.",
        ],
      },
      {
        id: "cleaningWithBicarbonateOrAirPolishing",
        img: "Limpieza-con-bicarbonato.webp",
        title: "Limpieza con bicarbonato",
        url: "limpieza-con-bicarbonato-o-aeropulido",
        buyUrl: "https://buy.stripe.com/28o03a2865td5gY5kz",
        price: 50.0,
        description: [
          "Te ofrecemos la solución perfecta para revitalizar y resaltar la belleza de tu sonrisa con nuestra limpieza con bicarbonato o aeropulido.",

          "Con esta técnica avanzada, esas manchas persistentes que a menudo son difíciles de eliminar ya no serán motivo de preocupación.",
        ],
      },
      {
        id: "generalCurettage",
        img: "curetaje-general.webp",
        title: "Curetaje general",
        url: "curetaje-general",
        buyUrl: "https://buy.stripe.com/4gw17e3ca8Fp7p65kr",
        price: 60.0,
        description: [
          "Este procedimiento está especialmente diseñado para aquellos pacientes que enfrentan desafíos como la gingivitis, ya sea en su forma crónica o aguda.",

          "La gingivitis se manifiesta a través de encías inflamadas y un sangrado frecuente, y por ello, precisa una atención especializada y meticulosa.",
        ],
      },
    ],
  },
  {
    titleDiscountSection: "Blanqueamientos",
    discounts: [
      {
        id: "bleachingSyringes",
        img: "jeringuillas-blanqueamiento.webp",
        title: "Jeringuillas de blanqueamiento",
        url: "jeringuillas-de-blanqueamiento",
        buyUrl: "https://buy.stripe.com/3csdU0aEC6xhaBiaEM",
        price: "20€/ud.",
        description: [
          "Intensifica tu resplandor, si ya dispones de tus férulas personalizadas y buscas dar un nuevo impulso o mantener la luminosidad de tu sonrisa.",

          "Estas jeringuillas te ofrecen la solución perfecta para continuar o revitalizar tu tratamiento de blanqueamiento.",
        ],
      },
      {
        id: "outpatientBleaching",
        img: "blanqueamiento-ambulatorio.webp",
        title: "Blanqueamiento ambulatorio",
        url: "blanqueamiento-ambulatorio",
        buyUrl: "https://buy.stripe.com/14k5nu3ca2h19xe28o",
        price: 100.0,
        description: [
          "En lugar de múltiples visitas al dentista, ponemos a tu disposición férulas personalizadas y jeringuillas con el producto blanqueador.",

          "Permitiéndote llevar a cabo el tratamiento en el espacio familiar y cómodo de tu hogar.",
        ],
      },
      {
        id: "photoActivationWhitening",
        img: "blanqueamiento-fotoactivacion.webp",
        title: "Foto activación",
        url: "blanqueamiento-por-foto-activacion",
        buyUrl: "https://buy.stripe.com/9AQ3fmaECf3NcJqbIU",
        price: 150.0,
        description: [
          "Brillo instantáneo, este tratamiento se distingue por su rapidez y eficiencia, otorgándote un tono más claro en un periodo significativamente más corto.",

          "Nuestra oferta combina tecnología de vanguardia con una tarifa asequible para un diagnóstico dental completo.",
        ],
      },
    ],
  },
];

export const discountsContent = [
  {
    id: "dentalCleaningWithUltrasoundPageContent",
    img: "limpieza-dental-ultrasonido-y-pulidor.webp",
    title: "Limpieza dental con ultrasonido",
    url: "limpieza-dental-con-ultrasonido",
    buyUrl: "https://buy.stripe.com/fZe17eh302h1fVCdR3",
    price: 20.0,
    description: [
      "Te presentamos una oportunidad excepcional para mantener tu salud bucal en óptimas condiciones con nuestra oferta de Limpieza dental con ultrasonido y aeropulidor.",

      "Este tratamiento profesional es esencial para mantener tus dientes y encías limpios, frescos y libres de placa y manchas.",

      "Mantener una higiene bucal adecuada es esencial para la salud general y la confianza en ti mismo. Nuestra oferta te brinda acceso a una limpieza dental de calidad a un precio asequible, utilizando técnicas modernas y efectivas para lograr una sonrisa fresca y radiante.",

      "* Una vez realizado el pago se le realizará estudio en consulta y nuestros especialistas determinarán si este es el tratamiento que usted necesita, de no ser así se le cobrará el importe restante o se le devolverá la parte correspondiente.",
    ],
    offerList: [
      {
        listTitle: "Exámen preliminar:",
        listDescription:
          "Antes de comenzar, un dentista evaluará tu salud bucal para asegurarse de que el tratamiento es el adecuado para ti.",
      },
      {
        listTitle: "Limpieza con ultrasonido:",
        listDescription:
          "A través de un dispositivo especializado, se eliminará la placa y el sarro de tus dientes y encías.",
      },
      {
        listTitle: "Finalización:",
        listDescription:
          "Tras la limpieza, recibirás un enjuague bucal para garantizar que no quede ningún residuo.",
        listDescription2:
          "Además, se te brindarán recomendaciones sobre cómo cuidar tu sonrisa y prolongar los efectos de la limpieza. ",
      },
    ],
  },
  {
    id: "deepDentalCleaningOrMaintenancePageContent",
    img: "limpieza-periodontal.webp",
    title: "Limpieza profunda periodontal",
    url: "limpieza-profunda-periodontal",
    buyUrl: "https://buy.stripe.com/4gw17edQO3l510I4gu",
    price: 40.0,
    description: [
      "Diseñada específicamente para aquellos pacientes con gingivitis leve, problemas de inflamación en las encías y sangrado ocasional.",

      "La gingivitis leve, aunque pueda parecer inocua, puede ser la puerta de entrada a complicaciones más serias si no se maneja adecuadamente. Además, la inflamación y el sangrado ocasional de las encías son señales claras de que tus encías requieren atención especializada.",

      "Es vital para quienes presentan síntomas de gingivitis leve y otros problemas periodontales invertir en tratamientos especializados.",

      "* Una vez realizado el pago se le realizará estudio en consulta y nuestros especialistas determinarán si este es el tratamiento que usted necesita, de no ser así se le cobrará el importe restante o se le devolverá la parte correspondiente.",
    ],
    offerList: [
      {
        listTitle: "Tratamiento especializado:",
        listDescription:
          "Esta limpieza está diseñada pensando en las necesidades particulares de aquellos con gingivitis leve y problemas de encías.",
      },
      {
        listTitle: "Atención a la inflamación:",
        listDescription:
          "Se centra en reducir y prevenir la inflamación de las encías, proporcionando alivio y protección.",
      },
      {
        listTitle: "Control del sangrado:",
        listDescription:
          "Al eliminar la placa subgingival, se reduce el sangrado ocasional, promoviendo encías más sanas y fuertes.",
      },
    ],
  },
  {
    id: "cleaningWithBicarbonateOrAirPolishingPageContent",
    img: "Limpieza-con-bicarbonato.webp",
    title: "Limpieza con bicarbonato o aeropulido",
    url: "limpieza-con-bicarbonato-o-aeropulido",
    buyUrl: "https://buy.stripe.com/28o03a2865td5gY5kz",
    price: 50.0,
    description: [
      "El consumo habitual de café, vino, tabaco y otros elementos puede generar manchas en nuestros dientes, opacando la luminosidad natural de nuestra sonrisa.",

      "Con esta técnica avanzada, esas manchas persistentes que a menudo son difíciles de eliminar ya no serán motivo de preocupación.",

      "Gracias al aeropulido, este tratamiento actúa sobre las manchas superficiales, devolviendo a tus dientes ese blanco radiante que tanto anhelas.",

      "La eficacia de este proceso garantiza resultados visibles desde la primera sesión, permitiéndote mostrar una sonrisa fresca y rejuvenecida.",

      "* Una vez realizado el pago se le realizará estudio en consulta y nuestros especialistas determinarán si este es el tratamiento que usted necesita, de no ser así se le cobrará el importe restante o se le devolverá la parte correspondiente.",
    ],
    offerList: null,
    description2: [
      "Nuestro equipo especializado aplicará una mezcla de agua, aire y bicarbonato. Esta combinación actúa directamente sobre las manchas, desintegrándolas y aclarando la superficie dental sin dañar el esmalte.",

      "El tratamiento no solo tiene beneficios estéticos, también contribuye a una mejor salud oral al eliminar partículas y residuos que se acumulan con el tiempo.",

      "Por lo tanto, si deseas que tu sonrisa brille con su máximo esplendor, ¡esta es tu oportunidad! Reserva tu cita y descubre el potencial de una sonrisa verdaderamente radiante.",
    ],
  },
  {
    id: "generalCurettagePageContent",
    img: "curetaje-general.webp",
    title: "Curetaje general",
    url: "curetaje-general",
    buyUrl: "https://buy.stripe.com/4gw17e3ca8Fp7p65kr",
    price: 60.0,
    description: [
      "Este procedimiento está especialmente diseñado para aquellos pacientes que enfrentan desafíos como la gingivitis, ya sea en su forma crónica o aguda.",

      "La gingivitis se manifiesta a través de encías inflamadas y un sangrado frecuente, y por ello, precisa una atención especializada y meticulosa.",

      "Además, es esencial mencionar que, para garantizar el éxito a largo plazo del curetaje y para maximizar sus beneficios, este tratamiento puede necesitar ser complementado con otros procedimientos.",

      "Estos tratamientos adicionales ayudarán a fortalecer y proteger tu salud bucal, asegurando que los resultados obtenidos con el curetaje sean duraderos y eficaces.",

      "* Una vez realizado el pago se le realizará estudio en consulta y nuestros especialistas determinarán si este es el tratamiento que usted necesita, de no ser así se le cobrará el importe restante o se le devolverá la parte correspondiente.",
    ],
    offerList: [
      {
        listTitle: "Atender la inflamación:",
        listDescription:
          "Abordando directamente las áreas afectadas, reducimos la hinchazón y el malestar.",
      },
      {
        listTitle: "Controlar el sangrado:",
        listDescription:
          "Al tratar las zonas que sangran con regularidad, buscamos minimizar el sangrado y reforzar la salud de las encías.",
      },
      {
        listTitle: "Prevenir complicaciones futuras:",
        listDescription:
          "Un tratamiento temprano y adecuado, como el curetaje, puede evitar la progresión de la gingivitis hacia enfermedades periodontales más graves.",
      },
    ],
  },
  {
    id: "bleachingSyringesPageContent",
    img: "jeringuillas-blanqueamiento.webp",
    title: "Jeringuillas de blanqueamiento",
    url: "jeringuillas-de-blanqueamiento",
    buyUrl: "https://buy.stripe.com/3csdU0aEC6xhaBiaEM",
    price: "20€/ud.",
    description: [
      "Intensifica tu resplandor, si ya dispones de tus férulas personalizadas y buscas dar un nuevo impulso o mantener la luminosidad de tu sonrisa.",

      "Estas jeringuillas te ofrecen la solución perfecta para continuar o revitalizar tu tratamiento de blanqueamiento.",

      "Nos apasiona brindarte soluciones que reflejen confianza y belleza en cada sonrisa. Te invitamos a consultar a nuestro equipo de expertos para determinar el tratamiento de blanqueamiento que más te convenga.",

      "¡Anímate y da el paso hacia la sonrisa que siempre has deseado!",
    ],
    offerList: [
      {
        listTitle: "Procedimiento:",
        listDescription:
          "Ideales para aquellos que ya han realizado algún tratamiento previo o simplemente desean un refuerzo ocasional. Las jeringuillas contienen el mismo gel blanqueador que se usa en tratamientos profesionales.",
      },
      {
        listTitle: "Pros:",
        listDescription: [
          {
            subListTitle: "Mantenimiento:",
            subListDescription:
              "Ayudan a mantener y reforzar el blanqueamiento obtenido previamente.",
          },
          {
            subListTitle: "Conveniencia:",
            subListDescription:
              "Facilita retocar el blanqueamiento según lo necesites.",
          },
        ],
      },
      {
        listTitle: "Contras:",
        listDescription:
          "Requiere Férulas, es esencial tener férulas personalizadas para aplicar el producto adecuadamente.",
      },
    ],
  },
  {
    id: "outpatientBleachingPageContent",
    img: "blanqueamiento-ambulatorio.webp",
    title: "Blanqueamiento ambulatorio",
    url: "blanqueamiento-ambulatorio",
    buyUrl: "https://buy.stripe.com/14k5nu3ca2h19xe28o",
    price: 100.0,
    description: [
      "En lugar de múltiples visitas al dentista, ponemos a tu disposición férulas personalizadas y jeringuillas con el producto blanqueador.",

      "Permitiéndote llevar a cabo el tratamiento en el espacio familiar y cómodo de tu hogar.",

      "Este tratamiento es ideal para aquellos que prefieren un enfoque gradual. Comenzaremos tomando impresiones de tus dientes para crear férulas personalizadas.",

      "Posteriormente, te entregaremos estas férulas junto con jeringuillas de blanqueamiento para que puedas administrar el tratamiento en casa.",
    ],
    offerList: [
      {
        listTitle: "Pros:",
        listDescription: [
          {
            subListTitle: "Comodidad:",
            subListDescription:
              "Puedes realizar el tratamiento en la tranquilidad de tu hogar y a tu ritmo.",
          },
          {
            subListTitle: "Flexibilidad:",
            subListDescription:
              "Al ser un proceso gradual, puedes controlar la intensidad y duración según tus preferencias y sensibilidad.",
          },
        ],
      },
      {
        listTitle: "Contras:",
        listDescription: [
          {
            subListTitle: "Compromiso:",
            subListDescription:
              "Requiere una dedicación y constancia para ver resultados notables.",
          },
          {
            subListTitle: "Dieta blanca:",
            subListDescription:
              "Durante el tratamiento, es esencial evitar alimentos y bebidas que manchen los dientes para obtener los resultados esperados.",
          },
        ],
      },
    ],
  },
  {
    id: "photoActivationWhiteningPageContent",
    img: "blanqueamiento-fotoactivacion.webp",
    title: "Blanqueamiento por foto activación",
    url: "blanqueamiento-por-foto-activacion",
    buyUrl: "https://buy.stripe.com/cN2cPWcMK08TcJqeV4",
    price: 150.0,
    description: [
      "Brillo instantáneo, este tratamiento se distingue por su rapidez y eficiencia, otorgándote un tono más claro en un periodo significativamente más corto.",

      "Nuestra oferta combina tecnología de vanguardia con una tarifa asequible para un diagnóstico dental completo.",

      "En este tratamiento, aplicamos un gel blanqueador sobre tus dientes y posteriormente lo activamos con una luz especializada.",

      "Esta combinación acelera el proceso de blanqueamiento, obteniendo resultados notorios en una sola sesión.",
    ],
    offerList: [
      {
        listTitle: "Pros:",
        listDescription: [
          {
            subListTitle: "Resultados rápidos:",
            subListDescription:
              "Ideal para quienes buscan un cambio notable en poco tiempo.",
          },
          {
            subListTitle: "Eficacia:",
            subListDescription:
              "La combinación de gel y luz asegura un blanqueamiento uniforme y profundo.",
          },
        ],
      },
      {
        listTitle: "Contras:",
        listDescription: [
          {
            subListTitle: "Sensibilidad:",
            subListDescription:
              "Puede generar sensibilidad temporal en algunos pacientes.",
          },
          {
            subListTitle: "Duración:",
            subListDescription:
              " Es posible que necesites sesiones de refresco para mantener el brillo a largo plazo.",
          },
        ],
      },
    ],
  },
  {
    id: "digitalOrthopantomographyPageContent",
    img: "ortopantomografia-digital.webp",
    title: "Tac 3D una sola arcada",
    url: "tac-3d-una-arcada",
    buyUrl: "https://buy.stripe.com/8wMdU0fYW1cXdNu4gh",
    price: 20.0,
    description: [
      "Oportunidad única para obtener una imagen completa y detallada de tus estructuras dentales y maxilares con nuestra oferta de Ortropantomografía Digital. Este estudio radiográfico avanzado es esencial para una evaluación exhaustiva de tu salud bucal y dental.",

      "Nuestra oferta brinda acceso a esta tecnología de diagnóstico de vanguardia a un precio altamente accesible.",

      "Ya sea que estés buscando mantenerte al tanto de tu salud bucal o necesites una evaluación más detallada, nuestra oferta de Ortropantomografía Digital está diseñada para brindarte una visión completa y precisa de tu salud dental.",
    ],
    offerList: [
      {
        listTitle: "Ortropantomografía Digital:",
        listDescription:
          "Utilizando equipos de radiografía digital de última generación, capturaremos una imagen panorámica de tus dientes, mandíbula y maxilares en una sola toma.",
        listDescription2:
          "Esta imagen detallada permite a los profesionales dentales detectar problemas, como caries, infecciones, impactaciones y maloclusiones, que pueden no ser visibles en radiografías convencionales.",
      },
      {
        listTitle: "Proceso de la Ortropantomografía:",
        listDescription: [
          {
            subListTitle: "Preparación:",
            subListDescription:
              "Serás recibido por nuestro equipo dental y se te explicará el procedimiento. Te proporcionaremos las instrucciones necesarias para asegurarnos de que la imagen sea de la mejor calidad posible.",
          },
          {
            subListTitle: "Posicionamiento y captura de imagen:",
            subListDescription:
              "Te pediremos que te sientes o te pongas de pie en posición frente al equipo de rayos X. Mantendrás la cabeza en una posición específica mientras el equipo gira alrededor de tu cabeza, capturando una imagen completa.",
          },
          {
            subListTitle: "Resultados y Asesoramiento:",
            subListDescription:
              "Una vez capturada la imagen, nuestros profesionales analizarán los resultados. En caso de detectar cualquier problema o anormalidad, se te proporcionará asesoramiento sobre los próximos pasos, incluyendo cualquier tratamiento necesario.",
          },
        ],
      },
    ],
  },
  {
    id: "full3DCTBothArchesPageContent",
    img: "tac-3d-ambas-arcadas.webp",
    title: "TAC 3D Completo en ambas arcadas",
    url: "tac-3d-completo-ambas-arcadas",
    buyUrl: "https://buy.stripe.com/5kA5nu1429Jt8ta002",
    price: 37.0,
    description: [
      "Este estudio de imágenes avanzado proporciona una visión tridimensional completa de tu boca, permitiendo a los profesionales dentales evaluar con precisión problemas y planificar tratamientos.",

      "Nuestra oferta combina tecnología de vanguardia con una tarifa asequible para brindarte un acceso sin igual a un diagnóstico dental completo.",

      "El TAC 3D es una herramienta esencial para el diagnóstico preciso y la planificación de tratamientos dentales.",

      "Ya sea que necesites evaluar problemas dentales existentes o planificar tratamientos futuros, nuestra oferta de TAC 3D Completo en Ambas Arcadas está diseñada para brindarte una visión completa y detallada de tu salud bucal.",
    ],
    offerList: [
      {
        listTitle: "TAC 3D completo en ambas arcadas:",
        listDescription:
          "Utilizando equipos de tomografía axial computarizada (TAC) especializados, capturaremos imágenes detalladas en tres dimensiones de tus estructuras dentales, maxilares y mandíbula superior e inferior.",
        listDescription2:
          "Esta tecnología avanzada proporciona una visión completa de tu salud bucal y es esencial para detectar problemas como caries, infecciones, quistes, fracturas y evaluación de las estructuras óseas para planificar tratamientos dentales.",
      },
      {
        listTitle: "Proceso del TAC 3D:",
        listDescription: [
          {
            subListTitle: "Preparación:",
            subListDescription:
              "Nuestro equipo dental te dará instrucciones sobre cómo prepararte para el escaneo. Esto podría incluir asegurarte de no llevar objetos metálicos, como joyas o gafas, y seguir otras instrucciones específicas.",
          },
          {
            subListTitle: "Escaneo del TAC:",
            subListDescription:
              "Te colocarás en una posición específica y permanecerás inmóvil mientras el equipo de TAC toma una serie de imágenes en capas del área de tus arcadas dentales. El proceso es rápido y no invasivo.",
          },
          {
            subListTitle: "Resultados y asesoramiento:",
            subListDescription:
              "Después de procesar las imágenes, nuestros profesionales analizarán los resultados detallados del TAC 3-D. Si se detecta algún problema o anormalidad, recibirás asesoramiento sobre los pasos a seguir, que pueden incluir tratamientos específicos.",
          },
        ],
      },
    ],
  },
  {
    id: "digitizedDischargeSplintWith3ShapeScannerPageContent",
    img: "ferula-descarga-michigan.webp",
    title: "Férula de descarga michigan con escáner 3Shape",
    url: "ferula-de-descarga-digitalizada-con-escaner-3shape",
    buyUrl: "https://buy.stripe.com/3cs9DKfYW3l5bFmeUX",
    price: 80.0,
    description: [
      "En esta oferta, te proporcionamos una solución integral para el cuidado de tu salud bucal a través de la Ferula de Descarga Michigan, un dispositivo dental diseñado para tratar problemas como el bruxismo (rechinar de dientes) y el trastorno de la articulación temporomandibular (ATM).",

      "Esta oferta integral está diseñada para brindarte una solución completa para tus problemas dentales, desde la digitalización precisa de la férula hasta los procedimientos diagnósticos avanzados y el ajuste personalizado.",
      "Incluye no solo la ferula en sí, sino también una serie de servicios y procedimientos adicionales para asegurar un enfoque completo y efectivo en el tratamiento.",
    ],
    offerList: [
      {
        listTitle:
          "Ferula de descarga Michigan con guías caninas digitalizada:",
        listDescription:
          "Se trata de una férula dental personalizada diseñada para encajar en tu boca de manera cómoda y efectiva.",
        listDescription2:
          "La digitalización con escáner 3D (utilizando el escáner 3Shape) garantiza una adaptación precisa y un ajuste perfecto.",
        listDescription3:
          "La Ferula de Descarga Michigan se centra en proteger tus dientes del rechinamiento y el apretamiento involuntario durante la noche.",
      },
      {
        listTitle: "Procedimientos diagnósticos avanzados:",
        listDescription: [
          {
            subListTitle: "Revisión Dental:",
            subListDescription:
              "Un dentista profesional llevará a cabo una revisión exhaustiva de tu salud bucal para evaluar cualquier problema existente y determinar la adecuación del tratamiento.",
          },
          {
            subListTitle: "Ortopantomografía:",
            subListDescription:
              "Se realizará una imagen panorámica de tus estructuras dentales y maxilares para obtener una visión completa de tu boca.",
          },
          {
            subListTitle: "TAC 3D (Tomografía Axial Computarizada):",
            subListDescription:
              "Este escaneo avanzado proporciona imágenes tridimensionales detalladas de tus estructuras dentales y faciales, permitiendo un diagnóstico más preciso.",
          },
        ],
      },
      {
        listTitle: "Montaje y ajuste en articulador:",
        listDescription:
          "Una vez que se hayan obtenido los datos necesarios de los escaneos y las imágenes, se realizará el montaje de la férula en un articulador.",
        listDescription2:
          "Esto asegura que la férula funcione correctamente con tus patrones de movimiento mandibular.",
      },
      {
        listTitle: "Ajuste de férula en boca:",
        listDescription:
          "El ajuste final de la férula se realizará en tu boca para asegurarse de que se adapte de manera cómoda y efectiva.",
        listDescription2:
          "Cualquier modificación necesaria se llevará a cabo en este momento.",
      },
    ],
  },
  // {
  //     id: "digitizedDischargeSplintNobruxPageContent",
  //     img: "ferula-descarga-nobrux.webp",
  //     title: "Férula de descarga Nobrux",
  //     url: "ferula-de-descarga-nobrux",
  //     buyUrl: "https://buy.stripe.com/9AQ3fmaECf3NcJqbIU",
  //     price: 150.00,
  //     description: [
  //         "¿Padeces de bruxismo severo? Entonces entiendes la urgencia de una defensa de calidad superior.",

  //         "Concebida explícitamente para los afectados con un desgaste dental extremo, garantizándote una protección insuperable. Se destaca por su robustez y vida útil prolongada, pensada para los que buscan soluciones duraderas.",

  //         "Si te encuentras entre quienes sufren de bruxismo severo, Nobrux es tu respuesta definitiva.",

  //         "¡Solicítala ya! Y en tan solo siete días, experimenta los beneficios que transformarán tu bienestar."
  //     ],
  //     offerList: [
  //         {
  //             listTitle: "Alineación con articulador digital:",
  //             listDescription: "Cada férula Nobrux se ajusta mediante un articulador digital, consolidando su precisión manufacturera a niveles máximos."
  //         },
  //         {
  //             listTitle: "Análisis de oclusión de calidad premium:",
  //             listDescription: "No es sólo una férula. Es un paquete completo con un análisis exhaustivo de oclusión realizado por un protésico experto, asegurándote que Nobrux encaje perfectamente y te brinde la protección que te mereces.",
  //         }
  //     ],
  // }
];

export const grouponTickets = [
  {
    title: "Tomografía axial computarizada (TAC) dental de una arcada",
    linkToWhatsapp:
      "https://api.whatsapp.com/send?phone=+34605472132&text=Hola,%20acabo%20de%20comprar%20un%20cup%C3%B3n%20descuento%20por%20Groupon%20para%20Tomograf%C3%ADa%20axial%20computarizada%20(TAC)%20dental%20de%20una%20arcada,%20me%20gustar%C3%ADa%20pedir%20cita",
    url: "tomografia-axial-una-arcada",
  },
  {
    title: "Tomografía axial computarizada (TAC) dental de dos arcadas",
    linkToWhatsapp:
      "https://api.whatsapp.com/send?phone=+34605472132&text=Hola,%20acabo%20de%20comprar%20un%20cup%C3%B3n%20descuento%20por%20Groupon%20para%20Tomograf%C3%ADa%20axial%20computarizada%20(TAC)%20dental%20de%20dos%20arcadas,%20me%20gustar%C3%ADa%20pedir%20cita",
    url: "tomografia-axial-dos-arcadas",
  },
  {
    title: "Higiene dental con pulido, diagnostico y revisión con radiografía",
    linkToWhatsapp:
      "https://api.whatsapp.com/send?phone=+34605472132&text=Hola,%20acabo%20de%20comprar%20un%20cup%C3%B3n%20descuento%20por%20Groupon%20para%20Higiene%20dental%20con%20pulido,%20diagnostico%20y%20revisi%C3%B3n%20con%20radiograf%C3%ADa,%20me%20gustar%C3%ADa%20pedir%20cita",
    url: "higiene-dental-revision-radiografia",
  },
  {
    title: "Férula de descarga michigan",
    linkToWhatsapp:
      "https://api.whatsapp.com/send?phone=+34605472132&text=Hola,%20acabo%20de%20comprar%20un%20cup%C3%B3n%20descuento%20por%20Groupon%20para%20una%20F%C3%A9rula%20de%20descarga%20Michigan,%20me%20gustar%C3%ADa%20pedir%20cita",
    url: "ferula-de-descarga-michigan",
  },
];
