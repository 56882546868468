import { useEffect, useState } from "react";
import qs from "qs";

export const useReaptcha = (rcRef) => {

    const [rcError, setRcError] = useState("");
    const [token, setToken] = useState("");
    const [captchaErrorMsg, setCaptchaErrorMsg] = useState("");


    const [isCaptchaSent, setIsCaptchaSent] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [executing, setExecuting] = useState(false);
    const [verified, setVerified] = useState(false);

    const [formReset, setFormReset] = useState({});

    const onError = () => {
        console.log("error...");
        setRcError(true);
    };
    
    const onExpire = () => {
        console.log("expired...");
        console.log("resetting...");
        resetReCaptcha();
    };
    
    const onLoad = resetForm => {
        console.log("loaded...");
        setLoaded(true);
        setFormReset(resetForm);
    };

    const onVerify = token => {
        console.log("verified...");  
        setToken(token);
        setVerified(true);
        setExecuting(false);
    };

    const resetEverything = resetForm => {
        if (rcError) {
          setRcError(false);
        }
        if (resetForm) {
          setCaptchaErrorMsg(false);
          resetForm();
        }
        resetReCaptcha();
    };
    
    const resetReCaptcha = async () => {
        await rcRef.current.reset();
        setVerified(false);
        setIsCaptchaSent(false);
        setExecuting(false);
        setToken("");
    };

    useEffect(() => {
      const SubmitRecaptcha = async (token) => {
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          data: qs.stringify({"g-recaptcha-response": token}),
          url: "/"
        };
        try {

          await fetch(options);
          setIsCaptchaSent(true);
          
        } catch (e) {
          console.log(e)
          setCaptchaErrorMsg(e.message);
        }
      };
      if (token) {
        SubmitRecaptcha(token);
      }
    }, [token]);

    return {
        onLoad,
        onError,
        onVerify,
        onExpire,
        resetEverything,
        resetReCaptcha,
        setExecuting,
        formReset,
        verified,
        isCaptchaSent,
        executing,
        captchaErrorMsg,
        loaded
    }
}
