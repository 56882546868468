import { useEffect, useState } from 'react';
import "../../Styles/Preloader.scss";

export const Preloader = () => {

    const [playLoader, setPlayLoader] = useState(false);

    useEffect(() => {

      const onPageLoad  = () =>{
          setPlayLoader(true)
      }

      if (document.readyState === 'complete') {
            setTimeout(() => {
                onPageLoad();
            }, 3000)
      } else {
          window.addEventListener('load', onPageLoad);
          // Remove the event listener when component unmounts
          return () => window.removeEventListener('load', onPageLoad);
      }

    }, []);

    return (
      <>
          {!playLoader && 
              <div id="preloaderContainer">
                  <img src={require("../../assets/img/svg/Preloader.svg")} alt='imagen de pre carga de página'/>
              </div>
          }
      </>
    );
};